.process-loading {

  position: relative;
  width: 100%;
  height:15px;
  background-color: #fff5ed;
  margin-top:30px;
  overflow: hidden;

  & .process-animated{
    height:100%;

    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left:-46px;
    //transform-origin: left top;
    -webkit-animation-name: MOVE-BG;
    -webkit-animation-duration: 5s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    //
    animation-name: MOVE-BG;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;


    background-image: repeating-linear-gradient(
          125deg,
        $first-color 1px,
        lighten( $first-color, 10% ) 2px,
        lighten( $first-color, 10% ) 11px,
        $first-color 12px,
        $first-color 20px
    );
    //background-size: 35px 35px;


  }

}

//@keyframes scaleBarFrames {
//   0%  {  transform-origin: left top; transform: scaleX(0); }
//   50%  {  transform-origin: left top;transform: scaleX(1); }
//   51%  { transform-origin: right top; transform: scaleX(1); }
//   100% {  transform-origin: right top; transform: scaleX(0);  }
//}

//
//@-webkit-keyframes MOVE-BG {
//  from {
//    -webkit-transform: translateX(0);
//  }
//  to {
//    -webkit-transform: translateX(46px);
//  }
//}

@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(46px);
  }
}
