
$subject-show__A1: themed('form-element--bg-color') !default;
$subject-show__B1: themed('base--text-color') !default;
$subject-show__F0: themed('frame--bg-color') !default;
$subject-show__E0: themed('form-element--bg-color') !default;

.me__subject-show{

  border-bottom: 1px solid $subject-show__F0;
  color: $subject-show__B1;

  .me__subject-show__label{
    height:30px;
    line-height:30px;
    padding: 0 10px;
  }

  .me__subject-show__subject-text{
    height:30px;
    line-height:30px;
    background-color: $subject-show__A1;
    padding-left:10px;
    flex-grow:1;
  }

  .me__subject-show__dropdown-list{
    display: block;
    position: absolute;
    width: 100%;
    max-height: 300px;
    height: auto;
    background: $subject-show__E0;
    top: 31px;
    z-index:100001;
  }

  .me__subject-show__dropdown-item{
    display: block;
    width: calc( 100% - 20px );
    height: 50px;
    line-height: 20px;
    padding: 5px 10px 0 10px;
  }

  .me__subject-show__dropdown-condition{
    span{
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }
    display: block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    opacity: .6;
  }

}

