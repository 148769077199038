$dialog-cropper__A0: transparent;

//@todo: move this to generic?
//@todo: (xaf, elaine) theme cropper?

.me__dialog-cropper{

  display:flex;
  flex-direction:row;

  .me__dialog-cropper__canvas{

    .img-container{
      max-height:100%;
    }

    .cropper-canvas{
      opacity:0.5 !important;
    }
  }

  .me__dialog-cropper__preview{
    background-color:transparent;
    width:250px;
    height:120px;
    overflow:hidden;
    padding:10px;
  }

  .cropper-container{

  }

  .preview-container{
    width:100%;
    height:100%;

    img{
      max-width:100%;
      max-height:100px;
    }
  }

}
