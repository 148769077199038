.xaf-barchart {

  //.value-bars{
  //  fill: $first-color;
  //
  //}
  //
  //.back-bars{
  //  fill: #ccc;
  //}

  .xaf-barchart-row{

    $barRowHeight : 20px;
    $barDataHeight : 10px;
    $barDataTop : ( $barRowHeight -  $barDataHeight ) / 2;
    $rowMarginBottom : 2px;



    .xaf-barchart-row-name{
      width:30%;
      height:100%;
      float:left;
      font-size:14px;
      line-height: $barRowHeight;
      max-width:120px;
      //background-color: blue;
    }
    .xaf-barchart-row-value{
      width:10%;
      height:100%;
      float:left;
      font-size:14px;
      line-height: $barRowHeight;
      max-width:80px;
      //background-color: blue;

    }
    .xaf-barchart-row-procent{
      width:10%;
      height:100%;
      float:left;
      font-size:14px;
      line-height: $barRowHeight;
      //background-color: blue;

    }
    .xaf-barchart-row-bar{
      width:50%;
      height:100%;
      float:left;
      position:relative;
      //background-color: yellow;



      .xaf-barchart-row-bar-back{
        position:absolute;
        top:$barDataTop;
        left:0px;
        width:100%;
        height:$barDataHeight;
        background-color: lightgrey;
      }

      .xaf-barchart-row-bar-data{
        position:absolute;
        top:$barDataTop;
        left:0px;
        height:$barDataHeight;
        display:block;
        background-color: $first-color;
      }

    }

    height:$barRowHeight;
    float:left;
    width:100%;
    margin-bottom:$rowMarginBottom;
    //background-color:grey;



  }

  width:100%;
  height:auto;
  overflow:hidden;
  //background-color:red;

}

