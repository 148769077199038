$subject-edit__A0: themed('list-reverse:selected--bg-color') !default;
$subject-edit__B0: themed('list-reverse--bg-color') !default;
$subject-edit__C0: themed('list-reverse--text-color') !default;

.me__subject-config {
  md-input-container{
    flex-grow: 1;
  }
  &__add-btn{
    margin-left: auto !important;
    order: 3;
  }
  &__inner {
    display: inline-block;
    min-width: 530px;
    height: 200px;
    background: $subject-edit__B0;
    width: 100%;
  }
  &__list {
    display: block;
    width: 100%;
    height: auto;
    overflow: auto;
  }
  &__list-item {
    &.selected{
      background: $subject-edit__A0;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    cursor: pointer;
  }
}
