$fields-group__A0: themed('list:selected--bg-color') !default;
$fields-group__E0: 'elaine-six'; //$iconFontFamily; //themed('') !default;
$fields-group__E1: themed('base--text-color') !default;

@import "fields_group_tooltip";

.me__fields-group{

  .me__fields-group__button{
    width:30px;
    height:30px;
    font-family: $fields-group__E0;
    color:$fields-group__E1;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    cursor:pointer;

  }
  .me__fields-group__spinner{
    position:relative;
    top:15px;
  }

  .approval-page {
    color: #000 !important;
  }

  padding-bottom: 170px;
}



