
$article__A0: #ccc;
$article__A1: themed('element-action:active--bg-color') !default;
$article__B0: themed('element-action:active-highlight--bg-color') !default;
$article__B1: themed('element-action--text-color') !default;
$article__C0: themed('element-action:active-highlight--bg-color') !default;

$article__D1: themed('layer-1--color') !default;
$article__D2: themed('layer-2--color') !default;
$article__D3: themed('layer-3--color') !default;
$article__D4: themed('layer-4--color') !default;
$article__D5: themed('layer-5--color') !default;

@mixin open-border( $color ) {
  outline: 3px dotted $color;
}

.me__article{

  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  outline: none;
  //border: 1px solid transparent; //fix to replace overflow:hidden in height:auto
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &.open{
    &:not(.active){
      &.layer-1{
        @include open-border( $article__D1 );
      }
      &.layer-2{
        @include open-border( $article__D2 );
      }
      &.layer-3{
        @include open-border( $article__D3 );
      }
      &.layer-4{
        @include open-border( $article__D4 );
      }
      &.layer-5{
        @include open-border( $article__D5 );
      }
    }
    &.active{
      border: 3px solid $article__C0;

      .me__article__overlay-infos{
        display:block !important;
      }

      > .me__article__overlay-infos{
        background: rgba($article__B0, 1);
        outline: 3px solid $article__B0 !important;
        overflow: hidden;
      }
    }

    & > .me__article__overlay{
      display:none;
    }
  }

  //&:hover{
  //  > .me__article__overlay-infos {
  //    display: none;
  //  }
  //}

  .me__article__canvas{
    min-height:30px;
    overflow: hidden;
  }
  .articleConditionText {
    float: left;
    padding: 10px !important;
    font-size: 12px;
  }
  .me__article__overlay{
    min-height:30px;
    position: absolute;
    top:0;
    left:0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    outline: none;
    margin: 1px;
    font-size: 12px;
    cursor:pointer;
    z-index: 1001 ; // IN fordegrund z-index for the me__article-menu__backdrop-passive in deactivated-articles

    //&:hover,
    //&.active {
    //  background-color: rgba($article__A1, 0.3);
    //}
  }

  .me__article__overlay-infos {

    > span {
      float: left;
      overflow: hidden;

      &.me__article__overlay-infos-name{
        width: calc(100% - 23px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    position: absolute;
    display: none;
    height:  20px;
    line-height: 20px;
    background: rgba($article__B0, .5);
    color: $article__B1;
    padding: 0 10px 0 5px;
    white-space: nowrap;
    font-size:12px;
    top:-21px;
    width:100%;
    overflow:hidden;
    outline: 1px solid rgba($article__B0, .5);
    cursor:grab;

    &.layer-1{
      z-index:2011;
    }
    &.layer-2{
      z-index:2012;
    }
    &.layer-3{
      z-index:2013;
    }
    &.layer-4{
      z-index:2014;
    }
    &.layer-5{
      z-index:2015;
    }
  }

  .me__article__lock{
    display:inline-block;
    width:20px;
    height:20px;
    vertical-align: text-bottom;
    &::before{
      content: url( '/gui/fonts/six/icons/lock-solid-white.svg' );
      width:20px;
      box-sizing: border-box;
    }
    float:right  !important;
  }

  .me__article__grip{
    display:inline-block;
    width:20px;
    height:20px;
    vertical-align: text-bottom;
    margin-right:3px;
    &::before{
      content: url( '/gui/fonts/six/icons/grip.svg' ); //grip
      width:20px;
      box-sizing: border-box;
      opacity:.7;
    }
  }

  .me__article__filter{
    margin-top:3px;
    margin-left:3px;
    float:right !important;
  }
}

article{

  &.structured{
    position: relative;
    display: block;
    margin-bottom: 32px;
    border: 1px solid $article__A0;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
    padding-bottom: 27px;
    &.hasConditions{
      padding-bottom: 17px;
      min-height: 50px;
    }

    .me__article__overlay-infos{
      &.showInfos{
        display:block !important;
      }
    }
  }

  &:not(.single-article-wrapper):hover{
    > .me__article > .me__article__overlay-infos {
      display: block;
      background: rgba($article__B0, 1);
      outline: 1px solid rgba($article__B0, 1);
    }

    > .me__article > .me__article__overlay {
      background-color: rgba($article__A1, 0.3);
    }
  }

  &.single-article-wrapper:hover{
    > .me__article > .me__article__overlay-infos {
      display: block;
      background: rgba($article__B0, 1);
      outline: 1px solid rgba($article__B0, 1);
    }

    > .me__article > .me__article__overlay {
      background-color: transparent;
      cursor:default;

    }
  }

  &.single-article-wrapper{
    pointer-events: none;
    cursor:default;
  }

  transition-property: margin-bottom, margin-top, margin-left, margin-right, outline-color, outline-width, box-shadow, padding-bottom, padding-left, padding-right, padding-top;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
}

//
