//@todo:crete a theme
@import "colors";
@import "theme/theme";

// ______________________________________________________________________

// @todo we need to create an override
.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:calc( 100% - 40px );
}

.ui-layout-row{
  &> .ui-splitbar{
    background: $eColorDark;
  }
}

.ui-layout-column{
  &> .ui-splitbar{
    background: $eColorDark;
  }
}

.ui-layout-navigation{
  overflow: visible;
}

//______________________________________________________________________

@import "../generic/loadingSpinner/loadingSpinner";
@import "../generic/forms/forms";
@import "../generic/compatibilityShow/compatibility_show";
@import "toaster/toaster";
@import "../generic/icons/icons";
@import "scrollpane_override";
@import "ui_layout-override";
@import "tinymce-override";
@import "dialogs/dialogs";
@import "productLogo/product_logo";
@import "messageEditor/message_editor";
@import "panels/panels";
@import "elaineApp/elaine_app";
@import "elaine-overlay-scrollbars";

//old
@import "bootstrap";
@import "font_awesome";
@import "pdfviewer";
@import "spectrum";
@import "form";
@import "date_time_picker";
@import "moment_picker";
@import "tree";
@import "listview";
@import "toolbar";
@import "tab_browser";
@import "panels";
@import "property_table";
@import "angular_tree";
@import "nvd3";
@import "statistics";
@import "dialogs";
@import "cropper";
@import "campaignmonitor";
@import "webuipopover";
@import "jobcontrol";
@import "login";
@import "base";
@import "accounting_mailings";
@import "accounting_recipients";
@import "analyse";
@import "mailsequence";
@import "optinhistory";
@import "tree_grid";
@import "ewe";
@import "ejm";
@import "message_client_preview";
@import "../XAF/style";
