$view-text-edit__A0: themed('frame--bg-color') !default;

.me__view-text-edit{
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  border-right: 4px solid $view-text-edit__A0;

  &__container-center{
    width: calc(100% - 6px);
  }

  &__textarea{
    display: block;
    width: 100%;
    height: calc( 100% - 31px );
  }
}
