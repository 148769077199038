html {

  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }


  b, strong {
    font-weight: 700;
  }

  small {
    font-size: 0.75em;
    line-height: 1.2;
  }

  .success {
    color: $green;
  }

  .xapp {

    ui-view {

      &.ng-enter {
        transition: 3s;
        opacity: 0;
      }

      &.ng-enter-active {
        opacity: 5;
      }
    }

    .xaf-stage {
      position: relative;
      height: auto;
      min-height: 150px;
      background-color: $grey;
      img {
        display: block;
        height: auto;
        position: absolute;
        right: 30px;
        bottom: 15px;
        width: auto;
        max-width: 360px;

        @media all and (-ms-high-contrast:none){
          width: 180px;
        }

        height: auto;
        max-height: 115px;
        @include drop;

      }
      &.letter {
        background-color: #999;
      }
      &.graph {
        background-color: #999;
      }
      &.device {
        background-color: #999;
      }

      &.mailings {
        background-color: $header-bg-color-mailings;
      }
      &.groups {
        background-color: $header-bg-color-groups;
      }
      &.statistics {
        background-color: $header-bg-color-statistics;
      }
      &.dashboard {
        background-color: $header-bg-color-dashboard;
      }
      &.account {
        background-color: $header-bg-color-account;
      }

      .xaf-caption {
        h1{
          @media screen and (max-width: $w-640) {
            font-size: 2em;
          }
          @media screen and (max-width: $w-320) {
            font-size: 1.8em;
          }
          margin: 0;
          padding: 0;
          font-size: 2.25em;
          line-height: 1.2;
          font-weight: 300;
        }
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 30px;
        left: 0;
        color: $white;
        @media screen and (max-width: $w-640) {
          font-size: 80%;
        }
      }
    }
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

/* -----------------------------------------------------------  main elements intro animation */
@-webkit-keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }

.fade-in {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 500ms;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:fadeIn ease-in 500ms;
  animation:fadeIn ease-in 500ms;
  -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
  -webkit-animation-duration:500ms;
  -moz-animation-duration:500ms;
  animation-duration:500ms;

}

@-webkit-keyframes fadeInHeader { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@-moz-keyframes fadeInHeader { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@keyframes fadeInHeader { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }

.fade-in-header {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 500ms;
  -moz-animation:fadeIn ease-in 500ms;
  animation:fadeIn ease-in 500ms;
  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
  -webkit-animation-duration:500ms;
  -moz-animation-duration:500ms;
  animation-duration:500ms;
}


.loading{
  padding: 30px;
  text-align: center;
}

.icon-loading{
  font-size: 80px;
  color: #888888;
  margin: 0 auto 30px auto;
  &.dropOut {
    animation: dispersion 1s infinite alternate;
  }
}

.loading>p {
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 30px;
}
