.xaf-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #273a47;
  opacity: .5;
  -ms-filter: "alpha(opacity = 50)";
  z-index: 101;
}

.xaf-modal-container {
  &.xaf-preview-box {

    .xaf-preview-close{
      right: 2%;
      top: 22px;
      position: absolute;
      display: inline-block;
      line-height: 38px;
      margin-right: 22px;
      z-index: 1210;
      cursor: pointer;
    }
    .xaf-preview-devices {
      width: 100%;
      height: 88px;
      background-color: $white;
      position: relative;
      z-index: 1209;
      @include clear;
      & > div {
        width: 88px;
        height: 88px;
        float: left;
        cursor: pointer;
        position: relative;
        text-align: center;
        @include transition;
        &:hover {
          background-color: $grey;
        }
        &.active {
          background-color: $blue;
          color: $white;
        }
        small {
          position: absolute;
          width: 100%;
          display: inline-block;
          left: 0;
          bottom: 8px;
        }
        .icon {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          font-size: 38px;
          line-height: 88px;
        }
      }
    }
    .xaf-preview-window{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      padding-top: 88px;
      z-index: 1208;
      background-color: $grey-light;
      overflow-y: auto;
      left: 0;
      right: 0;
      margin: 0 auto;
      &.mobile {
        max-width: 320px;
        .qw-1-2 {
          width: 100%;
          padding: 0;
        }
      }
      &.tablet {
        max-width: 768px;
      }
      &.laptop {
        max-width: 1024px;
      }
      .xaf-preview-window-inner {
        height: 100%;
        padding: 30px;
        overflow-y: auto;
        background-color: $grey-light;
      }
    }

    position: fixed;
    display: block;
    width: 96%;
    height: 96%;
    top: 10%;
    left: 30%;
    z-index: 1210;
    background-color: $white;
    overflow: hidden;
    transform: none;
    @include clear;
    @include box-shadow;
    @include transition;
  }
  &.keyvisual,
  &.socialmedia {
    .upload {
      #file-text {
        float: left;
      }
      .btn-upload {
        position: absolute;
        overflow: hidden;
        width: 38px;
        height: 38px;
        background: #753aba;
        float: left;
        margin-top: 5px;
        right: 0;
      }
      #image-upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: transparent;
      }
    }
  }
  .xaf-modal-title {
    h2{
      font-size: 1.375em;
      line-height: 1.2;
      font-weight: 300;
      padding: 0;
      margin: 0;
    }
    p{
      padding: 0;
      margin: 0;
    }
    .icon {
      cursor: pointer;
      right: 18px;
      top: 18px;
    }
    padding: 30px;
  }
  .xaf-modal-data {
    padding: 0 30px 30px;
    height: 100%;
    width: 100%;

    @include clear;
    .qw-1-1 {
      margin-top: 15px;
    }
    //button {
    //  //margin-top: 30px;
    //  float: right;
    //}
  }
  .xaf-modal {
    .xaf-modal-close {
      &:hover {
        color: #000;
      }
      position: absolute;
      top: 5px;
      right: 5px;
      float: right;
      text-align: right;
      text-decoration: none;
      font-size: 16px;
      color: #ccc;
    }
    .xaf-modal-header {
      h3 {
        font-family: Roboto Thin;
        margin: 0;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 300;
      }
      display: block;
      height: 30px;
      width: 100%;
      line-height: 30px;
      padding-left: 10px;
    }
    .xaf-modal-body {

      display: block;
      overflow: hidden;
      height: calc(100% - 40px);
      padding-top: 10px;

      .xaf-mailing-display-ctrl {
        ul {
          li {
            a {
              &.active {
                background: #a8c33d;
                color: #fefefe;
              }
              display: block;
              width: 100%;
              height: 100%;
              padding: 0 5px 0 5px;
              text-decoration: none;
            }
            display: inline-block;
            height: 100%;
            line-height: 30px;
            text-transform: uppercase;
            overflow: hidden;
          }
          padding: 0;
          margin: 0;
          height: 30px;
        }
        list-style: none;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        text-align: left;
        display: block;
      }

    }

    width: 400px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #fefefe;
    z-index: 101;
  }
  display: block;
  position: fixed;
  background: $white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 500;
  @include box-shadow;
}
