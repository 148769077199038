// libs

@import "device_tabs_mixin";
@import "../forms/angular_chosen_override";


@import "dialogs/dialogs";

@import "structure_element"; //@todo: expiremtal

@import "subject_show";
@import "subject_edit";
@import "tab_header";
@import "html_show";

//message stage components
@import "article_edit";
@import "preview_show";

// message components
@import "attachment";
@import "composing";
@import "article";
@import "widget";

// message widget forms
@import "complex_imagelink";
@import "complex_index";
@import "complex_input";
@import "complex_css_image";
@import "complex_simple_textarea";
@import "complex_link";
@import "complex_list";
@import "complex_switch_image";
@import "complex_switch_link";
@import "complex_textarea";

// other forms
@import "preview_edit";
@import "preview_config";
@import "preview_condition";
@import "preview_tab-forms";
@import "preview_release";
@import "preview_versioning";

//ui
@import "ui_tabs";

.mce-i-visualaid{
  border: 1px dashed #fefefe !important;
}

