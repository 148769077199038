// main: ../index.scss
.xaf-home {
  .noty_bar{
    .noty_message{
      .noty_inner{
        .noty_inner_left{
          display: inline-block;
          float: left;
          width: calc( 100% - 50px );
        }
        .noty_inner_right{
          .xaf-icon{
            font-size: 2em;
          }
          display: inline-block;
          float: left;
          width: 50px;
        }
      }
      overflow: hidden;
    }
    overflow: hidden;
  }


  .xaf-main {

    &.xaf-new-pwd{
      height: 410px;
    }

    position: absolute;
    max-width: 460px;
    width: 100%;
    height: 340px;
    background: #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    float: none;
    min-height: auto;
    h1 {
      font-size: 2.25em;
      font-weight: 300;
      @media screen and (max-width: $w-640) {
        font-size: 2em;
      }
      @media screen and (max-width: $w-320) {
        font-size: 1.8em;
      }
      line-height: 1;
      margin-bottom: 10px;
    }
    form {
      h1{
        @media screen and (max-width: $w-640) {
          font-size: 2em;
        }
        @media screen and (max-width: $w-320) {
          font-size: 1.8em;
        }
        margin: 0;
        padding: 0;
        font-size: 2.25em;
        line-height: 1.2;
        font-weight: 300;
      }
      *:focus {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
      }
      .xaf-form-fieldset {
        .xaf-form-group {
          margin-bottom: 10px;
        }
        width: 100%;
        height: auto;
        display: block;
        margin: 10px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }
      .xaf-form-control {
        img {
          width: 50%;
          height: 50%;
          margin: 8px;
        }
        a{
          cursor: pointer;
          line-height: 38px;
          text-decoration: none;
          float: left;
        }
        button{
          &.submit-login{
            &:not(.negative) {
              background-color: #25cf14;
              color: #fff;
              span{
                &#icon-login-btn{
                  background-color: #1da010;
                }
              }
            }
          }
          p{
            margin: 0;
          }
          span {
            &[data-translate]{
              background-color: transparent;
              color: #fefefe;
            }
          }
          &.negative{
            span {
              &#icon-login-btn{
                background-color: #d2d2d2;
                color: #fff;
              }
              background-color: transparent;
              color: #000;
            }
          }
          float: right;
          cursor: pointer;
          margin: 0;
        }
        position: absolute;
        display: block;
        width: 100%;
        height: 38px;
        border: none;
      }
      input {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1em;
        font-weight: 300;
        margin-bottom: 20px;
      }
      div {
        margin-top: 10px;
        @media screen and (max-width: $w-640){
          float: none;
          //text-align: center;
        }
      }
      .register {
        margin: 12px 20px 0 0;
        line-height: 1;
        float: left;
        @media screen and (max-width: $w-640){
          float: none;
        }
      }
      height: 100%;
    }
    small{
      position: absolute;
      bottom: 10px;
      left: 30px;
    }
  }
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1em;
  font-weight: 300;
  background-color: $grey-light;
  background-image: url(/plugins/xaf_framework/public/img/background-default-home.png);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  height: auto;
}
