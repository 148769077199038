// overall
[ui-splitbar]{
  &.animate-column{
    .ui-splitbar-icon {
      transform: rotate(90deg);
    }
  }

  &.layout-static {
    .ui-splitbar-icon {
      display: none;
    }
  }

  &.layout-north{
    &.splitbar-closed{
      .ui-splitbar-icon-down{
        display: inline-block;
      }
      .ui-splitbar-icon-up{
        display: none;
      }
    }
    .ui-splitbar-icon-down{
      display: none;
    }
    .ui-splitbar-icon-up{
      display: inline-block;
    }
  }

  &.layout-south{
    &.splitbar-closed{
      .ui-splitbar-icon-down{
        display: none;
      }
      .ui-splitbar-icon-up{
        display: inline-block;
      }
    }
    .ui-splitbar-icon-down{
      display: inline-block;
    }
    .ui-splitbar-icon-up{
      display: none;
    }
  }

  &.layout-west{
    &.splitbar-closed{
      .ui-splitbar-icon-right{
        display: inline-block;
      }
      .ui-splitbar-icon-left{
        display: none;
      }
    }
    .ui-splitbar-icon-right{
      display: none;
    }
    .ui-splitbar-icon-left{
      display: inline-block;
    }
  }

  &.layout-east{
    &.splitbar-closed{
      .ui-splitbar-icon-right{
        display: none;
      }
      .ui-splitbar-icon-left{
        display: inline-block;
      }
    }
    .ui-splitbar-icon-right{
      display: inline-block;
    }
    .ui-splitbar-icon-left{
      display: none;
    }
  }

  .ui-splitbar-icon {
    &.ui-splitbar-icon-right,
    &.ui-splitbar-icon-left {
      &:after {
        content: '...';
        font-size: 40px;
        height: 6px;
        line-height: 6px;
      }
      border: none;
      width: 30px;
      height: 6px;
    }
    &.ui-splitbar-icon-up,
    &.ui-splitbar-icon-down {
      &:after {
        content: '...';
        font-size: 40px;
        height: 6px;
        line-height: 6px;
      }
      border: none;
      width: auto;
      height: 6px;
    }
  }

}

.ui-layout-container{
  &.stretch{
    overflow: visible;
  }
}
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  &> .ui-splitbar{
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
}
