/*
  todo:
   - Finally create an example form and elements
   - Separate structure and content elements
*/

$inputText__A1: themed('form-element--text-color');

.me__form-input-comment{
  float: left;
  margin: 5px 0;
  background-color: transparent;
  color: $inputText__A1;
  padding: 0 4px;
  &::before {
    margin: 2px 5px;
    display: block;
    float:left;
  }
}

.me__form__basic-text{
  /*
    div.me__form.me__form--margin
      div.me__form-row
        span.me__form__basic-text

  */
  line-height: 30px;
  color: $inputText__A1;
}



.me__form__label{

  &.me__form__label--old-grid{
    min-width:120px;
  }

  &.me__form__label--contrast{
    color: $inputText__A1;
  }

  &.me__form__label--middle{
    line-height: 20px;
  }
}
