.me__preview-edit {
  .preview-edit-tab-container {
    .preview-edit-tab-container-child {
      display: block;
      width: 100%;
      height: 100%;
    }

    display: block;
    width: 100%;
    height: calc(100% - 50px);
  }
}


