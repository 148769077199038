.xafg-1-1,
.xafg-1-2,
.xafg-1-3,
.xafg-2-3,
.xafg-1-4,
.xafg-3-4,
.qw-1-1,
.qw-1-2,
.qw-1-3,
.qw-2-3,
.qw-1-4,
.qw-3-4 {
  float: left;
  position: relative;
}

.qw-1-4 {
  width: 25%;
}

.xafg-1-3, .qw-1-3 {
  width: 33.3333%;
}

.xafg-1-2, .qw-1-2 {
  width: 50%;
  &.left {
    padding: 0 15px 30px 30px;
  }
  &.right {
    padding: 0 30px 30px 15px;
  }
  @media screen and (max-width: $w-960) {
    width: 100%;
    &.left,
    &.right {
      padding: 0 30px 30px 30px;
    }
  }
}

.xafg-2-3, .qw-2-3 {
  width: 66.6667%;
}

.xafg-3-4, .qw-3-4 {
  width: 75%;
}

.xafg-1-1, .qw-1-1 {
  width: 100%;
}
