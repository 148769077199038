.me__preview-sms{
  &__container{
    display: block;
    width: 100%;
    height: 100%;
  }
  &__show{
    border-radius: 0;
    display: block;
    max-width: 100%;
    height: auto;
    background: #fefefe;
    color: #000;
    word-wrap: break-word;
    white-space: pre-line;
    width: 320px;
    margin: 0 auto;
  }
  .me__preview__outdated-overlay{
    display:flex;
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,.5);
    align-items: center;
    top:0;
    left:0;
    cursor:pointer;
    z-index: 1;
  }
  .me__preview__outdated-advice{
    color:white;
    font-size: 20px;
    width:100%;
    text-align:center;
  }
  display: block;
  width: 100%;
  height: 100%;
}
