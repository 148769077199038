//addition to flatpicker
.flatpickr-current-month.slideLeft {
  display: none
}

.flatpickr-current-month.slideRight {
  display: none
}

.dayContainer{
  &.slideLeft,&.slideRight {
    display: none
  }
}

.last-item{
  border-bottom: none;
}
