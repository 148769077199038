@-webkit-keyframes fadeOut {
  from { opacity:1;  /* IE9 only */ }
  to { opacity:0; opacity: 1\9; }
}
@-moz-keyframes fadeOut {
  from { opacity:1;  /* IE9 only */ }
  to { opacity:0; opacity: 1\9; }
}
@keyframes fadeOut {
  from { opacity:1;  /* IE9 only */ }
  to { opacity:0; opacity: 1\9; }
}

@keyframes dispersion {
  from { opacity: 0; transform: translateY(0); }
  to{ opacity:1;transform: translateY(-20%);}
}

.xaf-progress-container{
  .xaf-bar-indicator{
    &.fly{
      opacity:1;
      -webkit-animation:fadeOut ease-in 800ms;
      -moz-animation:fadeOut ease-in 800ms;
      animation:fadeOut ease-in 800ms;
      -webkit-animation-fill-mode:forwards;
      -moz-animation-fill-mode:forwards;
      animation-fill-mode:forwards;
      -webkit-animation-duration:800ms;
      -moz-animation-duration:800ms;
      animation-duration:800ms;
    }
    float: left;
    margin-bottom:5px;
    padding-bottom: 5px;
    width:100%;
    text-align: center;
  }
  .xaf-barbox{
    &.xaf-barbox-disapear{
      transform: scale(0);
      transition: 500ms all ease;
    }
    .xaf-bar{
      width:0px;
      height: 10px;
      background-color:$first-color;
      transition-duration: 1s;
      border-radius: 25px;
    }
    float: left;
    padding: 2px;
    height: 14px;
    width:100%;
    -webkit-box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.15);
    border-radius: 25px;
  }
  width:100%;
  background-color:transparent;
  height:50px;
  padding:70px 0px;
}
