// main: ../index.scss
.xaf-header {
  .xaf-edit {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: $white;
    a {
      color: $white;
      padding-left: 5px;
      .icon {
        width: 16px;
        height: 14px;
        position: relative;
        margin: 0 5px;
        vertical-align: middle;
      }
    }
    @media screen and (max-width: $w-640) {
      padding: 10px 10px 10px 38px;
    }
    @media screen and (max-width: $w-640) {
      span {
        font-size: 0.8em;
        line-height: 20px;
      }
      a {
        font-size: 0.8em;
        line-height: 20px;
      }
    }
    display:none;
  }

  width: 100%;
  position: fixed;
  z-index: 110;
}
