.me__complex-index {

  .me__complex-index__container {
    display: block;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
  }
  .me__complex-index__inner{

  }
  .me__complex-index__list{
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .me__complex-index__listitem{
    &:first-of-type{
      margin-top: 20px;
    }
    display: block;
    width: 100%;
    height: 30px;
    padding: 0;
    list-style: none;
    margin: 0 0 5px 0;
  }

  .me__complex-index__input{
    flex-grow:1;
  }

  md-switch.me__complex-index__switch{
    order:1;
    margin-left:10px;
  }
}
