.xaf-dashboard-list-item {
  .xaf-dashboard-list-item-block-info{
    float: left;
    width: calc( 100% - 300px);
    height:auto; //var
    overflow: hidden;

    @media screen and (max-width: $w-1200) {
        &.reporting{
          width: 100%;
        }
    }

    @media screen and (max-width: $w-768) {
        &:not(.reporting){
          width: 100%;
        }
      //border: 1px solid red;
      height:auto;
      overflow:hidden;

    }

    .xaf-item-block-info-properties{
      p{
        margin: 0;
      }
      @media screen and (max-width: $w-768) {
        width: 100%;
      }
      .xaf-item-block-info-properties-name{
        display: block;
        margin-bottom: 8px;
      }
      .xaf-item-block-info-properties-extras{
        p {
          font-size: 1em;
          line-height: 1.4;
          display: block;
          margin-bottom: 5px;
        }
      }
      height:auto;
      overflow:hidden;
      width: 300px;
      float:left;
    }

    .xaf-item-block-info-stats{
      //background-color: #d0e7a7;
      //height:100%;
      height:auto;
      overflow:hidden;
      width:calc( 100% - 300px );
      float:left;
      @media screen and (max-width: $w-768) {
        width: 100%;
      }

    }

    &:not(.reporting){
      .xaf-item-block-info-stats{
        display:none;
      }
    }


  }


  .xaf-dashboard-list-item-block-control{
    @media screen and (max-width: $w-1200) {
      &.reporting{
        width: 100%;
      }
      height: 90px;
    }
    @media screen and (max-width: $w-768) {
      &:not(.reporting){
        width: 100%;
      }
    }
    .xaf-item-block-control-button{
      float: right;
      width: 200px;
      padding-top: 30px;
      height: 100%;
      position: relative;
    }
    .xaf-item-block-control-pointer{
      float: right;
      width: 34px;
      margin-right: 40px;
      padding-top: 75px;
      height: 100%;
      position: relative;
    }
    float: right;
    width: 300px;
    height: 110px;
  }
  .xaf-dashboard-item-options{

  }
  &.open-options {
    .xaf-dashboard-item-options {
      &::before {
        border-bottom: 16px solid $blue;
        top: -16px;
      }
      height: 30px;
      overflow: visible;
    }
    padding-bottom: 40px;
  }
  padding: 0px 30px 10px 30px;
  height: auto;
  overflow:hidden;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  @include transition;
}
