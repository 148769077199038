.e_form {
  .querybuilder-fieldset{
    height: calc( 100% - 80px );
  }
  button, legend, select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  abbr {
    color: #6d891b !important;
  }
  *[disabled]{
    *[disabled]{
      opacity: 1;
    }

    &.styled-select, &.styled-multiple-selection{
      a, button,.chosen-disabled {
        opacity: 1 !important;
      }
    }
    *,a {
      cursor: default;
    }
    .form-control {
      &:focus:not( [disabled] ){
        background: #404644;
      }
    }
    .chosen-choices{
      cursor: default !important;
    }
    cursor: default !important;
    opacity: .3;
  }
  fieldset:disabled{
    button{
      opacity: 1 !important;
    }
  }
  select{
    &[ disabled ]{
      cursor: default !important;
    }
  }
  .ui-spinner{
    &.ui-state-disabled{
      .ui-spinner-button {
        &:hover {
          background: #404644;
        }
        cursor: default;
        opacity: .3;
      }
    }
    [type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      width: calc( 100% - 30px );
    }
    .ui-spinner-button {
      &.ui-spinner-up{
        top: 0;
      }
      &.ui-spinner-down{
        top: 15px;
      }
      .ui-icon{

      }
      display: inline-block;
      width: 30px;
      height: 15px;
      line-height: 15px;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: none;
      color: #fefefe;
      border-left: 1px solid #000;
      cursor: pointer;
      outline: none;
    }
    position: relative;
    background: #404644;
  }
  hr {
    border: none;
    border-top: 1px solid #000;
  }
  .withPadding{
    hr{
      margin-top:0;
    }
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .withoutMargin{
    margin:0;
  }
  .e_icn_btn{
    &:hover:not( [disabled] ),&.active{
      &.cancel{
        &[disabled] {
          background: #404644;
          border-color: #404644;
        }
        background: #731513;
        border-color: #731513;
      }
      background: #6d891b;
      border-color: #6d891b;
    }
    &.right{
      float: right;
    }
    background: #404644;
    border: none;
    color: #fefefe;
    border-radius: 0;
    width: auto;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    outline: none;
    vertical-align: top;
  }
  .form-btn-with-dropdown{
    &.right{
      float: right;
    }
    .e_icn_btn{
      float: left;
    }
    .additional-options{
      &[disabled]{
        &:hover{
          background: #404644;
        }
      }
      &:hover{
        background: #6d891b;
      }
      width: 30px;
      display: inline-block;
      text-align: center;
      background: #404644;
      height: 30px;
      vertical-align: top;
      line-height: 30px;
      float: left;
      border-left: 1px solid #000;
      outline: none;
    }
    .form-btn-with-dropdown-submenu{
      button{
        white-space: nowrap;
      }
      width: auto;
      top: 100%;
      right: 0;
      height: auto;
      min-height: 30px;
      position: absolute;
    }
    width: auto;
    display: inline-block;
    position: relative;
    vertical-align: top;
  }
  .e_form_error_span {
    position: absolute;
    color: #fefefe;
    bottom: 0;
    right: 0;
    background: #731513;
    border: 1px solid #731513;
    padding: 0 10px;
    width: auto;
    z-index: 100;
    word-break: break-word;
  }
  .e_form_warning {
    text-align: center;
    padding: 0;
    margin: 10px auto;
  }
  .additionalOption {
    &.visibleOption {
      display: block;
    }
    display: none;
  }
  .fixedHeightContainer{
    height: 150px;
  }
  .elaine-dropdown {
    margin: 0;
    padding: 0;
    background: transparent;
  }
  fieldset {
    &:first-of-type{
      margin-top: 5px;
    }
    &.disabled{
      .switch-legend {
        &[disabled]{
          .switch-item{
            cursor: default;
          }
          opacity: .5;
        }
      }
      [disabled]{
        opacity: 1;
      }
      .form-group{
        opacity: .5;
      }
    }
    .script-editor-container {
      .script-editor-inner {
        height:620px;
        min-height:620px;
        min-width:100%;
        max-width:100%;
      }
      width:100%;
      height:620px;
      display:block
    }

    .elaine-user-scroll-container {
      height:480px;
      display:block
    }

    .message-clearing-hint-container{
      height: 100px;
    }
    .message-clearing-failure-container{
      height: 300px;
    }

    &.grouped{
      margin-bottom: 0;
    }
    &.global{
      .switch-legend{
        .e_icn{
          visibility: hidden;
        }
      }
      padding-top: 0;
    }
    .switch-legend{
      .switch-item{
        vertical-align: middle;
        margin-right: 5px;
      }
      > .e_icn{
        &.cross{
          &:after{
            content: 'X';
            color: #c31a1d;
          }
        }
        &.hook{
          &:after{
            content: '=';
            color: #6d891b;
          }
        }
        width: 20px;
        height: 20px;
        line-height: 20px;
        display: inline-block;
      }
      padding-top: 10px
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    &.withoutPaddingTop {
      padding-top: 0;
      margin: 0 0 20px 0;
    }
    &.noPaddingMargin{
      padding-top: 0;
      margin: 0;
    }
    &.moreMarginTop{
      margin-top: 30px;
    }
    .marginBottom{
      margin-bottom: 10px;
    }
    .variant-container{
      .variant-container-inner{
        height: 135px;
        display: block;
      }
      height: 270px;
      display: block;
    }

    &.additionalOption {
      &.visibleOption {
        display: block;
      }
      display: none;
    }
    border: none;
    margin: 0 0 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  legend {
    width: inherit;
    border-bottom: none;
    background: transparent;
    color: #fefefe;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .form-block {
    width: 95%;
    margin-left: 5%;
  }
  .element-block {
    &.disabled {
      opacity: .5;
    }
    width: 100%;
    opacity: 1;
  }
  .inline-element{
    width:50%;
    display:inline-block;
  }
  .form-control {
    &.skip{
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 449px;
      white-space: nowrap;
      display: table-cell;
    }
    &:focus:not( [disabled] ) {
      background: #797d7c;
      border: none;
    }
    border-radius: 0;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    background: #404644;
    border:none;
    color: #fefefe;
    font-size: 12px;
    vertical-align: top;
    outline: none !important;
    box-shadow: none;
    -ms-user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    user-select: text;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }
  .edb-form-head{
    .form-group {
      &:last-of-type{
        margin-left: 30px;
      }
      width: 40%;
      display: inline-block;
      vertical-align: middle;
    }
    padding: 15px 15px 0 15px;
  }
  .edb-form-body{
    .edb-form-body-top{
      .edb-form-body-column {
        &:not( :last-of-type ){
          &:after{
            content: url(/gui/img/dbr-arrow.svg );
            position: absolute;
            font-size: 25px;
            right: -20%;
            top: -5px;
            width:125px;
          }
          // in Edge arrows can only have a width of 110px
          @supports (-ms-ime-align:auto) {
            &:after {
              width: 110px;
            }
          }
        }
        .text{
          font-size: 16px;
          padding-left: 10px;
        }
        text-align: center;
        height: auto;
        margin-top: 25px;
        position: relative;
      }
      height: 75px;
    }
    .edb-form-body-bottom{
      .edb-form-body-column{
        &:not( :last-of-type ){
          border-right: 1px solid #000;
        }
        &.event-type,&.filter{
          label{
            display: none;
          }
          .styled-select{
            width: 100%;
          }
        }
        &.timer{
          label{
            &[for="timing"]{
              display: none;
            }
            width: 100%;
          }
          .styled-select{
            width: 100%;
            margin-bottom: 10px;
          }
          .date-element{
            width: 100%;
          }
          span{
            &.ui-spinner{
              width: 78%;
              vertical-align: top;
            }
            &.additional{
              width: 20%;
              vertical-align: top;
            }
          }
        }
        &.action{
          label{
            &[for="action"]{
              display: none;
            }
            width: 100%;
          }
          p{
            display: none;
          }
          .styled-select{
            width: 100%;
          }
        }
        fieldset{
          &.main,&.choose{
            margin: 0 0 10px 0;
          }
        }
        .fixedHeightContainer{
          fieldset{
            .form-group{
              &.form-group-select {
                .additional {
                  margin-top: 4px;
                }
              }
              &.form-group-select-multiple {
                .styled-multiple-selection {
                  width: 100%;
                  position: relative;
                }
              }
              &.form-group-textarea {
                .form-group-textarea-inner {
                  width: 100%;
                }
                width: 265px;
              }
            }
            padding: 0;
          }
          padding: 0 10px;
        }
        height: 100%;
        vertical-align: top;
      }
      height: 300px;
    }
    .edb-form-body-column {
      label{
        span{
          float: left;
          right: inherit;
        }
      }
      display: inline-block;
      width: 24.5%;
    }
    padding: 0 15px 15px 15px;
  }
  .form-group {
    &.form-group-skip-through{
      .input-group{
        .input-group-addon{
          &:hover:not( [disabled] ){
            .e_icn {
              color: #fefefe;
            }
            background: #6d891b;
          }
          .e_icn{
            color: #000;
          }
          max-width: 30px;
          padding: 6px 7px;
          background: #fefefe;
          border: none;
        }
      }
    }
    &.form-group-eswitch{
      .switch-item{
        vertical-align: middle;
      }
    }
    .ui-spinner{
      &.ui-state-disabled{
        .ui-spinner-button {
          &:hover {
            background: #404644;
          }
          cursor: default;
          opacity: .3;
        }
      }
      [type="number"] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
        width: calc( 100% - 30px );
      }
      .ui-spinner-button {
        &.ui-spinner-up{
          top: 0;
        }
        &.ui-spinner-down{
          top: 15px;
        }
        .ui-icon{

        }
        display: inline-block;
        width: 30px;
        height: 15px;
        line-height: 15px;
        position: absolute;
        right: 0;
        text-align: center;
        text-decoration: none;
        color: #fefefe;
        border-left: 1px solid #000;
        cursor: pointer;
        outline: none;
      }
      position: relative;
      background: #404644;
    }
    .elaine-vendor-link{
      color: #6d891b;
    }
    label {
      .e_icn {
        display: inline-block;
        color: #731513;
        float: right;
        height: 30px;
        line-height: 30px;
        position: absolute;
        right: 0;
        top: 0;
      }
      .label-text{
        display: block;
        max-width: calc( 100% - 15px );
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 30px;
      }
      &.label-about{
        width: 30%;
      }
      position: relative;
      display: inline-block;
      width: 23%;
      max-width: 100%;
      margin: 0;
      height: 30px;
      line-height: 30px;
      vertical-align: top;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    > span:not( .switch-item ){
      height: auto;
      line-height: 30px;
      display: inline-block;
    }
    .form-group-buttons{
      display: inline-block;
      width: 76%;
      height: 30px;
      margin-left: 23.5%;
    }
    .form-radio-plus-input-container{
      width: 71%;
      display: inline-block;
      padding-left: 5px;
    }
    .form-radio-plus-input-container-button{
      display: inline-block;
      float: right;
      overflow: hidden;
      width: 27%;
      padding-top: 37px;
     }
    .button-drop-down{
      &:hover,&.active{
        &[disabled] {
          background: #404644;
          border-color: #404644;
        }
        background: #6d891b;
      }
      &.left{
        .button-drop-down-menu-container {
          .button-drop-down-menu {
            .button-drop-down-menu-item {
              .button-drop-down-submenu{
                right: calc( 100% + 2px );
              }
            }
          }
          right: calc( 100% + 2px );
        }
      }
      &.right{
        .button-drop-down-menu-container {
          .button-drop-down-menu {
            .button-drop-down-menu-item {
              .button-drop-down-submenu{
                left: calc( 100% + 2px );
              }
            }
          }
          left: calc( 100% + 2px );
        }
      }
      .button-drop-down-menu-container{
        .button-drop-down-menu{
          .button-drop-down-menu-item{
            &:hover{
              background: #6d891b;
            }
            a{
              color: inherit;
              text-decoration: none;
              padding: 0 5px 0 5px;
              width: 100%;
              height: 30px;
              line-height: 30px;
              display: block;
              cursor: pointer;
            }
            .button-drop-down-submenu{
              margin: 0px;
              padding: 0;
              position: absolute;
              top: 0;
              list-style: none;
              background: #404644;
            }
            width: 100%;
            height: 30px;
            display: block;
            text-align: left;
          }
          width: 150px;
          height: auto;
          margin: 0px;
          padding: 0;
          list-style: none;
          background: #404644;
        }
        min-width: 150px;
        display: block;
        top: 0;
        position: absolute;
        height: 200px;
        z-index: 1000;
        outline: none;
      }
      > span{
        &.e_icn{
          height: 100%;
          width: 100%;
          display: block;
          line-height: 30px;
        }
      }
      background: #404644 none repeat scroll 0 0;
      border-color: #404644;
      width: 9.75%;
      position: relative;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      color: #fefefe;
      cursor: pointer;
      text-align: center;
      outline: none;
    }

    &.form-addition {
      &.em_form {
        &.form-group-input {
          label {
            display: none;
          }
          input {
            width: 100%;
          }
        }
      }
      label {
        opacity: 0;
        width: calc( 24% - 20px );
      }
      &.with-label{
        label {
          opacity: 1;
        }
      }
      width: calc( 100% - 20px );
      margin-left: 20px;
    }
    &.form-group-input{
      &.limit{
        .ui-spinner{
          input[type="number"]{
            width: calc( 100% - 30px );
          }
          width: 15%;
        }
        input[type="number"]{
          width:50%;
        }
        .form-group-checkbox{
          label{
            span{
              &:before{
                top: 9px;
                left: 10px;
              }
              &:after{
                top: 12px;
                left: 12px;
              }
            }
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
          }
          display: inline-block;
          width: 20%;
          margin-bottom: 0;
        }
      }
    }
    &.form-group-tag-config{
      .tag-select{
        .tag-select-inner{
          .tag-select-disable{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: .5;
            cursor: default;
          }
          .tag-select-option{
            &:hover,&.selected{
              background: #6d891b;
            }
            display: block;
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding-left: 5px;
          }
          display: block;
          width: 100%;
          height:auto;
          overflow: auto;
          position: relative;
        }
        &.combi{
          min-width: 0px;
        }
        display: inline-block;
        min-width: 610px;
        width: 100%;
        background: #404644;
        height: 200px;
      }
    }
    &.form-group-input-color{
      .invisible{
        width: 0;
        height: 0;
        padding: 0;
        border: none;
        float: left;
      }
      .input-group{
        &.picker-only{
          .sp-replacer{
            border: none;
          }
        }
        .input-group-addon{
          &:hover{
            background: #6d891b;
          }
        }
        display: inline-block;
        width: 76%;
      }
      input{
        width: calc( 100% - 56px );
        display: inline-block;
        vertical-align: top;
      }
      .sp-replacer{
        &:hover{
          .sp-dd{
            opacity: 1;
          }
        }
        .sp-dd{
          color: #fefefe;
          opacity: .5;
        }
        display: inline-block;
        background: #404644;
        border-color: #404644;
        border-left: 1px solid #000;
        padding-left: 10px;
      }
      .input-group-addon{
        width: 10%;
        display: inline-block;
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        background: #404644;
        border: none;
        color: #fefefe;
        cursor: pointer;
        padding: 0 5px;
        border-left: 1px solid #000;
      }
    }
    &.form-group-value-show{
      height: 30px;
    }
    &.form-group-salutation-config{
      .salutation-select{
        .salutation-select-inner{
          .salutation-select-option{
            &:hover,&.selected{
              background: #6d891b;
            }
            display: block;
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          height:auto;
          width: 100%;
          overflow: auto;
          display: block;
        }
        display: inline-block;
        min-width: 530px;
        width: 100%;
        background: #404644;
        height: 200px;
      }
    }

    &.form-group-recipientGroup-config{
      .recipientGroup-select{
        .recipientGroup-select-inner{
          .recipientGroup-select-option{
            &:hover,&.selected{
              background: #6d891b;
            }
            display: block;
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding-left: 5px;
          }
          height:auto;
          width: 100%;
          overflow: auto;
          display: block;
        }
        display: inline-block;
        min-width: 530px;
        width: 100%;
        background: #404644;
        height: 200px;
      }
    }
    &.form-group-profiling{
      .form-custom-group{
        .from-additional-label{
          &:first-of-type{
            text-align: left;
          }
          display: inline-block;
          width: 9%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          vertical-align: top;
        }
        .form-group-select{
          label{
            display: none;
          }
          .styled-select{
            &[disabled]{
              &:hover {
                &:before {
                  background: #404644;
                }
              }
            }
            width: 100%;
          }
          display: inline-block;
          vertical-align: top;
          width: 35%;
        }
        .form-group-input{
          label{
            display: none;
          }
          input{
            width: 100%;
          }
          display: inline-block;
          width: 35%;
        }
      }
    }
    &.form-group-select-time{
      .form-group-select{
        label{
          display: none;
        }
        .styled-select{
          &[disabled]{
            &:hover {
              &:before {
                background: #404644;
              }
            }
          }
          width: 100%;
        }
        width: 49.5%;
        display: inline-block;
        vertical-align: top;
      }
      .form-group-input-date{
        label{
          display: none;
        }
        .date-element{
          width: 100%;
        }
        width: 49.5%;
        display: inline-block;
      }
    }
    &.form-group-date-filter{
      .form-additional-label{
        display: inline-block;
        width: calc( 23% - 15px );
        height: 30px;
        line-height: 30px;
        text-align: left;
        vertical-align: top;
      }
      .form-group-select{
        label{
          display: none;
        }
        .styled-select{
          width: 100%;
        }
        width: 26%;
        display: inline-block;
        vertical-align: top;
      }
      .form-group{
        &.form-group-input {
          &.withSpan {
            input[type="number"] {
              width: calc( 100% - 30px );
            }
          }
        }
      }
      .form-group-input{
        label{
          display: none;
        }
        .ui-spinner{
          width: 90px;
        }
        .additional{
          width: calc( 100% - 95px );
        }
        width: 25%;
        display: inline-block;
      }
    }
    &.form-group-caused-delay{
      .form-additional-label{
        &:first-of-type{
          text-align: left;
        }
        &.large{
          width: 20%;
        }
        display: inline-block;
        width: 9%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        vertical-align: top;
      }
      .form-group-select{
        label{
          display: none;
        }
        .styled-select{
          width: 100%;
        }
        &:last-of-type{
          width: 25%;
        }
        width: 15%;
        display: inline-block;
        vertical-align: top;
      }
      .form-group-input{
        label{
          display: none;
        }
        .input{
          width: 100%;
        }
        width: 10%;
        display: inline-block;
      }
    }
    &.form-group-input{
      &.withSpan{
        &.full-span{
          input{
            &[type="number"]{
              width: 35%;
            }
            width: 76%;
          }
          span{
            &.additional{
              margin-left: 24%;
              width: 76%;
              text-align: left;
            }
          }
        }
        input{
          &[type="number"]{
            width: 100%;
          }
          width: 66%;
        }
        span{
          &.additional{
            width: 10%;
            text-align: left;
          }
        }
      }
      // don't show clear button in IE
      input::-ms-clear {
        display: none;
      }
      input {
        display: inline-block;
        width: 76%;
      }
    }
    &.form-group-input-with-button {
      input{
        width: 49.5%;
        display: inline-block;
      }
      .text-display {
        span{
          display: block;
          width: 100%;
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        width: 49.5%;
        display: inline-block;

      }
      .additional-information{
        display: inline-block;
        margin-left: 23.5%;
      }
      button {
        &:hover, &:active, &:focus{
          &[disabled] {
            background: #404644;
            border-color: #404644;
          }
          background: #6d891b;
          border-color: #6d891b;
        }
        vertical-align: top;
        border-color: #404644;
        background: #404644;
        width: calc( 26% - 1px );
        display: inline-block;
      }
    }
    &.form-group-input-with-dropdown{
      input{
        width: 65.5%;
        display: inline-block;
      }
    }
    &.form-group-input-upload-file {
      &.complex-image{
        .e_icn_btn{
          &.icon-close{
            width: 30px;
          }
          width: 21.5%;
        }
        input {
          &[type="text"] {
            display: inline-block;
            width: 49.5%;
          }
        }
        &.medialibrary{
          input {
            &[type="text"] {
              display: inline-block;
              width: 40%;
            }
          }
          .e_icn_btn{
            &:not( .icon-close ){
              width: calc( 17.5% - 15px);
            }
          }
        }
      }
      &.medialibrary{
        input {
          &[type="text"] {
            display: inline-block;
            width: 40%;
          }
        }
        button{
          width: 17.5%;
        }
      }
      input {
        &[type="file"] {
          opacity: 0;
          width: 0;
          height: 0;
        }
        &[type="text"] {
          display: inline-block;
          width: 50%;
        }
      }
      button{
        &:hover, &:active, &:focus {
          &[disabled] {
            background: #404644;
            border-color: #404644;
          }
          background: #6d891b;
          border-color: #6d891b;
        }
        background: #404644;
        border-color: #404644;
        width: 25.5%;
      }
      .additional-information{
        display: inline-block;
        margin-left: 23.5%;
      }
    }
    &.form-group-triple-input{
      .form-group{
        label{
          display: none;
        }
        .ui-spinner{
          &.ui-state-disabled{
            input{
              opacity: 1;
            }
            opacity: .3;
          }
          width: 120px;
        }
        width: 30%;
        display: inline;
      }
      .form-control{
        width: 16%;
        display: inline-block;
      }

    }
    &.form-group-input-date {
      .date-element {
        &[disabled]{
          .date-toggle {
            &.dropdown-toggle {
              &:hover {
                .input-group-addon {
                  background: #404644;
                }
              }
            }
          }
        }
        &.invisible{
          display: none;
        }
        &.timeOnly{
          table{
            &.hour-view, &.minute-view{
              thead{
                display: none;
              }
            }
          }
        }
        .date-toggle{
          &:hover:not( [disabled] ){
            .input-group-addon {
              background: #6d891b;
            }
          }
          float: left;
          width: calc( 100% - 30px );
          overflow: hidden;
        }
        input {
          display: inline-block;
          width: calc( 100% - 30px );
        }
        span{
          &[disabled]{
            opacity: .5;
          }
          &.form-control{
            &.small {
              width: 20%;
            }
            display: inline-block;
            width: calc( 100% - 30px );
            line-height: 30px;
            padding: 0 5px;
          }
        }
        .date-input-group{
          display: inline-block;
          width: calc( 100% - 33px );
        }
        .input-group-addon{
          &.removeDate{
            &:hover:not( [disabled] ){
              background: #731513;
            }
          }
          &.dateAddon {
            &:hover:not( [disabled] ) {
              background: #6d891b;
            }
          }
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0 5px;
          border: none;
          border-radius: 0;
          background: #404644;
          border-left: 1px solid #000
        }
        width: 76%;
        display: inline-block;
      }
    }
    &.form-group-input-date-period {
      &.no-label{
        > label{
          width: 10px;
        }
        .date-element{
          width: calc( 100% - 15px );
        }
      }
      .date-element {
        &[disabled]{
          .date-toggle {
            &.dropdown-toggle {
              &:hover {
                .input-group-addon {
                  background: #404644;
                }
              }
            }
          }
        }
        &.invisible{
          display: none;
        }
        &.timeOnly{
          table{
            &.hour-view, &.minute-view{
              thead{
                display: none;
              }
            }
          }
        }
        .date-element-begin{
          display: inline-block;
          position: relative;
          width: 40%;
          float: left;
        }
        .date-element-end{
          display: inline-block;
          position: relative;
          width: 40%;
          float: left;
        }
        .endLabel{
          width: 10%;
          display: inline-block;
          text-align: center;
          float: left;
        }
        .beginLabel{
          width: 10%;
          display: inline-block;
          text-align: center;
          float: left;
        }
        &.noBeginLabel{
          .beginLabel{
            display: none;
          }
          .date-element-begin{
            display: inline-block;
            position: relative;
            width: 43%;
          }
          .date-element-end{
            display: inline-block;
            position: relative;
            width: 43%;
          }
          .endLabel{
            width: 10%;
            display: inline-block;
            text-align: center;
          }
        }
        .date-toggle{
          &:hover:not( [disabled] ){
            .input-group-addon {
              background: #6d891b;
            }
          }
          float: left;
          width: calc( 100% - 30px );
          overflow: hidden;
        }
        input {
          display: inline-block;
          width: calc( 100% - 30px );
        }
        span{
          &[disabled]{
            opacity: .5;
          }
          &.form-control{
            &.small {
              width: 20%;
            }
            display: inline-block;
            width: calc( 100% - 30px );
            line-height: 30px;
            padding: 0 5px;
          }
        }
        .date-input-group{
          display: inline-block;
          width: calc( 100% - 33px );
        }
        .input-group-addon{
          &.removeDate{
            &:hover:not( [disabled] ){
              background: #731513;
            }
          }
          &.dateAddon {
            &:hover:not( [disabled] ) {
              background: #6d891b;
            }
          }
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0 5px;
          border: none;
          border-radius: 0;
          background: #404644;
          border-left: 1px solid #000
        }
        width: 76%;
        display: inline-block;
      }
    }
    &.form-group-textarea {
      &.form-group-textarea-with-button{
        label{
          display: inline-block;
        }
        .form-group-textarea-inner{
          display: inline-block;
          width: 76%;
        }
        .form-group-textarea-buttons{
          .button-drop-down{
            display: inline-block;
            width: 125px;
          }
          button{
            display: inline-block;
            width: 125px;
          }
          input[type="file"] {
            opacity: 0;
            width: 0;
            height: 0;
          }
          display: inline-block;
          width: 76%;
          margin-left: 23%;
          margin-top: 10px;
        }

      }
      label{
        vertical-align: top;
      }
      .form-group-textarea-inner{
        textarea {
          min-height: 108px;
          width: 100%;
          resize: none;
          overflow: hidden;
          line-height: 18px;
          padding-top: 6px;
          padding-bottom: 5px;
        }
        display: inline-block;
        height: 108px;
        width: 76%;
        overflow: hidden;
      }
    }
    /* tiny MCE custom*/
    &.form-group-textarea-rich {
      .mce-tinymce {
        display: inline-block;
        width: 76%;
      }
    }
    &.form-group-radio{
      label{
        input{
          &[disabled]{
            + span{
              cursor: default;
            }
          }
        }
        span{
          width: calc( 100% - 18px );
          display: inline-block;
          padding-left: 18px;
          cursor: pointer;
        }
        input{
          &:checked,&:not(:checked){
            + span{
              &:before {
                content: '';
                position: absolute;
                left: 1px;
                top: 3px;
                width: 12px;
                height: 12px;
                border: 2px solid #fefefe;
                background: #404644;
                border-radius: 50%;
                box-shadow: inset 0 1px 3px #404644
              }
            }
          }
          /* checkbox mark  */
          &:checked{
            + span{
              &:after {
                content: '';
                background: #fefefe;
                position: absolute;
                width: 6px;
                height: 6px;
                top: 6px;
                left: 4px;
                font-size: 10px;
                line-height: 0.8;
                color: #fefefe;
                transition: all .2s;
                border-radius: 50%;
                outline: none;
              }
            }
          }
          /* checked mark aspect changes */
          &:not(:checked){
            + span{
              &:after{
                opacity: 0;
                transform: scale(0);
              }
            }
          }
          display: none;
          float: left;
          margin: 2px 5px 0 0;
        }
        width: 100%;
        height: auto;
        line-height: 20px;
      }
    }
    &.form-group-checkbox{
      label{
        &:hover{
          input{
            &[disabled]{
              + span{
                &:not( .e_icn ){
                  color: #fefefe;
                }
              }
            }
          }
        }
        span{
          &:hover{
            color: #6d891b;
          }
          width: calc( 100% - 18px );
          padding-left: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }
        input{
          &:checked,&:not(:checked){
            + span{
              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 4px;
                width: 12px;
                height: 12px;
                background: #404644;
                border-radius: 0px;
                box-shadow: inset 0 1px 3px #404644
              }
            }
          }
          /* checkbox mark  */
          &:checked{
            + span{
              &:after {
                font-family: $iconFontFamily;
                content: ',';
                position: absolute;
                top: 7px;
                left: 2px;
                font-size: 8px;
                line-height: 0.8;
                color: #fefefe;
                transition: all .2s;
                border-radius: 0;
                outline: none;
                opacity: 1;
                transform: scale(1);
              }
            }
          }
          /* checked mark aspect changes */
          &:not(:checked){
            + span{
              &:after{
                opacity: 0;
                transform: scale(0);
              }
            }
          }
          display: none;
          float: left;
          margin: 2px 5px 0 0;
        }
        width: 100%;
        height: 20px;
        line-height: 20px;
      }
    }
    &.form-group-checkbox-labeled{
      .checkbox-container{
        &[disabled]{
          .additional-checkbox-label{
            &:not([disabled]):hover{
              color: #fefefe;
            }
          }
        }
        .additional-checkbox-label{
          &:not([disabled]):hover{
            color: #6d891b;
          }
          padding-left: 20px;
          outline: none;
        }
        input{
          &:checked,&:not(:checked){
            + span{
              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 8px;
                width: 12px;
                height: 12px;
                background: #404644;
                border-radius: 0px;
                box-shadow: inset 0 1px 3px #404644
              }
            }
          }
          /* checkbox mark  */
          &:checked{
            + span{
              &:after {
                font-family: $iconFontFamily;
                content: ',';
                position: absolute;
                top: 12px;
                left: 2px;
                font-size: 8px;
                line-height: 0.8;
                color: #fefefe;
                transition: all .2s;
                border-radius: 0;
                outline: none;
                opacity: 1;
                transform: scale(1);
              }
            }
          }
          /* checked mark aspect changes */
          &:not(:checked){
            + span{
              &:after{
                opacity: 0;
                transform: scale(0);
              }
            }
          }
          display: none;
          float: left;
          margin: 5px 5px 0 0;
        }
        position: relative;
        height: 30px;
        line-height: 30px;
        width: 76%;
        display: inline-block;
      }
    }
    &.form-group-checkbox-group {
      .form-group-checkbox-group-no-permissions{
        p{
          margin: 0;
        }
      }
      .form-group-checkbox-group-container{
        .form-group-checkbox-group-inner{
          display: block;
          width: 100%;
          height: auto;
        }
        display: block;
        width: 510px;
        height: 200px;
        margin-bottom: 10px;
      }
      legend{
        label{
          span{
            &:hover{
              color: #6d891b;
            }
            white-space: nowrap;
            width: calc( 100% - 18px );
            padding-left: 18px;
          }
          input{
            &:checked,&:not(:checked){
              + span{
                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 3px;
                  width: 12px;
                  height: 12px;
                  background: #404644;
                  border-radius: 0px;
                  box-shadow: inset 0 1px 3px #404644
                }
              }
            }
            /* checkbox mark  */
            &:checked{
              + span{
                &:after {
                  font-family: $iconFontFamily;
                  content: ',';
                  position: absolute;
                  top: 7px;
                  left: 2px;
                  font-size: 8px;
                  line-height: 0.8;
                  color: #fefefe;
                  transition: all .2s;
                  border-radius: 0;
                  outline: none;
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }
            /* checked mark aspect changes */
            &:not(:checked){
              + span{
                &:after{
                  opacity: 0;
                  transform: scale(0);
                }
              }
            }
            display: none;
            float: left;
            margin: 5px 5px 0 0;
          }
          width: 100%;
          height: auto;
          line-height: 20px;
        }
        margin: 0;
        height: 20px;
        line-height: 20px;
      }
      .slave {
        width: calc( 50% - 15px );
        display: inline-block;
        margin-left: 15px;
      }
      margin-bottom: 10px;
    }
    &.form-group-checkbox-membership {
      .form-group-checkbox-membership-grid {
        .form-group-checkbox-membership-row {
          &:nth-child( even ) {
            background: #1f2523;
          }
        }
      }
      legend{
        &.scrolling{
          .iconContainer{
            width: 96px;
          }
        }
        .iconContainer{
          .e_icn{
            margin-right: 12px;
            float: left;
            height: 100%;
            line-height: 30px;
          }
          float: right;
          overflow: hidden;
          width:90px;

        }
        width: 100%;
        border-bottom: 2px solid #000;
        margin-bottom: 0;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
      }
      .form-group-checkbox-membership-inner{
        height:125px;
        overflow: hidden;
        margin: 0;
      }
      label{
        &.disabled{
          input,span{
            cursor: default !important;
          }
          opacity: .25;
          cursor: default;
        }
        width: auto;
        line-height: 30px;
      }
      .border {
        border-bottom: 1px solid #000;
      }
      .description {
        span{
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        width: calc( 100% - 90px);
        overflow: hidden;
        display: inline-block;
        float: left;
        line-height: 30px;
        padding-left: 10px;
      }
      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
      }
      [type="checkbox"]:not(:checked) + span,
      [type="checkbox"]:checked + span {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
      }
      /* checkbox box element */
      [type="checkbox"]:not(:checked) + span:before,
      [type="checkbox"]:checked + span:before {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 12px;
        height: 12px;
        background: #404644;
        border-radius: 0px;
        box-shadow: inset 0 1px 3px #404644
      }
      /* checkbox mark  */
      [type="checkbox"]:checked + span:after {
        font-family: $iconFontFamily;
        content: ',';
        position: absolute;
        top: 6px;
        left: 1px;
        font-size: 8px;
        line-height: 0.8;
        color: #fefefe;
        transition: all .2s;
        border-radius: 0;
        outline: none;
      }
      /* checked mark aspect changes */
      [type="checkbox"]:not(:checked) + span:after, [type="radio"]:not(:checked) + span:after {
        opacity: 0;
        transform: scale(0);
      }
      [type="checkbox"]:checked + span:after, [type="radio"]:checked + span:after {
        opacity: 1;
        transform: scale(1);
      }
      /* hover style just for information */
      label :hover :before {
        border-color: #6d891b !important;
        width: 20%;
        overflow: hidden;
        display: inline-block;
        float: right;
      }
      margin-bottom: 20px;
      border: 1px solid #000;
    }
    &.form-group-select, &.form-group-select-with-button{

      &.targetGroupFilter{
        .styled-select{
          width: 45%;
        }
        button{
          width: 30.5%;
        }
      }
      &.half{
        label{
          display: none;
        }
        .styled-select{
          width: 45%;
        }
      }
      &.fallBack{
        .styled-select {

          &:before {
            content: '';
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background: #404644;
            position: absolute;
            pointer-events: none;
            display: block;
            border-left: 1px solid #000;
          }
        }
      }
      &.optional{
        label{
        }
      }
      .additionalSubOption{
        .form-group{
          margin-bottom: 0;
        }
        min-height: 30px;
        width: 100%;
        display: block;
        margin: 10px 0 15px;
      }
      .additional{
        .operator{
          width: 76%;
          height: 30px;
          display: inline-block;
          line-height: 30px;
          padding: 0 5px;
          color: #fefefe;
        }
        &:last-of-type {
          margin-top: 15px;
        }
        margin-top: 10px;
      }
      .styled-select{

        &.additional{
          margin-left: 23.5%;
          margin-top: 10px;
        }
        &[disabled]{
          .chosen-container{
            &:before {
              opacity: .3;
            }
          }
          &:hover {
            .chosen-container{
              &:before {
                background: #404644;
              }
            }
            &:before {
              background: #404644;
            }
          }
        }
        select{
          option{
            &[disabled]{
              color: #fefefe;
            }
          }
          optgroup{
            font-weight: bold;
          }
          outline: none;
          display: inline-block;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 100%;
        }
        vertical-align: top;
        position: relative;
        width: 76%;
        display: inline-block;
      }
    }
    &.form-group-select-with-button {
      &.em_form {
        .styled-select {
          width: 74%;
        }
        button {
          width: 25.75%;
        }
      }
      .styled-select{
        width: 50%;
      }
      button {
        border-color: #404644;
        background: #404644;
        width: 25.5%;
        display: inline-block;
      }
    }
    &.form-group-select-multiple {
      .styled-multiple-selection {
        .chosen-choices{
          //For Old IE
          width: 92%;
          //For the rest
          width:  calc( 100% - 33px );
        }
        .chosen-container:before {
          background: #404644;
        }
        &:hover:not( [disabled] ) {
          .chosen-container:before {
            background: #6d891b;
          }
        }
      }
      &.data-source-select{
        .styled-multiple-selection {
          .chosen-container{
            &.chosen-container-multi{
              &:before{
                height: 200px;
              }
              .chosen-choices{
                min-height: 200px;
              }
            }
          }
          height: 200px;
        }
      }
      label{
        vertical-align: top;
      }
      .styled-multiple-selection {

        &[disabled]{
          &:hover {
            .chosen-container {
              &:before {
                background: #404644;
              }
            }
          }
        }
        select {
          width: calc( 100% + 20px );
          height: 100%;
          overflow: hidden;
        }
        display: inline-block;
        vertical-align: top;
        overflow: visible;
        width: 76%;
        height: 100px;
      }
    }
    &.form-group-select-tree {
      .tree-select {
        &:focus {
          .form-control {
            background: #797d7c;
          }
        }
        &[disabled] {
          .tree-select-show {
            &:hover {
              .tree-select-arrow {
                background: #404644;
              }
            }
          }
        }
        .tree-select-show {

          &:hover {
            .tree-select-arrow {
              background: #6d891b;
            }
          }
          .tree-select-show-span {
            .tree-select-text{
              display: block;
              width: calc( 100% - 40px );
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            display: block;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            position: absolute;
          }
          .tree-select-arrow {
            .e_icn {
              font-size: 1em;
              margin-left: 10px;
            }
            height: 30px;
            width: 33px;
            line-height: 30px;
            position: absolute;
            top: 0;
            right: 0;
            border-left: 1px solid #000;
            background: #404644;
          }
          display: block;
          height: 30px;
          width: 100%;
          position: relative;
          line-height: 30px;
          padding: 0;
        }
        .tree-select-options {
          &.active {
            display: block;
          }
          .tree-select-options-inner {
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
          }
          .tree-select-option {
            &:hover:not( [disabled] ){
              color: #6d891b;
            }
            &.focus:not( [disabled] ){
              color: #fefefe;
              background: #6d891b;
            }
            .tree-select-option-text{
              display: block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            display: block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding: 0 10px;

          }
          position: absolute;
          width: 100%;
          z-index: 1000;
          display: none;
          top: 31px;
          background: #404644;
          overflow: auto;
          border: 1px solid #000;
          border-top: none;
          height: 150px;
        }
        position: relative;
        width: 76%;
        display: inline-block;
        overflow: visible;
        outline: none;
      }

      overflow: visible;
      height: 30px;
    }
    &.form-group-inline-input{
      .input_element {
        label {
          .e_icn {
            float: right;
            color: #c31a1d;
          }
          display: inline-block;
          width: 19%;
          vertical-align: bottom;
          padding-left: 8%
        }
        input {
          display: inline-block;
          width: 39%;
        }
        width: 76%;
        display: inline-block;
      }
    }
    &.form-group-inline-radio {
      .inline-radio {
        label {
          span{
            padding-left: 20px;
            cursor: pointer;
          }
          input {
            &:checked, &:not(:checked) {
              + span {
                &:before {
                  content: '';
                  position: absolute;
                  left: 1px;
                  top: 8px;
                  width: 12px;
                  height: 12px;
                  border: 2px solid #fefefe;
                  background: #404644;
                  border-radius: 50%;
                  box-shadow: inset 0 1px 3px #404644
                }
              }
            }
            /* checkbox mark  */
            &:checked {
              + span {
                &:after {
                  content: '';
                  background: #fefefe;
                  position: absolute;
                  width: 6px;
                  height: 6px;
                  top: 11px;
                  left: 4px;
                  font-size: 10px;
                  line-height: 0.8;
                  color: #fefefe;
                  transition: all .2s;
                  border-radius: 50%;
                  outline: none;
                }
              }
            }
            /* checked mark aspect changes */
            &:not(:checked) {
              + span {
                &:after {
                  opacity: 0;
                  transform: scale(0);
                }
              }
            }
            display: none;
            float: left;
            margin: 2px 5px 0 0;
          }
          width: 49%;
          display: inline-block;
        }
        height: 30px;
        line-height: 30px;
        width: 76%;
        display: inline-block;
        .verticalDisplay{
          display: flex;
        }
      }
      overflow: hidden;
    }
    &.form-group-radio-input {
      label{
        &:hover{
          input{
            &[disabled]{
              + span{
                &:not( .e_icn ){
                  color: #fefefe;
                }
              }
            }
          }
        }
        span{
          padding-left: 25px;
        }
        input {
          &:checked, &:not(:checked) {
            + span {
              &:before {
                content: '';
                position: absolute;
                left: 1px;
                top: 8px;
                width: 12px;
                height: 12px;
                border: 2px solid #fefefe;
                background: #404644;
                border-radius: 50%;
                box-shadow: inset 0 1px 3px #404644
              }
            }
          }
          /* checkbox mark  */
          &:checked {
            + span {
              &:after {
                content: '';
                background: #fefefe;
                position: absolute;
                width: 6px;
                height: 6px;
                top: 11px;
                left: 4px;
                font-size: 10px;
                line-height: 0.8;
                color: #fefefe;
                transition: all .2s;
                border-radius: 50%;
                outline: none;
              }
            }
          }
          /* checked mark aspect changes */
          &:not(:checked) {
            + span {
              &:after {
                opacity: 0;
                transform: scale(0);
              }
            }
          }
          display: none;
          float: left;
          margin: 2px 5px 0 0;
        }
        display: inline-block;
        width: 30%;
        line-height: 30px;
      }
      [type="number"] {
        display: inline-block;
        width: calc( 100% - 30px );
        overflow: hidden;
        height: 30px;
        margin-right: 3px;
      }
      .input-addon{
        height: 30px;
        width: 17%;
        line-height: 30px;
        display: inline-block;
      }
    }
    &.form-group-target-group {
      .firstRow {
        .group {
          .groupSelection {
            &.form-group {
              &.form-group-select {
                label {
                  width: 33%;
                }
                .styled-select {
                  &[disabled]{
                    &:hover {
                      &:before {
                        background: #404644;
                      }
                    }
                  }
                  width: 66%;
                }
              }
            }
          }
          display: inline-block;
          overflow: hidden;
          float: left;
          width: 60%
        }
        .limit {
          display: inline-block;
          overflow: hidden;
          float: left;
          width: 30%
        }
        .dynamic {
          display: inline-block;
          overflow: hidden;
          float: left;
          width: 20%;
          padding-left: 2%;
        }
        .buttons {
          button {
            &:hover, &:active, &:focus{
              background: #6d891b;
              border-color: #6d891b;
            }
            background: #404644;
            border-color: #404644;
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 1px;
          }
          display: inline-block;
          overflow: hidden;
          float: left;
          width: 20%;
        }
      }
      .secondRow {
        .group {
          .filterSelection {
            &.form-group {
              &.form-group-select-with-button {
                label {
                  width: 33%;
                }
                button {
                  width: 25%;
                  margin-left: 1%;
                }
                .styled-select {
                  &[disabled]{
                    &:hover {
                      &:before {
                        background: #404644;
                      }
                    }
                  }
                  width: 40%;
                }
              }
            }
          }
          display: inline-block;
          overflow: hidden;
          float: left;
          width: 60%
        }
        .limit {
          .limitInput {
            &.form-group {
              &.form-group-input {
                label {
                  width: 26%;
                }
                input {
                  width: 70%;
                }
                display: inline-block;
              }
            }
          }
          display: inline-block;
          overflow: hidden;
          float: left;
          padding-left: 2%;
          width: 20%
        }
      }
    }
    &.form-inline-input-container{
      .form-inline-input {
        input {
          display: inline-block;
          width: calc(50% - 22px);
          margin: 2px;
        }
        button {
          &:hover, &:focus{
            background: #731513;
            border-color: #731513;
          }
          width: 30px;
          margin-top: 2px;
          line-height: 30px;
          display: inline-block;
          text-align: center;
          padding: 0;
        }
        display: block;
        width: 100%;
        height: auto;
        padding-right: 5px;
      }
      width: 100%;
      height: 150px;
      overflow: auto;
      display: block;
    }
    &.inline-half{
      label{
        width: 100px;
      }
      input,.styled-select{
        width: calc( 100% - 105px );
      }
      width: 49%;
      display: inline-block;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    margin-bottom: 1.5%;
    position: relative;
  }
  /*TABS*/
  .tabbable {
    .nav {
      li {
        &.active {
          a {
            background: transparent;
            color: #fefefe;
            border-bottom: 2px solid #6d891b;
          }
        }
        .drop-down-submenu{
          li{
            &:hover{
              a{
                border: none;
              }
              background: #6d891b;
            }
            a{
              display: block;
              text-decoration: none;
            }
            padding: 0 10px;
            border: none;
          }
          position: absolute;
          list-style: none;
          padding: 0;
          -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
          box-shadow: 0 6px 12px rgba(0,0,0,0.175);
          background: #282e2c;
          z-index: 100;
        }
        a {
          &:not( [disabled] ){
            &:focus, &:hover {
              border-bottom: 2px solid #6d891b;
            }
          }
          &:focus, &:hover {
            background: transparent;
            color: #fefefe;
          }
          .icon-close{
            margin-left: 10px;
          }
          border: none;
          height: 100%;
          color: #fefefe;
          padding: 0 10px;
          line-height: 28px;
          outline: none;
        }
        height: 100%;
        border-right: 1px solid #000;
      }
      border-bottom: 1px solid #000;
      margin: 0;
      height: 30px;
    }
  }
  //custom to datetimepicker css
  .datetimepicker {
    .table{
      .left,.right,.switch{
        &:hover{
          background: #6d891b;
        }
      }
      td{
        &.disabled{
          opacity: .5;
          cursor: default;
        }
        &.day{
          &.current{
            color: #fefefe;
            background: transparent;
            //border: 1px solid #6d891b;
            border: none;
            outline: 1px solid #6d891b;
            outline-offset: -1px;
          }
          &:hover:not(.disabled),&.active:not(.disabled){
            background: #6d891b;
          }
        }
        span{
          &.disabled{
            opacity: .5;
            cursor: default;
          }
          &.hour,&.minute{
            &:hover:not(.disabled) {
              color: #fefefe;
              background: #6d891b;
            }
          }
          &.current{
            &:hover:not(.disabled){
              color: #fefefe;
              background: #6d891b;
            }
            background: transparent;
            border: 1px solid #6d891b;

          }
          border-radius: 0;
        }
      }

    }
    background: #404644;
    border-radius: 0;
  }
  /*ADDITIONAL FOR CHOSEN LIB*/
  .chosen-container{

    &.chosen-disabled{
      &:hover{
        &:before{
          background: #404644;
        }
      }
      &:before{
        background: #404644;
      }
      cursor: default;
    }
    &.chosen-container-multi{
      &:before {
        height: 100px;
      }
      .chosen-drop {
        .result-selected {
          color: #ccc;
          background: #6d891b;
        }
      }
      .chosen-choices{
        height: 100%;
        min-height: 100%;
        overflow: auto;
        display: block;
      }
      height: 100%;
      overflow: hidden;
    }
    &.chosen-with-drop{
      .chosen-single{
        //border: 1px solid #404644;
        box-shadow: none;
        background-image: none;
      }
    }
    .chosen-results{
      li{
        &.group-result{
          font-weight: inherit;
        }
      }
      .active-result{
        &:hover{
          &.result-selected{
            color: #fefefe;
            background: #6d891b;
          }
          color: #6d891b;
        }
        &.highlighted{
          color: #6d891b;
        }
      }
      display: block;
      width: 100%;
      height: auto;
      max-height: none;
    }
    .chosen-single{
      height: 100%;
      line-height: 30px;
      border-radius: 0;
      background: #404644;
      color: #fefefe;
      //border: 1px solid #404644;
      box-shadow: none;
      background-image: none;
    }

    .chosen-scroll{
      display: block;
      width: 100%;
      height: 175px;
      overflow: auto;
    }
    .chosen-scroll-choices{
      display: block;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .chosen-drop {
      .chosen-results {
        .disabled-result{
          cursor: default;
          opacity: .3;
        }
        .active-result{
          &:hover{
            &.result-selected{
              color: #fefefe;
              background: #6d891b;
            }
            color: #6d891b;
          }
          &.highlighted{
            color: #6d891b;
          }
          &.result-selected {
            color: #fefefe;
            background: #6d891b;
          }
          background: #404644;
        }
        display: block;
        width: 100%;
        height: auto;
        max-height: none;
        color: #fefefe;
        padding: 0;
      }
      background: #404644;
      border: 1px solid #000;
      left: auto !important;
     // position: sticky !important

    }
    .chosen-choices {
      li {
        &.search-field {
          input {
            &[type=text] {
              border-color: #404644;
              background: #404644;
              width: 100%;
            }
          }
        }
        &.search-choice {
          .search-choice-close {
            &:after {
              content: "\78";
              font-family: $iconFontFamily;
              font-size: 14px;
              margin: 0;
              text-decoration: none;
            }
            position: absolute;
            top: 6px;
            right: 10px;
            display: block;
            width: 12px;
            height: 12px;
            background: none;
            font-size: 16px;
            color: #fefefe;
            text-decoration: none;
          }
          background-image: none;
          border: none;
          background: #282e2c;
          color: #fefefe;
          box-shadow: none;
          border-radius: 0;
          padding: 5px 25px 5px 10px;
        }
      }
      border-color: #404644;
      background-image: none;
      background: #404644;
      color: #fefefe;
    }
    width: 100%;
    height: 30px;
    max-width: 100%;
    min-width: 100%;
    font-size: 12px;
  }
  .elaine-campaign-conf {
    .elaine-campaign-conf-header {
      .table{
        margin-bottom: 0;
      }
      display: block;
      width: 580px;
      height: 30px;
      border: 1px solid #000;
    }
    .elaine-campaign-conf-container {
      .elaine-campaign-conf-inner {
        .step-height {
          line-height: 30px;
        }
        .elaine-campaign-conf-table-field {
          .elaine-campaign-conf-table-field-left {
            .title {
              display: block;
              width: 100%;
            }
            .desc {
              display: block;
              width: 100%;
            }
            height: auto;
            width: calc(100% - 130px);
            vertical-align: top;
            display: inline-block;
          }
          .elaine-campaign-conf-table-field-right {
            width: 125px;
            height: 30px;
            vertical-align: top;
            display: inline-block;
          }
          display: block;
          width: 100%;
          height: auto;
        }
        display: block;
        width: 100%;
        height: auto;
      }
      display: block;
      width: 580px;
      height: 450px;
      border: 1px solid #000;
    }
    td, th{
      &.step-id{
        width: 40px;
      }
      &.step-type{
        width: 100px;
      }
      &.step-desc{
        width: calc( 100% - 140px );
      }
    }
    width: 100%;
  }
  .elaine-import-mapping{
    .elaine-import-mapping-preview{
      .dataTables_wrapper{
        width: auto;
        height: auto;
        display: block;
      }
      width: 100%;
      height: 100%;
      display: block;
    }
    .dataTables_scrollHead{
      height: 25px;
    }
    &.has-csv-header{
      .elaine-import-mapping-header {
        .elaine-import-mapping-header-field {
          &.select {
            width: 200px;
          }
          &.data-type {
            width: 153px;
          }
          &.csv-info {
            padding: 0 5px 0 5px;
            width: calc( 100% - 363px );
          }

        }
      }
      .elaine-import-mapping-body {
        .elaine-import-mapping-body-inner {
          .elaine-import-mapping-body-row {
            .elaine-import-mapping-body-row-field {
              &.select {
                width: 203px;
              }
              &.data-type {
                width: 154px;
              }
              &.csv-info {
                width: calc(100% - 357px);
              }
            }
          }
        }
      }
      height: 150px;
    }
    .elaine-import-mapping-header{
      .elaine-import-mapping-header-field{
        &:not( :last-of-type ){
          border-right: 1px solid #000;
        }
        &.select{
          width: 202px;
        }
        &.csv-info{
          padding: 0 5px 0 5px;
          width: calc( 100% - 211px );
        }
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }
      display: block;
      width: 100%;
      height: 30px;
      background: #282e2c;
      border-bottom: 2px solid #000;
    }
    .elaine-import-mapping-body{
      .elaine-import-mapping-body-inner {
        .elaine-import-mapping-body-row {
          .elaine-import-mapping-body-row-field {
            .styled-select {
              &:after{
                top: 0;
              }
              width: 100%;
              border-left: 1px solid #000;
            }
            &.select {
              width: 200px;
            }
            &.csv-info {
              padding: 0 5px 0 5px;
              width: calc(100% - 205px);
              line-height: 30px;
            }
            display: inline-block;
            height: 30px;
            //line-height: 30px;
            vertical-align: top;
            float: left;
          }
          &:nth-child(odd) {
            .elaine-import-mapping-body-row-field {
              &.select, &.data-type {
                .form-group {
                  .styled-select {
                    &:not([disabled]):hover {
                      &:before {
                        background: #6d891b;
                      }
                    }
                    &:before {
                      background: #282e2c;
                    }
                    .chosen-container{
                      .chosen-single{
                        background: #282e2c;
                        border: none;
                        border-color: #282e2c;
                      }
                      vertical-align: top;
                    }
                    select {
                      border: none;
                      background: #282e2c;
                    }
                  }
                  margin-bottom: 0;
                }
                margin-bottom: 0;
              }
              background: #282e2c;
            }
            background: #282e2c;
          }
          width: 100%;
          height: 30px;
          display: block;
        }
        width: 100%;
        height: auto;
        display: block;
      }
      display: block;
      width: 100%;
      height: calc( 100% - 30px );
    }
    .listview_table thead {
      border-bottom: 2px solid #000;
    }
    display: block;
    width: 100%;
    height: 150px;
    color: #fefefe;
    background: #404644;
    border: 1px solid #000;
    overflow: hidden;
  }

  .elaine-optinquery{
    .listview_table tbody tr td:first-of-type{
      white-space: nowrap;
    }
    height: 350px;
    width: 780px;
    display: block;
    background: #404644;
    border: 1px solid #000;
  }

  .form-group-directive-container{
    .form-group-directive-container-inner{
      .rendered-directive-no-item{
        display: block;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
      .rendered-directive{
        &:nth-child( odd ){
          background: #1f2523;
        }
        .rendered-directive-inner{
          label{
            width: calc( 23% + 10px );
          }
          input:not( [type="radio"] ),.date-element,.inline-radio,.checkbox-container{
            width: calc( 76% - 10px )
          }
          display: inline-block;
          width: calc( 100% - 40px );
          height: auto;
          vertical-align: top;
        }
        .rendered-directive-button{
          .e_icn_btn{
            &:hover{
              .e_icn{
                color: #fefefe;
              }
              background: transparent;
            }
            .e_icn{
              color: #c31a1d;
            }
            background: transparent;
          }
          display: inline-block;
          width: 40px;
          height: auto;
          vertical-align: top;
        }
        display: block;
        width: 100%;
        height: 40px;
        padding: 4px 10px 0 10px;
      }
      width: 100%;
      height: auto;
      display: block;
    }
    overflow: hidden;
    margin: 0;
  }
  .framed{
    .form-group-directive-container{
      border: 1px solid #000;
    }
  }


  &.navigation {
    .form-group {
      &.form-group-input {
        &.amount {
          input {
            width: 100%;
            padding-left: 5px;
          }
          width: 60px;
        }
      }
      &.form-group-select {
        &.unit{
          width: 100px;
        }
        .styled-select {
          width: 100%;
        }
        width: 200px;
      }
      &.form-group-input-date {
        label {
          width: 30px;
        }
        .date-element {
          width: calc(100% - 35px);
        }
        width: 175px;
      }
      &.form-group-input-date-period {
        label {
          display: none;
        }
        .date-element {
          label {
            display: inline-block;
            width: 30px;
            height: 20px;
            line-height: 20px;
          }
          width: calc(100% - 35px);
        }
        width: 400px;
      }
      .switch-item {
        height: 15px;
        margin-top: 8px;
      }
      margin: 0 20px 0 0;
      display: inline-block;
      vertical-align: top;
    }
    .e_icn_btn {
      &:hover {
        background: #6d891b;
        border-color: #6d891b;
      }
      background: #282e2c;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    color: #fefefe;
    //border-bottom: 1px solid #000;
  }
}

.elaine-complex-index-wrapper {
  .elaine-complex-index-container {
    .elaine-complex-index {
      .elaine-complex-index-item {
        .elaine-complex-index-item-left {
          display: block;
          width: 45px;
          float: left;
          padding: 5px 0 0 5px;
        }
        .elaine-complex-index-item-right {
          display: block;
          width: calc(100% - 45px);
          float: left;
        }
        display: block;
        width: 100%;
        height: 30px;
        padding: 0;
        list-style: none;
        margin-bottom: 5px
      }
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    display: block;
    width: 100%;
    height: 250px;
  }
}

.elaine-complex-list-wrapper {
  .elaine-complex-list-container {
    .elaine-complex-list {
      .elaine-complex-list-item {
        .elaine-complex-list-item-left {
          span {
            display: block;
            width: 30px;
            height: 30px;
          }
          display: block;
          width: 30px;
          float: left;
        }
        .elaine-complex-list-item-middle {
          display: block;
          width: calc(100% - 120px);
          float: left;
        }
        .elaine-complex-list-item-right {
          button {
            display: block;
            width: 30px;
            height: 30px;
            padding: 0 7px;
            float: left;
          }
          display: block;
          width: 90px;
          float: left;
        }
        display: block;
        width: 100%;
        height: 30px;
        padding: 0;
        list-style: none;
        margin-bottom: 5px;
      }
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    display: block;
    width: 100%;
    height: 250px;
  }
  .elaine-complex-list-buttons{
    button{
      float: right;
    }
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
  }
}

.switch-item {
  &.disabled {
    opacity: .50;
    cursor: default;
  }
  &.wide {
    &.checked {
      small {
        left: 52px;
      }
    }
    width: 80px;
  }
  &.checked {
    small {
      left: 15px;
    }
    .off {
      display: none;
    }
    .on {
      display: block;
    }
    background: #6d891b;
    border-color: #6d891b;
  }
  small {
    background: #fff;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 1px;
    left: 2px;
    transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
  }
  .switch-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
  }
  .off {
    display: block;
    position: absolute;
    right: 10%;
    top: 25%;
    z-index: 0;
    color: #A9A9A9;
  }
  .on {
    display: none;
    z-index: 0;
    color: #fff;
    position: absolute;
    top: 25%;
    left: 9%;
  }
  background: #404644;
  border: 1px solid #dfdfdf;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  overflow: visible;
  width: 30px;
  height: 15px;
  padding: 0px;
  margin: 0px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  top: -1px;
  outline: none;
}

.sp-container{
  .sp-picker-container{
    .sp-input{
      &:focus{
        background: #797d7c;
        border: none;
      }
      border-radius: 0;
      height: 30px;
      padding: 0 10px;
      background: #404644;
      border: none;
      color: #fefefe;
      font-size: 12px;
      vertical-align: top;
      outline: none !important;
      box-shadow: none;
      -ms-user-select: text;
      -moz-user-select: text;
      -khtml-user-select: text;
      -webkit-user-select: text;
      user-select: text;
    }
    .sp-button-container{
      button{
        &:hover {
          background: #6d891b;
          color: #fefefe;
        }
      }
      a{
        &:hover {
          background: #731513;
          color: #fefefe;
        }
      }
      button, a{
        background: #404644;
        border: none;
        color: #fefefe;
        border-radius: 0;
        width: auto;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        outline: none;
        vertical-align: top;
        text-decoration: none;
        display: inline-block;
        margin-left: 5px;
        text-transform: uppercase;
        font-family: $fontFamilyLight;
        font-size: 14px;
        text-shadow: none;
      }
    }
    border-color: #000;
  }
  .sp-palette-container{
    border-color: #000;
  }
  background: #282e2c;
  border-color: #282e2c;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

.open.upwards > .dropdown-menu{
  top: -270px;
}