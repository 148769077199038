$editor-base__A0: themed('base--bg-color') !default;

editor-base{
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.me__stage{
  &__container{
    overflow: hidden;
  }
  &__loading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $editor-base__A0;
  }
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
