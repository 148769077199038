.xaf-tabbable {
  .xaf-tabs-control {
    .xaf-tabs-control-item {
      &.active {
        a {
          background: #a8c33d;
          color: #fefefe;
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 5px 0 5px;
        text-decoration: none;
      }
      display: inline-block;
      height: 100%;
      line-height: 30px;
      text-transform: uppercase;
      overflow: hidden;
    }
    list-style: none;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .xaf-tab-content {
    .xaf-tab-pane {
      &.active {
        display: block;
      }
      display: none;
    }
  }
}
