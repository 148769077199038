@font-face {
    font-family: 'artegic-icons';
    font-weight: normal;
    font-style: normal;
    src: url('/plugins/xaf_framework/public/fonts/artegic-icons.eot');
    src: url('/plugins/xaf_framework/public/fonts/artegic-icons.eot?#iefix') format('embedded-opentype'),
         url('/plugins/xaf_framework/public/fonts/artegic-icons.woff') format('woff'),
         url('/plugins/xaf_framework/public/fonts/artegic-icons.ttf') format('truetype'),
         url('/plugins/xaf_framework/public/fonts/artegic-icons.svg#artegic-icons') format('svg');
}
