// Standard Extra Colors

$elaine-blue-dark--M2: #014e64;
$elaine-blue-dark--M1: #017596;
$elaine-blue-basic: #009cc9;
$elaine-blue-light--m1: #46d3fd;
$elaine-blue-light--m2: #82e1fd;
$elaine-blue-light--m3: #c0effd;

$elaine-purple-dark--M2: #351f45;
$elaine-purple-dark--M1: #502d68;
$elaine-purple-basic: #6a3c8a;
$elaine-purple-light--m1: #a77dc5;
$elaine-purple-light--m2: #c4a7d7;
$elaine-purple-light--m3: #e0d3ea;

$elaine-red-dark--M2: #551511;
$elaine-red-dark--M1: #7f201a;
$elaine-red-basic: #aa2921;
$elaine-red-light--m1: #df6c65;
$elaine-red-light--m2: #e89b96;
$elaine-red-light--m3: #f2cdca;

$elaine-ocre-dark--M2: #723f01;
$elaine-ocre-dark--M1: #aa5f01;
$elaine-ocre-basic: #e27f01;
$elaine-ocre-light--m1: #fcb255;
$elaine-ocre-light--m2: #fdcc8d;
$elaine-ocre-light--m3: #fce3c5;

$elaine-green-dark--M2: #54621f;
$elaine-green-dark--M1: #7e922d;
$elaine-green-basic: #a8c33d;
$elaine-green-light--m1: #cad98a;
$elaine-green-light--m2: #dae5b0;
$elaine-green-light--m3: #ecf1d6;
