// main: ../index.scss
.icon {
  display: inline-block;
  width: 38px;
  height: 38px;
  font-size: 18px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  line-height: 44px;
  @include transition;
}
