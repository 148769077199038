$stage-top__A0: themed('frame--bg-color') !default;
$stage-top__B0: themed('frame--bg-color') !default;
$stage-top__C0: themed('base--bg-color') !default;

.me__stage-top{
  *{
    user-select: none;
  }
  .me__stage-top__block-left{
    display: block;
    width: 206px;
    border-right: 6px solid $stage-top__A0;
    height: 100%;
    float: left;
  }
  .me__stage-top__block-right{
    display: block;
    width: calc( 100% - 206px );
    height: 100%;
    float: left;
  }
  .me__stage-top__right-row{

    &--top{
      border-bottom: 6px solid $stage-top__B0;
    }

    &--bottom{
      & >*{
        margin: 0 5px;

        &:first-child{
          margin-left: 0;
        }
      }
    }

    //Push child-item to right
    .push-right {
      margin-left: auto;
    }

    display: flex;
    width: 100%;
    height: auto;
    min-height:30px;
    overflow: visible;
    justify-content: flex-start;
  }

  .me__select-chosen-container{
    width:180px;
  }

  display: block;
  width: 100%;
  //@advice: stage-top height is in template "main.html" defined too for the ui-lyout
  height: 66px;
  background: $stage-top__C0;
}
