$view-html-edit__A0: themed('frame--bg-color') !default;

.me__view-html-edit{

  &__container-left{
    height:100%;
    display:flex;
    flex-direction:column;
    overflow:hidden;
  }

  &__container-center{
    width: calc(100% - 6px);
  }

  &__folder-list{
    border-bottom: 6px solid $view-html-edit__A0;
    display:block;
    height:0;
    flex-grow:1;
  }

  &__drop-zone{
    max-height:60px;
    min-height:30px;
  }

  &__scroll{
    &--article{
      height: 100%;
    }
    display: block;
    width: 100%;
    height: calc(100% - 60px);
  }

}
