$me__preview-release__A1: 200px;
.me__preview-release{

  &__feedback-given{
  text-align: center;
    height: $me__preview-release__A1;
    line-height: $me__preview-release__A1;
    font-size: 17px;
  }

}
