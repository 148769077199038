.jspScrollable {
  outline: none;
}
.jspVerticalBar{
  width: 7px;
}
.jspHorizontalBar{
  height: 7px;
}
.jspCorner{
  background: $eColorDark !important;
}
.jspTrack{
  .jspDrag{
    background: #585e5e;
  }
  background: $eColorDark;
}
