
$palettes: (
  'dark': (
    'artegic-corporate-green--dark': #6D891B,
    'artegic-corporate-green': #a8c33d,

    'artegic-green': #6b823c,
    'artegic-red': #aa2921,
    'artegic-orange': #c28325,
    'artegic-blue': #5086a4,

    'base-black': #000000,

    'base-grey-09': #1f2523,
    'base-grey-08': #282e2c,
    'base-grey-07': #404644,
    'base-grey-06': #474949,
    'base-grey-05': #4a4e4d,
    'base-grey-04': #585e5e,
    'base-grey-03': #6f6f6f,
    'base-grey-02': #757575,
    'base-grey-01': #797d7c,

    'base-white': #fefefe

  ),
  'light': (
    'artegic-corporate-green--dark': #3f95ca,
    'artegic-corporate-green': #41ade1,

    'artegic-green': #6b823c,
    'artegic-red': #aa2921,
    'artegic-orange': #c28325,
    'artegic-blue': #5086a4,

    'base-black': #000000,
          'base-grey-10': #acb0af,
    'base-grey-09': #868a89,
    'base-grey-08': #868a89,
    'base-grey-07': #868a89,
    'base-grey-06': #868a89,
    'base-grey-05': #818584,
    'base-grey-04': #909493,
    'base-grey-03': #9b9f9e,
    'base-grey-02': #b5b9b8,
    'base-grey-01': #dadedd,
    'base-grey-00': #e8ecea,

    'base-white': #fefefe
  )
)


