$view-source-edit__A0: themed('frame--bg-color') !default;
$view-source-edit__B0: themed('element-action--bg-color') !default;


.me__source-edit{
  &__ace{
    .ace_scrollbar{
      width: 0 !important;
    }
    width: calc( 100% - 8px );
  }
  &__article-ace{
    .ace_scrollbar{
      width: 0 !important;
    }
    width: 100%;
  }

  &__source{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__scroll{
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
    height: 100%;
    background: $view-source-edit__A0;
  }
  &__scroll-inner{
    position: absolute;
    top: 0;
    display: block;
    width: 8px;
    height: 50px;
    background: $view-source-edit__B0;
  }

  &__preview{
    overflow: hidden;
    height: 100%;
  }
  display: block;
  width: 100%;
  height: 100%;
}
