.me__preview-condition {
  &__skip-through {
    flex-grow: 1;
  }

  &__max-combinations {
    line-height: 30px;
    margin-left: 10px;
    font-size: 12px;
    color: #fefefe;
  }
  .approval-page {
    .chosen-container {
      .chosen-drop,.chosen-search-input,.active-result {
        background: $white !important;
        color: $black !important;
      }
      .active-result{
        &.result-selected{
          color: #fefefe !important;
          background: #a8c33d !important;
        }
        &:hover{
          color: #a8c33d !important;
          background: #fefefe !important;
          &.result-selected{
            color: #fefefe !important;
            background: #a8c33d !important;
          }
        }
      }
      .chosen-single{
        background: $gray !important;
        color: $black !important;
      }
      &::after{
        color: $black !important;
      }
      &::before{
        border-left: 1px solid $gray !important;
      }
    }

    input,button {
      background: $gray !important;
      color: $black !important;
    }
    .ui-spinner-button{
      border-left: 0px !important;
    }
    md-input-container {
      a::after {
        color: $black !important;
      }
      .input-group-addon{
        background: $gray !important;
        color: $black !important;
        border-left: 0px !important;
        &:hover {
          background: #6d891b !important;
        }
      }
    }
  }

}
