//@todo: better theme color name
$preview__A0: themed('frame--text-color')!default;

.me__preview {
  &__container{
    position: relative;
    display: block;
    width: 100%;
    height: calc(100% - 30px);
    color: #000;
    border-right: 0.8px solid #444444;
  }
  &__inner {
    display: block;
    margin: 0 auto;
    -moz-transition: .75s linear width;
    -webkit-transition: .75s linear width;
    transition: .75s linear width;
  }
  &__show{
    &.article-only{
      margin-top: 30px;
    }
    display: block;
    position: relative;
    width: auto;
    max-width: 800px;
    margin: 0 auto;
    background: $preview__A0;
  }
  &__view{
    subjects, textcontent{
      display: none;
    }
    overflow: hidden;
    padding: 8px;
    font-family: consolas, monaco, monospace;
  }
  display: block;
  width: 100%;
  height: 100%;
  overflow: visible;
  position: relative;

  .me__preview__outdated-overlay{
    display:flex;
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,.5);
    align-items: center;
    top:0;
    left:0;
    cursor:pointer;
    z-index: 1;
  }

  .me__preview__outdated-advice{
    color:white;
    font-size: 20px;
    width:100%;
    text-align:center;
  }

  .me__preview-single-subject{
    border-bottom: 1px solid #000;
    color: #fefefe;

    &__label{
      height:30px;
      line-height:30px;
      padding: 0 10px;
    }

    &__text{
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      width: 100%;
      background-color: #404644;
    }
  }

}
