.me__complex-textarea{

  margin: 5px;
  transition: all .3s;

  .me__complex-textarea__scroll{
    display: block;
    width: 100%;
    height: 500px;
  }

  .me__complex-textarea__spinner-box {
    position: relative;
    top: 0;
    width: 100%;
    height: 40px;
  }
}
