// main: ../index.scss

@mixin xaf-grid-board {

    padding-left: 33px;
    padding-right: 33px;
}

// Breakpoints
$w-1500: 1500px;
$w-1400: 1400px;
$w-1300: 1300px;
$w-1200: 1200px;
$w-1024: 1024px;
$w-960: 960px;
$w-768: 768px;
$w-640: 640px;
$w-480: 480px;
$w-320: 320px;


// Animations
@mixin drop {
    animation-name: dropElement;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.6s;
}
@keyframes dropElement {
    0% {
        transform: translateY(-50%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


@mixin transition {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin transition-fast {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

// Shadows
@mixin box-shadow {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow:    0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    box-shadow:         0px 0px 10px 2px rgba(0, 0, 0, 0.3);
}


@mixin clear {
    &::before,
    &::after {
        content: " ";
        display: table;
    }
    &::after {
	   clear: both;
    }
}
