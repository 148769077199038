$toaster-success-color: themed( 'success--color') !default;
$toaster-error-color: themed( 'error--color') !default;
$toaster-warning-color: themed( 'warning--color') !default;
$toaster-info-color: themed( 'info--color') !default;
$toaster_base: themed( 'base--bg-color') !default;
$toaster_text: themed( 'base--text-color') !default;

#toaster-container {
  margin: 20px auto;
  width: 500px;
  height:100px;
}

md-toast{
  .md-toast-content {
    .md-toast-content-text{
      padding:10px;
      overflow: hidden;
      color: $toaster_text;
    }
    .md-toast-icon-wrapper{

      &.success{
        .md-toast-icon{
          color: $toaster-success-color;
        }
      }
      &.error{
        .md-toast-icon{
          color:$toaster-error-color;
        }
      }
      &.warning{
        .md-toast-icon{
          color:$toaster-warning-color;
        }
      }
      &.info{
        .md-toast-icon{
          color:$toaster-info-color;
        }
      }
      .md-toast-icon{
        font-size:42px;
      }
      margin-left: auto;
      height: 45px;
      width: 45px;
    }
    display: flex;
    flex-direction: row;
    align-items:center;
    width: 100%;
    height:100%;
    background-color: $toaster_base;
    color: $toaster_text;
  }
  position:absolute;
  top:0;
  left: calc( 50% );
  width: 100%;
  z-index:1500;
  &.no-full-overLay{
  max-height: 30%;
  }
}
