.xaf-aside {
  @media screen and (max-width: $w-1500) {
    width: 20%;
  }
  @media screen and (max-width: $w-1200) {
    width: 25%;
  }
  @media screen and (max-width: $w-1024) {
    width: 30%;
  }
  @media screen and (max-width: $w-768) {
    width: 35%;
  }
  @media screen and (max-width: $w-640) {
    &.active {
      left: 0;
      &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 100%;
        z-index: 109;
        background: #000;
        opacity: .5;
        left: 0;
        top: 0;
        position: fixed;
        @include transition;
      }
    }
    width: 50%;
    left: -50%;
    z-index: 110;
    height: 100%;
  }

  @media screen and (max-width: $w-480) {
    width: 70%;
    left: -70%;
    z-index: 110;
    height: 100%;
  }

  .xaf-aside-main-menu {
    .xaf-aside-menu-list{
      margin: 0;
      padding: 0;
      vertical-align: baseline;
    }
    .xaf-aside-menu-secondary {
      ul {
        li {
          a {
            height: auto;
            min-height: 38px;
          }
          &.active{
            a{
              color: #b5b5b5;
              &:hover{
                background-color: $white;
                cursor:default;
              }
            }
          }
        }
      }
      margin-top: 30px;
    }
    ul {
      li {
        list-style: none;
        a {
          padding: 10px 0 10px 48px;
          text-decoration: none;
          font-size: 1em;
          position: relative;
          display: block;
          height: 38px;
          line-height: 18px;
          @include transition;
          background-color: $white;
          color: $black;
          .icon {
            top: 0;
            left: 0;
            @include transition;
            background-color: $white;
          }
          &:hover {
            background-color: $grey;
            color: $black;
            .icon {
              background-color: $grey-dark;
            }
          }
          &.current-area {
            background-color: $blue;
            color: $white;
            .icon {
              background-color: $blue-dark;
              color: $black;
            }
          }
        }
      }
    }
    @media screen and (max-width: $w-640) {
      padding-top: 68px;
      z-index: 110;
      .primary {
        height: auto;
      }
    }
    overflow: hidden;
    position: relative;
    top: 0;
    padding: 30px 0 0 0;
    width: 100%;
    left: 0;
    height: 100%;
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    @include transition;
    background: #fff;
    z-index: 95;

  }

  .xaf-aside-menu-link {

    .burger {
      position: absolute;
      left: 9px;
      top: 18px;
      height: 3px;
      background: $black;
      width: 20px;
      border-radius: 3px;
      &::before, &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $black;
        content: "";
        -webkit-transition-duration: 0.2s, 0.2s;
        -moz-transition-duration: 0.2s, 0.2s;
        -ms-transition-duration: 0.2s, 0.2s;
        transition-duration: 0.2s, 0.2s;
        -webkit-transition-delay: 0.2s, 0s;
        -moz-transition-delay: 0.2s, 0s;
        -ms-transition-delay: 0.2s, 0s;
        -o-transition-delay: 0.2s, 0s;
        transition-delay: 0.2s, 0s;
        border-radius: 3px;
      }
      &::before {
        top: -6px;
        -webkit-transition-property: top, -webkit-transform;
        -moz-transition-property: top, -moz-transform;
        -ms-transition-property: top, -ms-transform;
        -o-transition-property: top, -0-transform;
        transition-property: top, transform;
      }
      &::after {
        bottom: -6px;
        -webkit-transition-property: bottom, -webkit-transform;
        -moz-transition-property: bottom, -moz-transform;
        -ms-transition-property: bottom, -ms-transform;
        -o-transition-property: bottom, -0-transform;
        transition-property: bottom, transform;
      }
      @media screen and (max-width: $w-640) {
        background: $white;
        &::before, &::after {
          background: $white;
        }
      }
    }

    &.active {
      &::before, &::after {
        background: $black !important;
      }
      .burger {
        background: none;
        &::before, &::after {
          -webkit-transition-delay: 0s, 0.3s;
          -moz-transition-delay: 0s, 0.3s;
          -ms-transition-delay: 0s, 0.3s;
          -o-transition-delay: 0s, 0.3s;
          transition-delay: 0s, 0.3s;
        }
        &::before {
          top: 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &::after {
          bottom: 0;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        @media screen and (max-width: $w-640) {
          &::before, &::after {
            background: $black !important;
          }
        }
      }
    }

    //&:not(.active ) {
    //  .nav-title {
    //    display: none;
    //  }
    //}

    .nav-title {
      position: relative;
      font-weight: 300;
    }
    @media screen and (max-width: $w-640) {
      position: fixed;
      color: $white;
      left: 0;
      height: 38px;
      .nav-title {
        display: none;
        color: $black;
      }


      &.active {
        .nav-title {
          display: block;
        }
      }
    }

    @include transition;
    position: relative;
    display: block;
    color: $black;
    z-index: 999999;
    width: 100%;
    padding: 10px 0 10px 48px;
    text-decoration: none;
    overflow: hidden;

  }

  .xaf-aside-link {
    .artegic-link:visited {
      color: #444;
    }

    .artegic-link:link {
      color: #444;
      text-decoration: none;
    }

    .artegic-link {
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      min-width: 85px;
      height: 30px;
      font-size: 11px;
    }

    position:absolute;
    bottom:0px;
    left:38px;
    z-index:100;

  }
  position: fixed;
  width: 300px;
  height:100%;
  z-index: 99;
}
