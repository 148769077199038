$html-edit__A0: themed('frame--text-color') !default;
$html-edit__A1: themed('frame--bg-color') !default;
$html-edit__A2: #000;

.me__html-show {
  .me__html-show-inner {
    .me__html-show-inner-show{
      display: block;
      position: relative;
      width: auto;
      max-width: 800px;
      margin: 50px auto;
      background: $html-edit__A0;
      outline: 1px dashed $html-edit__A1;
    }
    subjects, textcontent{
      display: none;
    }
    display: block;
    margin: 0 auto;
    -moz-transition: .75s linear width;
    -webkit-transition: .75s linear width;
    transition: .75s linear width;
  }
  display: block;
  width: 80%;
  height: calc( 100% - 30px );
  color:$html-edit__A2;
  margin: 0 auto;

}

