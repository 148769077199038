// main: ../index.scss

.xaf-action-button {

  .icon {
    top: 0;
    right: 0;

  }
  &.right {
    //float: right;
  }
  &.xaf-button-pos-left {
    float: left;
    top: 10px;
  }
  &.xaf-button-margin, &:not(:last-child){
    //margin-right:5px;
  }
  &.interactive {
    span {
      background-color: darken($button-interactive-bg, 10%) !important;
      color: $button-interactive-color !important;
    }
    &:hover {
      background-color: darken($button-interactive-bg, 10%) !important;
      span {
        background-color: darken($button-interactive-bg, 20%) !important;
      }
    }

    background-color: $button-interactive-bg !important;
    color: $button-interactive-color !important;
  }
  &.minimal{
    & .icon{
      display:none;
    }
    &:hover{
      text-decoration: none;
    }
    padding: 7px 10px;
    background-color: $white !important;
    min-width: 140px;
    width:auto;
  }
  &.special {
    background-color: $second-color!important;
    color: $white!important;
    width: 100% !important;
    span {
      background-color:  darken( $second-color, 10% )!important;
      color: $white!important;
    }
    &:hover {
      background-color: darken( $second-color, 10% )!important;
      color:$white!important;
      span {
        background-color: darken( $second-color, 20% )!important;
      }
    }
  }
  &.submit {
    span {
      background-color: darken($button-submit-bg, 10%);
      color: $button-submit-color;
    }
    &:hover {
      background-color: darken($button-submit-bg, 10%);
      span {
        background-color: darken($button-submit-bg, 20%);
      }
    }

    background-color: $button-submit-bg;
    color: $button-submit-color;
  }
  &.error {
    span {
      background-color: darken($button-error-bg, 10%)!important;
      color: $button-error-color;
    }
    &:hover {
      background-color: darken($button-error-bg, 10%)!important;
      span {
        background-color: darken($button-error-bg, 20%)!important;
      }
    }
    background-color: $button-error-bg!important;
    color: $button-error-color!important;
  }
  &.active {
    span {
      background-color: darken($button-active-bg, 10%) !important;
      color: $button-active-color !important;
    }
    &:hover {
      background-color: darken($button-active-bg, 10%)!important;
      span {
        background-color: darken($button-active-bg, 20%)!important;
      }
    }
    background-color: $button-active-bg !important;
    color: $button-active-color !important;
  }
  &.regular {
    span {
      background-color: darken($button-regular-bg, 10%);
      color: $button-regular-color;
    }
    &:hover {
      background-color: darken($button-regular-bg, 10%);
      span {
        background-color: darken($button-regular-bg, 20%);
      }
    }
    background-color: $button-regular-bg;
    color: $button-regular-color;
  }
  &.negative {
    span {
      background-color: darken($button-negative-bg, 10%) !important;
      color: $button-negative-bg-span-color !important;
    }
    background-color: $button-negative-bg !important;
    color: $button-negative-color !important;
    pointer-events: none;
    cursor: not-allowed;
  }
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 1em !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;

  min-width: 188px !important;
  display: inline-block !important;
  padding: 10px 48px 10px 10px !important;
  position: relative !important;
  margin-top: -18px !important;
  cursor: pointer !important;
  text-decoration: underline !important;
  line-height: 18px !important;
  height: 38px !important;
  font-weight: 400 !important;
  @include transition;
}

.xaf-btn{
  .icon {
    top: 0;
    right: 0;
  }
  &.small{
    width: 38px;
  }
  &.regular {
    span {
      background-color: darken($button-regular-bg, 10%);
      color: $button-regular-color;
    }
    &:hover {
      background-color: darken($button-regular-bg, 10%);
      span {
        background-color: darken($button-regular-bg, 20%);
      }
    }
    background-color: $button-regular-bg;
    color: $button-regular-color;
  }
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;

  width: auto;
  display: inline-block;
  padding: 10px;
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  line-height: 18px;
  height: 37px;
  font-weight: 400;
  @include transition;
}

.xaf-icon-button {
  &[disabled] {
    cursor: not-allowed;
  }
  &:hover:not( [disabled] ) {
    &.cancel {

    }
  }
}
