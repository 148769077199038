// main: ../index.scss
//Transitions-calc not in ie

.xaf-app-container {

  width: 100%;
  float: left;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 38px;
  background-color: $white;
  min-height: 100%;

  @include box-shadow;
  @include transition;

  .xaf-content-container{
    padding-bottom: 60px;
   }


  &.xaf-open-menu {
    width: 85%;
    left: 15%;

  }

  //Fix to transition-calc in ie
  &:not( .xaf-open-menu ){
     .xaf-content-container{
      padding-right: 38px;
    }
  }

  @media screen and (max-width: $w-1500) {
    &.xaf-open-menu {
      width: 80%;
      left: 20%;
    }
  }
  @media screen and (max-width: $w-1200) {
    &.xaf-open-menu {
      width: 75%;
      left: 25%;
    }
  }

  @media screen and (max-width: $w-1024) {
    &.xaf-open-menu {
      width: 70%;
      left: 30%;
    }
  }

  @media screen and (max-width: $w-768) {
    &.xaf-open-menu {
      width: 65%;
      left: 35%;
    }
  }

  @media screen and (max-width: $w-640) {
    width: 100%;
    left: 0;
    &.xaf-open-menu {
      width: 100%;
      left: 0;
    }
  }

}

.xaf-open-menu{
  .xaf-mailing-editor-stage{
    .xaf-float-menu{
      &.sticky{
        width: 85%;
      }
    }
  }
}

.xaf-pagination-content {
  margin-bottom: 60px;
}

.xaf-pagination {
  & > a {
    margin-right: 20px;
    white-space: nowrap;
    display: inline-block;
    padding: 9px 0;
  }
  width: calc( 100% - 38px );
  transition: width 0.3s ease-in-out;
  position: fixed;
  bottom: 0;
  padding: 15px 30px;
  text-align: right;
  background: #fefefe;
  z-index: 100;
  border-top: 1px solid #ccc;
}

.xaf-open-menu{
  .xaf-pagination {
    width: 85%;
  }
}
