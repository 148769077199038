//@todo: font-family definition
$chosen-container__A0: $iconFontFamily;

$chosen-container__A1: themed('base--text-color') !default;
$chosen-container__A2: themed('element-action--bg-color') !default;
$chosen-container__A3: themed('element-action:hover--bg-color') !default;;
$chosen-container__B0: themed('form-element--bg-color') !default;
$chosen-container__B1: themed('form-element--text-color') !default;
$chosen-container__C0: themed('list-reverse:hover--text-color') !default;
$chosen-container__C1: themed('list-reverse:hover--bg-color') !default;
$chosen-container__D0: themed('list-reverse:selected--text-color') !default;
$chosen-container__E0: themed('list-reverse:selected--bg-color') !default;
$chosen-container__F0: themed('list-reverse:selected--text-color') !default;
$chosen-container__F1: themed('list-reverse:selected--bg-color') !default;
$chosen-container__G0: themed('list-reverse--bg-color')  !default;
$chosen-container__H0: themed('list-reverse--text-color') !default;
$chosen-container__I0: themed('list-reverse--bg-color')  !default;
$chosen-container__I1: themed('list-reverse--bg-color')  !default;
//$chosen-container__J0: themed('list-reverse--bg-color')  !default;
$chosen-container__J1: themed('form-element--bg-color') !default;
$chosen-container__K0: $iconFontFamily;
$chosen-container__L0: themed('list-reverse--text-color') !default;
$chosen-container__M0: themed('base--bg-color') !default; //border button drop-down
$chosen-container__M2: themed('base--bg-color') !default; //labels with x
$chosen-container__M1: themed('list-reverse--text-color') !default;
$chosen-container__N0: themed('list-reverse--bg-color')  !default;
$chosen-container__N1: themed('form-element--bg-color')  !default;
$chosen-container__N2: themed('list-reverse--text-color') !default;
$chosen-container__P0: themed('form-element--bg-color') !default;
$chosen-container__P1: themed('form-element--text-color') !default;

.chosen-container-single .chosen-search{
  padding:0;
}

.chosen-container-single .chosen-search input[type=text]{
  background:none;
  background-color:$chosen-container__P0;
  border:none;
  margin:0;
  color: $chosen-container__P1;
}


.chosen-container-active.chosen-with-drop .chosen-single{
  border: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-image: none !important;
  background-color:transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $chosen-container__B0;
  color: $chosen-container__B1;
}

.chosen-container-active.chosen-with-drop .chosen-single div{
  border-left: 1px solid #000;
}

#linkcategories_chosen .chosen-choices, #extraActionAddition_chosen .chosen-choices, #linktargets_chosen .chosen-choices{
  height: 100%;
}
#linkcategories_chosen.chosen-container, #extraActionAddition_chosen.chosen-container, #linktargets_chosen.chosen-container{
  &:before{
    height: 100%;
    background: #404644;
  }
}

.chosen-container{
  &.chosen-container-multi{
    .chosen-choices{
      height: 100px;
    }
  }
  &:not(.chosen-disabled ):hover{
    &:before{
      background-color: $chosen-container__A3;
    }
  }
  &:before{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 33px;
    height: 30px;
    border-left: 1px solid #000;
    pointer-events: none;
    z-index: 2;
  }

  &:after{
    font-family: $chosen-container__A0;
    content: '[';
    color: $chosen-container__A1;
    right: 10px;
    top: 8px;
    position: absolute;
    pointer-events: none;
    z-index: 2;
  }


  &:not(.chosen-disabled ):hover{
    .chosen-single {
      div {
        background-color: $chosen-container__A3;
      }
    }
  }

  .chosen-single{
    div{
      width:33px;
      border-left: 1px solid $chosen-container__M0;
      //&:hover{ //same as line 76
      //  background-color: $chosen-container__A3;
      //}
    }
    span{
      display: block;
      overflow: hidden;
      margin-right: 26px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc( 100% - 40px);
    }
    height: 30px;
    line-height: 30px;
    border-radius: 0;
    background: $chosen-container__B0;
    color: $chosen-container__B1;
    border: none;
    box-shadow: none;
    background-image: none;
    padding: 0 0 0 10px;
  }

  .chosen-scroll{
    display: block;
    width: 100%;
    height: 175px;
    overflow: auto;
  }
  .chosen-scroll-choices{
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .chosen-drop {
    .chosen-results {
      .disabled-result{
        cursor: default;
        opacity: .3;
      }
      .active-result{
        &:hover{
          &.result-selected{
            color: $chosen-container__C0;
            background: $chosen-container__C1;
          }
          color: $chosen-container__D0;
        }
        &.highlighted{
          color: $chosen-container__E0;
        }
        &.result-selected {
          color: $chosen-container__F0;
          background: $chosen-container__F1;
        }
        background: $chosen-container__G0;
      }
      display: block;
      width: 100%;
      height: auto;
      max-height: none;
      color: $chosen-container__H0;
      padding: 0;
    }
    background: $chosen-container__I0;
    border: 1px solid $chosen-container__I1;
  }
  .chosen-choices {
    li {
      &.search-field {
        input {
          &[type=text] {
            //border-color: $chosen-container__J0;
            background: $chosen-container__J1;
            width: 100%;
          }
        }
      }
      &.search-choice {
        .search-choice-close {
          &:after {
            content: "\78";
            font-family: $chosen-container__K0;
            font-size: 14px;
            margin: 0;
            text-decoration: none;
          }
          position: absolute;
          top: 6px;
          right: 10px;
          display: block;
          width: 12px;
          height: 12px;
          background: none;
          font-size: 16px;
          color: $chosen-container__L0;
          text-decoration: none;
        }
        background-image: none;
        border: none;
        background: $chosen-container__M2;
        color: $chosen-container__M1;
        box-shadow: none;
        border-radius: 0;
        padding: 5px 25px 5px 10px;
      }
    }
    border-color: $chosen-container__N0;
    background-image: none;
    background: $chosen-container__N1;
    color: $chosen-container__N2;
  }
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  font-size: 12px;
}




.me__select-tree{
  .chosen-container{
    .chosen-drop{
      .chosen-results {
        li{
          $itemDif : 10px;
          $minDist : 5px;
          transition: opacity 0.1s linear;

          //@todo: opacity without option-class???
          &.level_0 {
            opacity: 1;
            padding-left: $minDist;
          }
          &.level_1 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 1);

          }
          &.level_2 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 2);
          }
          &.level_3 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 3);
          }
          &.level_4 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 4);
          }
          &.level_5 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 5);
          }
          &.level_6 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 6);
          }
          &.level_7 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 7);
          }
          &.level_8 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 8);
          }
          &.level_9 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 9);
          }
          &.level_10 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 10);
          }
          &.level_11 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 11);
          }
          &.level_12 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 12);
          }
          &.level_13 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 13);
          }
          &.level_14 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 14);
          }
          &.level_15 {
            opacity: 1;
            padding-left: calc( #{$minDist} + #{$itemDif} * 15);
          }
          &.disabled{
            opacity: 0.3;
            pointer-events: none;
          }
          opacity: 0;
        }
      }
    }
  }
}
