@import "preview_text";
@import "preview_sms";

.panelLegend{
    width: inherit;
    border-bottom: none;
    background: transparent;
    color: #fefefe;
    font-size: 16px;
    margin-bottom: 10px;
}
.panel__message-panel {
  .morebtn{
    font-size: 12px;
    border: none;
    border-radius: 0;
    color: #fefefe;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    outline: none;
    vertical-align: top;
    text-align: center;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    text-transform: none;
  }
  &__navigation {
    display: block;
    width: 100%;
    height: 30px;
    > *, md-switch:last-of-type {
      margin-right: 15px;
    }
    &_layer-blocker {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      z-index: 1000;
    }
    md-switch {
      .md-label {
        height: 30px;
        line-height: 30px;
      }
    }
  }
  &__container {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__select {
    vertical-align: top;
    min-width: 100px;
  }
  &__switch {
    vertical-align: top;
    display: inline-block;
    width: auto;
  }
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: visible;

  &__no-preview {
    margin: 10px;
    color: #fefefe;
    font-size: 16px;
  }
}

.me__view {
  > .stretch {
    overflow: visible;
  }
  &.ng-enter, &.ng-leave {
    -webkit-transition: opacity ease-in-out .3s;
    -moz-transition: opacity ease-in-out .3s;
    -ms-transition: opacity ease-in-out .3s;
    -o-transition: opacity ease-in-out .3s;
    transition: opacity ease-in-out .3s;
  }
  &.ng-enter {
    opacity: 0;
    position: initial;
  }
  &.ng-enter-active {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.ng-leave {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.ng-leave-active {
    opacity: 0;
    position: initial;
  }
  display: block;
  width: 100%;
  height: 100%;
  background: $view-container__A0; //@todo: ready
}
.no-decoration {
  text-transform: none !important;
}
