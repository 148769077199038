.xaf-form {
  .error input {
    //color: #ff0000;
    outline-color: #ff0000;
    outline-width: 1px;
    outline-style: solid;
  }

  .mce-tinymce {
    &.error {

      outline-color: #ff0000;
      outline-width: 1px;
      outline-style: solid;
    }
  }

  &.delivery-time {

    .icon {
      &.calendar {
        top: 26px;
        right: 16px;
        @media screen and (max-width: $w-960) {
          right: 31px;
        }
      }
      &.time {
        top: 26px;
        right: 31px;
      }
    }
  }

  &.horizontal {
    .inline-row {
      position: relative;
      display: inline-block;
      height: auto;
      width: auto;
      float: left;
      margin-left: 30px;
      min-width: 200px;
      .xaf-form-group {
        padding: 0;
      }
    }

    .xaf-action-button {
      margin-top: 25px;
      margin-bottom: 30px;
    }
  }

  &.xaf-form-import {
    & > .qw-1-1 {
      position: relative;
    }
    .xaf-import-list {
      background-color: $grey-light;
      padding: 40px 30px 30px 30px;
      text-align: center;
      & > p {
        font-size: 1em;
        line-height: 1.4;
        margin-bottom: 30px;
      }
      .xaf-icon-import-size {
        color: $grey-dark;
        font-size: 100px;
        @include drop;
      }
    }
    input[type="file"],
    button[type="submit"] {
      display: none;
      z-index: -99999;

    }
    label {
      font-size: 1em;
      line-height: 1.2;
      display: block;
      margin-top: 10px;
      width: 100%;
    }
    &.box.has-advanced-upload .box__dragndrop {
      display: inline;
    }
    &.box.is-dragover .box__input {
      background-color: grey;
    }
    &.box.is-uploading .box__input {
      visibility: none;
    }
    &.box.is-uploading .box__uploading {
      display: block;
    }
  }


  .xaf-assign-user {

    h3 {
      font-size: 1.25em;
      line-height: 1.2;
      font-weight: 300;
      padding: 30px;
      margin: 0;
    }
    .xaf-assign-fill {
      &:last-child {
        border: none;
      }

      label {
        font-size: 1em;
        line-height: 1.2;
        padding: 9px 30px;
      }
      .xaf-form-select {
        &.qw-1-2 {
          width: 50%;
        }
        margin: 0;
      }
      position: relative;
      padding: 0;
      border-bottom: 1px solid $grey-dark;
      font-weight: 400;
    }
    &:last-child {
      h3 {
        @media screen and (max-width: $w-960) {
          padding: 30px;
        }
        font-size: 1.25em;
        line-height: 1.2;
        font-weight: 300;
        padding: 30px 30px 30px 0;
        margin: 0;
      }
    }
    &.xaf-assign-title {
      @media screen and (max-width: $w-960) {
        display: none;
      }
    }
  }

  .item-group-address {
    position: relative;
    height: 100px;
    padding: 18px 0px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
    &.no-separation {
      border: none !important;
    }
    & label {
      cursor: pointer;
    }

    &.one-line {
      label {
        font-size: 1em;
        line-height: 70px;
        height: 70px;
      }
    }

    &.auto-line {
      label {
        font-size: 1em;
        line-height: auto !important;
        height: auto !important;
      }
    }

  }

  .xaf-form-inner {
    width: 100%;
    height: calc(100% - 50px);
    display: block;
  }
  .xaf-form-group-text {
    margin: 5px 0 5px 0;
  }
  .xaf-form-fieldset {
    & > p{
      margin: 0;
    }
    &.xaf-form-fieldset-condition-show{
      margin-top: 20px;
    }
    &.xaf-article-picker-list-container{
      .xaf-article-preview-list{
        .xaf-article-preview-list-item{
          .xaf-article-preview-list-item-title{
            font-size: 14px;
            text-decoration: underline;
            margin-bottom: 10px;
            display: block;
          }
          &:hover,&.active{
            .xaf-article-preview-list-item-inner{
              display: block;
            }
          }
          .xaf-article-preview-list-item-inner{
            position: absolute;
            display: none;
            width: 100%;
            height: 100%;
            background: repeating-linear-gradient(
                            45deg,
                            rgba(0, 0, 0, 0.2),
                            rgba(0, 0, 0, 0.2) 10px,
                            rgba(0, 0, 0, 0.3) 10px,
                            rgba(0, 0, 0, 0.3) 20px
            );
          }
          .xaf-article-preview-list-item-input{

          }
          position: relative;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #ccc;
        }
        padding: 0;
        margin: 0;
        list-style: none;
        display: block;
        width: 100%;
        height: 100%;
      }
      height: calc( 100% - 140px );
      min-width: 100%;
      width: 100%;
      display: block;
      overflow: auto;
    }
    &.box-control{
      .xaf-action-button{
        &.right{
          float: right;
        }
        &:not( :last-of-type ){
          margin-right: 0;
        }
        &:not(:last-child){
          margin-right: 10px;
        }
      }
      position: absolute;
      display: block;
      width: auto;
      height: 38px;
      right: 30px;
      bottom: 9px;
    }
    &.padding-left-60 {
      padding-left: 60px;
    }
    &.fieldset-margin-20-20-0-0 {
      margin: 20px 20px 0 0 !important;
    }
    .xaf-form-fieldset-legend {
      span {
        font-size: 1em;
        line-height: 1.2;
        margin-right: 5px;
      }
      width: calc(100% - 10px);
      height: 22px;
      font-size: 1.2em;
      line-height: 1;
      margin-bottom: 6px;
    }
    display: block;
    width: 100%;
    height: auto;
    border: none;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  .xaf-form-group {

    &.xaf-form-group-input {
      padding: 0px 30px;
      margin-bottom: 30px;
    }

    &.xaf-form-group-select {
      padding: 0 0 0 30px;
      margin-bottom: 30px;
      position: relative;
    }

    .xaf-form-group-imagelink-left {
      .xaf-form-item-image {
        width: calc(100% - 10px);
      }
      float: left;
      width: 150px;
      height: auto;
      display: block;
      overflow: hidden;
      min-height: 1px;
      padding-top: 10px;
      position: relative;
    }
    .xaf-form-group-imagelink-right {
      .xaf-form-group-file {
        .xaf-file-container {
          &:before {
            content: '\00220f';
            font-size: 14px;
            //color: $dark;
          }

        }
      }
      float: left;
      display: block;
      width: calc(100% - 150px);
      height: auto;
      overflow: visible;
    }
    .xaf-form-item {

      &.error {
        border: 1px solid red;
      }

      &.textarea {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1em;
        font-weight: 300;
        height: 200px;
        padding: 10px;
      }
      margin-bottom: 16px;
      font-size: 12px;
      width: 100%; //calc( 100% - 20px );
      height: 37px;
      padding: 0 10px 0 10px;
      background-color: #ebebeb;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }
    > div.cke_reset {
      width: calc(100% - 10px);
    }
    &.xaf-form-group-inline {
      label {
        font-size: 1em;
        line-height: 1.2;
        float: left;
        width: 10%;
        padding: 0 5px;
      }
      .xaf-form-item {
        float: left;
        width: 85%;
      }
    }
    display: block;
    overflow: visible;
    width: 100%;
    height: auto;
  }

  .xaf-form-control {
    xaf-icon-button {
      &.right {
        float: right;
      }
      &:not( :last-of-type ) {
        margin-right: 10px;
      }
      float: right;
    }
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%; //calc( 100% - 5px );
    height: 38px;
  }

  .xaf-form-input {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
    width: calc(100% - 15px);
    height: 30px;
    display: block;
    padding: 0 5px 0 5px;
    background-color: #ebebeb;
  }

  .xaf-select-drop-down {
    pointer-events: none;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .xaf-form-select {
    &::-ms-expand {
      display: none;
    }
    &.xaf-select-base{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      display: block;
      background-color: $grey;
      padding: 0 30px;
      //width: 100%;
      font-size: 0.875em;
      line-height: 18px;
      height: 38px;
      margin-top: 5px;
      color: $black;
      position: relative;
      @include transition;
      border-radius: 0;
    }
    &.xaf-select-options {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      display: block;
      background-color: $grey;
      padding: 0 30px;
      width: 100%;
      font-size: 0.875em;
      line-height: 18px;
      height: 38px;
      margin-top: 5px;
      color: $black;
      position: relative;
      @include transition;
      border-radius: 0;
    }
    &.xaf-form-item-additional {
      margin-left: 40px;
      width: calc(100% - 85px);
    }
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
    width: calc(100%);
    height: 30px;
    display: block;
    padding: 0 5px 0 5px;
  }

  .xaf-form-input-radio {
    label {
      font-size: 1em;
      height: auto;
      line-height: 30px;
      width: 100%;
      display: block;
      overflow: hidden;
    }
    input[type=radio] {
      vertical-align: middle;
      margin: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
  }

  .xaf-form-group-file {

    .xaf-form-input {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1em;
      font-weight: 300;
      width: calc(100% - 56px);
      margin: 0;
      display: inline-block;
      border: none;
      min-height: 32px;
    }
    .xaf-file-container {
      &:hover {
        &:before {
          background: #a8c33d; //$favourite-color;
          color: #fefefe;
        }
      }
      &:before {
        font-family: 'artegic-icons';
        font-size: 1.5em;
        speak: none;
        text-transform: none;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        line-height: 30px;
        width: 100%;
        background: #fefefe;
        text-align: center;
        content: 'd';
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
      }
      position: relative;
      display: inline-block;

      @supports (-ms-ime-align:auto) {
        width: 40px;
      }
      width: 52px;
      height: 32px;
      vertical-align: top;
      border: 1px solid #ccc;
    }
    .xaf-fileupload {
      cursor: inherit;
      display: block;
      filter: alpha(opacity=0);
      height: 32px;
      width: 100%;
      opacity: 0;
      position: absolute;
      text-align: right;
    }
  }

  .xaf-input {
    @include transition;
    &[type=text],
    &[type=email],
    &[type=password],
    &[type=time],
    &[type=date] {
      display: block;
      background-color: $grey;
      padding: 0 30px;
      width: 100%;
      font-size: 0.875em;
      line-height: 18px;
      height: 38px;
      margin-top: 5px;
      color: $black;
      border-radius: 0;
    }
    &[type=radio],
    &[type=checkbox] {
      position: absolute;
      opacity: 0;
      width: 38px;
      height: 38px;
      margin: 0;
      right: 30px;
      top: 50%;
      margin-top: -19px;
      z-index: 50;
      & ~ .check {
        color: transparent;
        display: block;
        position: absolute;
        width: 38px;
        height: 38px;
        border: 1px solid $grey-dark;
        border-radius: 50%;
        right: 30px;
        top: 50%;
        margin-top: -19px;
        z-index: 49;
        @include transition;
      }
      &:hover {
        & ~ .check {
          background-color: $grey-light;
          color: $grey-light;
        }
      }
      &:checked {
        & ~ .check {
          border: 1px solid $second-color;
          background-color: $second-color;
          color: $white;
        }
      }
    }

    &[aria-invalid='true'] {
      border: 1px solid red;
    }
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }

  .xaf-textarea {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    display: block;
    background-color: $grey;
    padding: 0 30px;
    font-size: 0.875em;
    line-height: 18px;
    margin-top: 5px;
    color: $black;
    position: relative;
    @include transition;
    border-radius: 0;
    height: auto;
    min-height: 200px;
    padding-top: 15px;
    max-width: 100%;
  }

  .xaf-select {
    position: absolute;
    display: block;
    height: 38px;
    width: 38px;
    pointer-events: none;
    right: 0;
    bottom: 0;
    z-index: 10;
    @include transition;
  }

  .xaf-form-list {
    .xaf-complex-list-container {
      .xaf-complex-list {
        .xaf-complex-list-item {
          .xaf-complex-list-item-left {
            span {
              font-size: 1em;
              line-height: 1.2;
              display: block;
              width: 30px;
              height: 30px;
            }
            display: block;
            width: 30px;
            float: left;
          }
          .xaf-complex-list-item-middle {
            display: block;
            width: calc(100% - 125px);
            float: left;
          }
          .xaf-complex-list-item-right {
            button:disabled {
              visibility:hidden;
            }
            display: block;
            width: 125px;
            float: left;
            height: 37px;
          }
          display: block;
          width: 100%;
          height: 30px;
          padding: 0;
          list-style: none;
          margin-bottom: 5px;
        }
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      display: block;
      width: 100%;
      height: 200px;
      overflow: auto;
    }
    .xaf-complex-list-buttons{
      button{
        float: right;
      }
      display: block;
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
    }
  }

  .xaf-complex-index-wrapper {
    .xaf-complex-index-container {
      .xaf-complex-index {
        .xaf-complex-index-item {
          .xaf-complex-index-item-left {
            display: block;
            width: 45px;
            float: left;
            padding: 5px 0 0 5px;
          }
          .xaf-complex-index-item-right {
            display: block;
            width: calc(100% - 45px);
            float: left;
          }
          display: block;
          width: 100%;
          height: 30px;
          padding: 0;
          list-style: none;
          margin-bottom: 5px
        }
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        list-style: none;
      }
      display: block;
      width: 100%;
      height: 250px;
    }
  }

  &.delivery-time {
    .icon {
      &.calendar {
        top: 26px;
        right: 16px;
        @media screen and (max-width: $w-960) {
          right: 31px;
        }
      }
      &.time {
        top: 26px;
        right: 31px;
      }
    }
  }

  .xaf-label {
    @include transition;
    .e_icn{
      color: #ff1a16;
    }
  }


  .xaf-list-radio-item{

    &:hover{
      background-color: #ebebeb;
    }

    label{
      span{
        &.xaf-list-radio-item-title{
          margin-top: 25px;
          display: block;
        }
        font-size: 1em;
        line-height: 1.2;
      }
      font-size: 1em;
      height: 100%;
      line-height: 30px;
      width: 100%;
      display: block;
      overflow:hidden;
      cursor: pointer;
    }

    input[type=radio]{
      vertical-align: middle;
      margin: 0;
    }

    &:not(:first-of-type){
      border-top: 1px solid #e5e5e5;
    }

    p {
      font-size: 1em;
      line-height: 1.4;
      display: block; margin-bottom:5px;
    }

    .xaf-list-item-name {
      display:block; margin-bottom:8px;
    }

    .xaf-input[type=radio] {
      margin-top: -30px;
    }

    @media screen and (max-width: $w-960) {
      //padding-bottom: 90px;
    }
    .xaf-list-item-infos{
      p{
        margin: 0;
      }
    }
    height: 90px;
    width: 100%;
    position: relative;
    padding: 0px 40px;
    cursor: pointer;

  }

  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  float: left;

}


