$article-menu__A0: themed('element-action:active--bg-color') !default;
$article-menu__B0: themed('element-action:active--bg-color') !default;
$article-menu__B1: themed('element-action--text-color') !default;
$article-menu__D0: themed('element-action:active--bg-color') !default;

//@todo: move this to a elaine-generic folder, not only for messageEditor

.me__article-menu__box {

  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  outline: none;
  margin: 1px;
  font-size: 12px;

  &.active{
    .me__article-menu__tool-bar{
      z-index: 3001;
    }
    .me__article__overlay-infos{
      //outline-width: 4px;
    }
  }

  .me__article-menu__backdrop-passive {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: repeating-linear-gradient( 135deg, rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.3) 10px,  rgba(0, 0, 0, 0.3) 20px);
    opacity: .35;
  }

  .me__article-menu__more{
    display:inline-block;
    width:20px;
    height:20px;
    vertical-align: text-bottom;
    &::before{
      content: url( '/gui/fonts/six/icons/more.svg' );
      width:20px;
      height:20px;
      box-sizing: border-box;
    }
  }

  .me__article-menu__tool-bar{
    position: absolute;
    background: $article-menu__D0;
    width: 30px;
    font-size: 12px;

    &--main{
      right: -34px;
      top:-25px;
      z-index: 2000;
    }

    &--top{
      left: calc( 50%  - 10px ); //Correct middle withou toolbar-left
      top: -55px;
      z-index: 1002;
    }

    &--bottom{
      left: calc( 50%  - 10px ); //Correct middle withou toolbar-left
      bottom: -34px;
      z-index: 1002;
    }
  }


  .md-open-menu-container.md-active md-menu-item{
    background-color:transparent;
  }
}


.md-open-menu-container,
.md-open-menu-container * {
  .me__article-menu__opt-group{
    color: #a8c33d;
    border-bottom: 1px solid #a8c33d;
    margin-top: 5px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
  }
  .me__article-menu__opt-group-item{
    padding-left: 16px
  }
  -webkit-transition: none !important;
  -moz-transition: none !important;
  transition: none !important;
}
