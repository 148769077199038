.xaf-list-pagination{
  height:38px;
  padding: 0 30px;
  width:auto;
  float:right;
  position:relative;

  .xaf-pag-item{

    &[disabled]{
      .pag-icon{
        opacity: .5;
      }
      cursor: default;
    }
    float:left;
    width:40px;
    height:100%;
    background-color: $white;
    color:$black;
    font-size: 20px;
    line-height:40px;
    cursor:pointer;
    text-align:center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover:not( [disabled] ){
      background-color:$grey;
      //background-color:$second-color;
    }

    .pag-icon{
      display: block;
      height: 20px;
      width: auto;
      margin: 2px auto 0;
    }

    &.pag-number{
      &.current{
        background-color:$second-color;
        color:$white;
      }
    }
  }
}