.xaf-section {
  width: 100%;
  position: relative;

  @include clear;

  &.header-top-padding {
    padding-top: 60px;
  }

  &.xaf-section-border {
    border-bottom: 1px solid #e5e5e5;
  }

  .xaf-section-title {
    h1 {
      font-size: 2.25em;
      line-height: 1.2;
      font-weight: 300;
      @media screen and (max-width: $w-640) {
        font-size: 2em;
      }
      @media screen and (max-width: $w-320) {
        font-size: 1.8em;
      }
    }

    h2 {
      font-size: 1.375em;
      line-height: 1.2;
      font-weight: 300;
    }

    h3 {
      font-size: 1.25em;
      line-height: 1.2;
      font-weight: 300;
    }

    h4 {
      font-size: 1.125em;
      line-height: 1.2;
      font-weight: 300;
    }

    h1,h2,h3{
      margin: 0;
    }
    @media screen and (max-width: $w-640) {
      font-size: 80%;
    }
    padding: 30px;
  }

  .xaf-section-footer {
    display: block;
    height: 40px;
    padding: 0 30px;

    .right {
      float: right;
    }
  }

}
