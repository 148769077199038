

.xaf-search{

  height: auto;
  overflow: hidden;

}



.ghost-item{

  display:none;
}



