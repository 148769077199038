$create-approval__A0: #731513;
$create-approval__B0: #75882c;
$create-approval__C0: #aa2921;
$create-approval__D0: #e27f01;

.dialog__create-approval {
  .analysis-content {
    &__scrollBody{
      max-height: 700px;
    }
    &__expandableBox{
      &_info {
        .md-title{
          color: $create-approval__B0 !important;
        }
      }
      &_error {
        .md-title{
          color: $create-approval__C0 !important;
        }
      }
      &_warning {
        .md-title{
          color: $create-approval__D0 !important;
        }
      }
    }
    &__text{
      border: none;
      margin: 0 0 10px 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
    &__info {
      color: $create-approval__B0;
      line-height: 30px;
    }
    &__error {
      color: $create-approval__C0;
      line-height: 30px;
    }
    &__warning {
      color: $create-approval__D0;
      line-height: 30px;
    }
  }

  .me__form-row-container {
    flex-direction: row;
  }

  &__articles-fieldset {
    margin-top: -35px !important;
  }

  .chosen-container.chosen-container-multi {
    max-height: 100px;

    //Hide scroll bar
    .chosen-choices {
      width: calc(100% - 32px)
    }

    &:before {
      background: #404644 !important;
    }
  }

  .me__form-row {
    margin: 0;
  }

  .me__form-row__column {
    display: flex;

    &.me__form-row__column--big {
      flex-grow: 1;
    }

    &.me__form-row__column--multiselect-big {
      flex-grow: 1;
      min-height: 80px;
      max-width: 375px;
    }
  }

  .dialog__create-approval__name--input {
    width: 76%;

    .nameInput {
      width: 100% !important;
    }
  }


  .dialog__create-approval__approval-object-type {
    flex-grow: 1;
    min-height: 40px;
  }

  .dialog__create-approval__name--label {
    width: 23.5%;

    label {
      width: 100% !important;
    }
  }

  .dialog__create-approval__menu-button--alignment {
    float: right;
    margin-top: -15px;
  }


  .dialog__create-approval__label {
    width: 23.5%;

    label {
      width: 100% !important;
    }
  }

  .dialog__create-approval__legend--margin {
    margin-bottom: 13px;
  }

  .dialog__create-approval__required-icon {
    margin-left: -15px;

    &::before {
      line-height: 30px;
      color: $create-approval__A0;
    }
  }

  .dialog__create-approval__approval-objects {
    .chosen-container {
      .chosen-drop {
        .chosen-results {
          li {
            $itemDif: 10px;
            $minDist: 5px;
            transition: opacity 0.1s linear;

            //@todo: opacity without option-class???
            &.level_0 {
              opacity: 1;
              padding-left: $minDist;
            }

            &.level_1 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 1);

            }

            &.level_2 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 2);
            }

            &.level_3 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 3);
            }

            &.level_4 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 4);
            }

            &.level_5 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 5);
            }

            &.level_6 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 6);
            }

            &.disabled {
              opacity: 0.3;
              pointer-events: none;
            }

            opacity: 1;
          }
        }

        .chosen-search {
          display: none;
        }
      }
    }
  }
}
