$w-1500: 1500px;
$w-1400: 1400px;
$w-1300: 1300px;
$w-1200: 1200px;
$w-1024: 1024px;
$w-960: 960px;
$w-768: 768px;
$w-640: 640px;
$w-480: 480px;
$w-320: 320px;
$white: #fff;
$black: #000;
$grey: #ebebeb;
$grey-light: #f2f2f2;
$grey-dark: #b2b2b2;
$blue: #3471ef;
$blue-dark: #3d61a3;
$purple: #753aba;
$purple-dark: #5c3f8a;
$purple-darker: #37214f;
$green: #25cf14;
$green-light: #a8c33d;
$green-dark: #428e28;
$green-darker: #387c22;
$red: #ff1a16;
$red-dark: #9e1c19;
$red-darker: #79170e;
$orange: #c28325;
$dark: #4c4c4c;
$dark-darker: #191919;

@mixin transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.approval-page {
  background: $white !important;
  color: $black !important;

  #previewReleaseTab {
      height: 60vh !important;
  }

  .ge__loading-spinner {
    background: rgba(0, 0, 0, 0.2);
  }
  .e__table--border{
    border: none;
  }
  .e__table-row--header{
    border-bottom: 1px solid #adadad !important;
    font-weight: bold !important;
    color: #505050 !important;
  }
  .e__table-row{
    border-bottom: 1px solid #d8d8d8;
  }
  .e__table-col{
    border-right: none !important;
  }

  .mention-menu{
    background: #eaeaea !important;
  }
  .mention-item{
    color: black;
  }
  .mention-item.active {
    background: #d8d8d8 !important;
    color: black;
  }

  & md-switch {
    &.md-checked {
      .md-bar {
        background-color: $blue !important;
        border-color: $blue !important;
      }
    }

    .md-thumb {
      width: 18px;
      height: 18px;
      top: 4px;
      left: 4px;
      background-color: $grey;
    }

    .md-bar {
      top: 4px;
      background-color: transparent;
      border: 1px solid $blue !important;
      height: 22px;
      width: 38px;
      border-radius: 15px;
    }

    .md-container {
      height: 100%;
    }

    color: $black !important;
    margin: 0;
  }
  tab-header {
    & a {
      background: $white;
      color: $black !important;
    }
    .me__tab-header__li.active{
      a {
        background: $blue !important;
        color: $white !important;
      }
      border-bottom: 0px !important;
    }
  }

  &-btn {
    background: $blue !important;
  }

  &-preview {
    width: 100%;
  }

  & label {
    color: $black !important;
  }

  & .md-label {
    color: $black !important;
  }

  &-btn-approve {
    background: $green-light !important;
  }

  &-btn-cancel {
    background: $red !important;
  }

  &__header {
    height: 100px;
    background: $green-light;
    color: $white;
    width: 100% !important;
    float: left !important;
    position: absolute !important;
    z-index: 100 !important;
    top: 0 !important;
    min-height: 100% !important;
    box-shadow: 0px 0px 10px 9px rgba(0, 0, 0, 0.3);

    &-title {
      font-size: xx-large;
      line-height: 3;
      padding: 2%;
    }
  }

  & .me__subject-show {
    border-bottom: 1px solid #ebebeb !important;
  }

  &__container {
    margin-top: 145px;
    width: 100% !important;
    float: left !important;
    position: absolute !important;
    z-index: 100 !important;
    top: 0 !important;
    background-color: $white !important;
    min-height: 100% !important;
    border-top: 1px solid #ebebeb !important;

    @include transition;
  }

  &__feedback-given {
    color: black !important;
    height: 100% !important;
    margin-top: 96px !important;
    z-index: 101 !important;
    background: white !important;
    position: absolute;
    width: 100%;
    font-size: 35px;

    .given-feedback-text {
      position: absolute;
      margin-top: 57px !important;
      top: 30% !important;
      left: 34%;
      font-size: 1.5em;
    }
  }

  &__approval-not-valid {
    color: black !important;
    height: 100% !important;
    margin-top: 96px !important;
    z-index: 101 !important;
    background: white !important;
    position: absolute;
    width: 100%;
    font-size: 35px;

    .approval-not-valid-text {
      position: absolute;
      margin-top: 57px !important;
      top: 30% !important;
      left: 30%;
      font-size: 1.5em;
    }
  }

  &__modeButtons {
    &.left {
      float: left;
      background: white;
      width: 100%;
      border-bottom: 1px solid black;
    }

    & > div {
      width: 70px;
      height: 50px;
      float: left;
      cursor: pointer;
      position: relative;
      text-align: center;

      &:hover {
        background-color: $grey;
      }

      &.active {
        background-color: $blue;
        color: $white;
      }

      small {
        position: absolute;
        width: 100%;
        display: inline-block;
        left: 0;
        bottom: 3px;
      }

      .icon {
        width: 100%;
        height: 36px;
        left: 0;
        top: 0;
      }

      .iconDevice {
        .xaf-icon-device-mobile {
          font-size: .8em;
        }

        .xaf-icon-device-tablet {
          font-size: .8em;
        }

        font-size: 28px;
        line-height: 36px;
      }
    }

    width: auto;
    height: 50px;
    float: right;
    background-color: $white;
    position: relative;
    z-index: 1209;
    color: $black !important;

  }

}

.xaf-aside {
  @media screen and (max-width: $w-1500) {
    width: 20%;
  }
  @media screen and (max-width: $w-1200) {
    width: 25%;
  }
  @media screen and (max-width: $w-1024) {
    width: 30%;
  }
  @media screen and (max-width: $w-768) {
    width: 35%;
  }
  @media screen and (max-width: $w-640) {
    &.active {
      left: 0;

      &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 100%;
        z-index: 109;
        background: #000;
        opacity: .5;
        left: 0;
        top: 0;
        position: fixed;
        @include transition;
      }
    }
    width: 50%;
    left: -50%;
    z-index: 110;
    height: 100%;
  }

  @media screen and (max-width: $w-480) {
    width: 70%;
    left: -70%;
    z-index: 110;
    height: 100%;
  }

  .xaf-aside-main-menu {
    .xaf-aside-menu-list {
      margin: 0;
      padding: 0;
      vertical-align: baseline;
    }

    .xaf-aside-menu-secondary {
      ul {
        li {
          a {
            height: auto;
            min-height: 38px;
          }

          &.active {
            a {
              color: #b5b5b5;

              &:hover {
                background-color: $white;
                cursor: default;
              }
            }
          }
        }
      }

      margin-top: 30px;
    }

    ul {
      li {
        list-style: none;

        a {
          padding: 10px 0 10px 48px;
          text-decoration: none;
          font-size: 1em;
          position: relative;
          display: block;
          height: 38px;
          line-height: 18px;
          @include transition;
          background-color: $white;
          color: $black;

          .icon {
            top: 0;
            left: 0;
            @include transition;
            background-color: $white;
          }

          &:hover {
            background-color: $grey;
            color: $black;

            .icon {
              background-color: $grey-dark;
            }
          }

          &.current-area {
            background-color: $blue;
            color: $white;

            .icon {
              background-color: $blue-dark;
              color: $black;
            }
          }
        }
      }
    }

    &.active {
      display: block;
    }

    @media screen and (max-width: $w-640) {
      padding-top: 68px;
      z-index: 110;
      .primary {
        height: auto;
      }
    }
    overflow: hidden;
    position: relative;
    top: 0;
    padding: 30px 0 0 0;
    width: 100%;
    left: 0;
    height: 100%;
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    @include transition;
    background: #fff;
    z-index: 95;

  }

  .xaf-aside-menu-link {

    .burger {
      position: absolute;
      left: 9px;
      top: 18px;
      height: 3px;
      background: $black;
      width: 20px;
      border-radius: 3px;

      &::before, &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $black;
        content: "";
        -webkit-transition-duration: 0.2s, 0.2s;
        -moz-transition-duration: 0.2s, 0.2s;
        -ms-transition-duration: 0.2s, 0.2s;
        transition-duration: 0.2s, 0.2s;
        -webkit-transition-delay: 0.2s, 0s;
        -moz-transition-delay: 0.2s, 0s;
        -ms-transition-delay: 0.2s, 0s;
        -o-transition-delay: 0.2s, 0s;
        transition-delay: 0.2s, 0s;
        border-radius: 3px;
      }

      &::before {
        top: -6px;
        -webkit-transition-property: top, -webkit-transform;
        -moz-transition-property: top, -moz-transform;
        -ms-transition-property: top, -ms-transform;
        -o-transition-property: top, -0-transform;
        transition-property: top, transform;
      }

      &::after {
        bottom: -6px;
        -webkit-transition-property: bottom, -webkit-transform;
        -moz-transition-property: bottom, -moz-transform;
        -ms-transition-property: bottom, -ms-transform;
        -o-transition-property: bottom, -0-transform;
        transition-property: bottom, transform;
      }

      @media screen and (max-width: $w-640) {
        background: $white;
        &::before, &::after {
          background: $white;
        }
      }
    }

    &.active {
      &::before, &::after {
        background: $black !important;
      }

      .burger {
        background: none;

        &::before, &::after {
          -webkit-transition-delay: 0s, 0.3s;
          -moz-transition-delay: 0s, 0.3s;
          -ms-transition-delay: 0s, 0.3s;
          -o-transition-delay: 0s, 0.3s;
          transition-delay: 0s, 0.3s;
        }

        &::before {
          top: 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &::after {
          bottom: 0;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        @media screen and (max-width: $w-640) {
          &::before, &::after {
            background: $black !important;
          }
        }
      }

      background: $white;
    }

    .nav-title {
      position: relative;
      font-weight: 300;
    }

    @media screen and (max-width: $w-640) {
      position: fixed;
      color: $white;
      left: 0;
      height: 38px;
      .nav-title {
        display: none;
        color: $black;
      }

      &.active {
        .nav-title {
          display: block;
        }
      }
    }

    @include transition;
    position: relative;
    display: block;
    color: $black;
    z-index: 999999;
    width: 100%;
    padding: 10px 0 10px 48px;
    text-decoration: none;
    overflow: hidden;
    background: $white;
  }

  &.active {
    box-shadow: 0px 0px 10px 9px rgba(0, 0, 0, 0.3);
  }

  .xaf-aside-link {
    .artegic-link:visited {
      color: #444;
    }

    .artegic-link:link {
      color: #444;
      text-decoration: none;
    }

    .artegic-link {
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      min-width: 85px;
      height: 30px;
      font-size: 11px;
    }

    position: absolute;
    bottom: 0px;
    left: 38px;
    z-index: 100;

  }

  position: fixed;
  width: 300px;
  height: 100%;
  z-index: 99;
  border-right: 0 solid $white;

}

.xaf-open-menu {
  width: 84.5% !important;
  left: 15.5% !important;
}
