.xaf-dashboard-item-btn-options, .btn-preview, .btn-info {
  @media screen and (max-width: $w-1200) {
    margin-top: -20px;
  }
  .dots {
    position: absolute;
    left: 13px;
    top: 13px;
    height: 4px;
    background-color: $grey-dark;
    width: 4px;
    border-radius: 2px;

    &::before, &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $grey-dark;
      content: "";
      -webkit-transition-duration: 0.2s, 0.2s;
      -moz-transition-duration: 0.2s, 0.2s;
      -ms-transition-duration: 0.2s, 0.2s;
      transition-duration: 0.2s, 0.2s;
      -webkit-transition-delay: 0.2s, 0s;
      -moz-transition-delay: 0.2s, 0s;
      -ms-transition-delay: 0.2s, 0s;
      -o-transition-delay: 0.2s, 0s;
      transition-delay: 0.2s, 0s;
      border-radius: 3px;
    }

    &::before {
      left: -6px;
      -webkit-transition-property: left, -webkit-transform;
      -moz-transition-property: left, -moz-transform;
      -ms-transition-property: left, -ms-transform;
      -o-transition-property: left, -0-transform;
      transition-property: left, transform;
    }

    &::after {
      left: 6px;
      -webkit-transition-property: left, -webkit-transform;
      -moz-transition-property: left, -moz-transform;
      -ms-transition-property: left, -ms-transform;
      -o-transition-property: left, -0-transform;
      transition-property: left, transform;
    }
  }

  &:hover {
    background-color: $grey;
  }

  &.active {
    background-color: $blue;
    &::before, &::after {
      background-color: $white;
    }
    .dots {
      background: none;
      &::before, &::after {
        width: 20px;
        background-color: $white;
        -webkit-transition-delay: 0s, 0.3s;
        -moz-transition-delay: 0s, 0.3s;
        -ms-transition-delay: 0s, 0.3s;
        -o-transition-delay: 0s, 0.3s;
        transition-delay: 0s, 0.3s;
      }
      &::before {
        left: -8px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &::after {
        left: -8px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
  border-radius: 50%;
  background-color: $grey-light;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.xaf-dashboard-item-options {

  background-color: $blue;
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: right;
  padding: 0 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  & > a {
    color: $white;
    line-height: 28px;
    margin-left: 40px;
    display: inline-block;
    font-weight: 400;
      cursor: pointer;
  }

  .preview {
    padding-left: 20px;
    margin: 0;
    .icon {
      margin-left: -24px;
      margin-top: 5px;
      width: 18px;
      height: 20px;
    }
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 0;
    right: 276px;
  }

  @media screen and (max-width: $w-640) {
    & > a {
      margin-left: 20px;
    }
  }
}


