
.pdfjs .textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: .2;
  line-height: 1.0
}

.pdfjs .textLayer>div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.pdfjs .textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: #b400aa;
  border-radius: 4px
}

.pdfjs .textLayer .highlight.begin {
  border-radius: 4px 0 0 4px
}

.pdfjs .textLayer .highlight.end {
  border-radius: 0 4px 4px 0
}

.pdfjs .textLayer .highlight.middle {
  border-radius: 0
}

.pdfjs .textLayer .highlight.selected {
  background-color: #006400
}

.pdfjs .textLayer::selection {
  background: #00f
}

.pdfjs .textLayer::-moz-selection {
  background: #00f
}

.pdfjs .textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none
}

.pdfjs .textLayer .endOfContent.active {
  top: 0
}

.pdfjs .annotationLayer section {
  position: absolute
}

.pdfjs .annotationLayer .linkAnnotation>a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.pdfjs .annotationLayer .linkAnnotation>a {
  background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7") 0 0 repeat
}

.pdfjs .annotationLayer .linkAnnotation>a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0
}

.pdfjs .annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer
}

.pdfjs .annotationLayer .textWidgetAnnotation input,
.pdfjs .annotationLayer .textWidgetAnnotation textarea,
.pdfjs .annotationLayer .choiceWidgetAnnotation select,
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  padding: 0 3px;
  vertical-align: top;
  width: 100%
}

.pdfjs .annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none
}

.pdfjs .annotationLayer .textWidgetAnnotation input[disabled],
.pdfjs .annotationLayer .textWidgetAnnotation textarea[disabled],
.pdfjs .annotationLayer .choiceWidgetAnnotation select[disabled],
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: 0;
  border: 1px solid transparent;
  cursor: default
}

.pdfjs .annotationLayer .textWidgetAnnotation input:hover,
.pdfjs .annotationLayer .textWidgetAnnotation textarea:hover,
.pdfjs .annotationLayer .choiceWidgetAnnotation select:hover,
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid #000
}

.pdfjs .annotationLayer .textWidgetAnnotation input:focus,
.pdfjs .annotationLayer .textWidgetAnnotation textarea:focus,
.pdfjs .annotationLayer .choiceWidgetAnnotation select:focus {
  background: 0;
  border: 1px solid transparent
}

.pdfjs .annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0
}

.pdfjs .annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 115%
}

.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none
}

.pdfjs .annotationLayer .popupWrapper {
  position: absolute;
  width: 20em
}

.pdfjs .annotationLayer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: #ff9;
  box-shadow: 0 2px 5px #333;
  border-radius: 2px;
  padding: .6em;
  margin-left: 5px;
  cursor: pointer;
  word-wrap: break-word
}

.pdfjs .annotationLayer .popup h1 {
  font-size: 1em;
  border-bottom: 1px solid #000;
  padding-bottom: .2em
}

.pdfjs .annotationLayer .popup p {
  padding-top: .2em
}

.pdfjs .annotationLayer .highlightAnnotation,
.pdfjs .annotationLayer .underlineAnnotation,
.pdfjs .annotationLayer .squigglyAnnotation,
.pdfjs .annotationLayer .strikeoutAnnotation,
.pdfjs .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer
}

.pdfjs .pdfViewer .canvasWrapper {
  overflow: hidden
}

.pdfjs .pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: 1px auto -8px auto;
  position: relative;
  overflow: visible;
  border: 9px solid transparent;
  background-clip: content-box;
  border-image: url(/gui/img/pdfviewer/shadow.png) 9 9 repeat;
  background-color: white
}

.pdfjs .pdfViewer.removePageBorders .page {
  margin: 0 auto 10px auto;
  border: 0
}

.pdfjs .pdfViewer.singlePageView {
  display: inline-block
}

.pdfjs .pdfViewer.singlePageView .page {
  margin: 0;
  border: 0
}

.pdfjs .pdfViewer .page canvas {
  margin: 0;
  display: block
}

.pdfjs .pdfViewer .page .loadingIcon {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('/gui/img/pdfviewer/loading-icon.gif') center no-repeat
}

.pdfjs .pdfPresentationMode:-webkit-full-screen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0
}

.pdfjs .pdfPresentationMode:-moz-full-screen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0
}

.pdfjs .pdfPresentationMode:-ms-fullscreen .pdfViewer .page {
  margin-bottom: 100% !important;
  border: 0
}

.pdfjs .pdfPresentationMode:fullscreen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0
}

.pdfjs * {
  padding: 0;
  margin: 0
}

.pdfjs input,
.pdfjs button,
.pdfjs select {
  font: message-box;
  outline: 0
}

.pdfjs .hidden {
  display: none !important
}

.pdfjs [hidden] {
  display: none !important
}

.pdfjs #viewerContainer.pdfPresentationMode:-webkit-full-screen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -webkit-user-select: none
}

.pdfjs #viewerContainer.pdfPresentationMode:-moz-full-screen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -moz-user-select: none
}

.pdfjs #viewerContainer.pdfPresentationMode:-ms-fullscreen {
  top: 0 !important;
  border-top: 2px solid transparent;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  cursor: none;
  -ms-user-select: none
}

.pdfjs #viewerContainer.pdfPresentationMode:-ms-fullscreen::-ms-backdrop {
  background-color: #000
}

.pdfjs #viewerContainer.pdfPresentationMode:fullscreen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}

.pdfjs .pdfPresentationMode:-webkit-full-screen a:not(.internalLink) {
  display: none
}

.pdfjs .pdfPresentationMode:-moz-full-screen a:not(.internalLink) {
  display: none
}

.pdfjs .pdfPresentationMode:-ms-fullscreen a:not(.internalLink) {
  display: none !important
}

.pdfjs .pdfPresentationMode:fullscreen a:not(.internalLink) {
  display: none
}

.pdfjs .pdfPresentationMode:-webkit-full-screen .textLayer>div {
  cursor: none
}

.pdfjs .pdfPresentationMode:-moz-full-screen .textLayer>div {
  cursor: none
}

.pdfjs .pdfPresentationMode:-ms-fullscreen .textLayer>div {
  cursor: none
}

.pdfjs .pdfPresentationMode:fullscreen .textLayer>div {
  cursor: none
}

.pdfjs .pdfPresentationMode.pdfPresentationModeControls>*,
.pdfjs .pdfPresentationMode.pdfPresentationModeControls .textLayer>div {
  cursor: default
}

.pdfjs #outerContainer {
  width: 100%;
  height: 100%;
  position: relative
}

.pdfjs #sidebarContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200px;
  visibility: hidden;
  -webkit-transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-duration: 200ms;
  transition-timing-function: ease
}

html[dir='ltr'] .pdfjs #sidebarContainer {
  -webkit-transition-property: left;
  transition-property: left;
  left: -200px
}

html[dir='rtl'] .pdfjs #sidebarContainer {
  -webkit-transition-property: right;
  transition-property: right;
  right: -200px
}

.pdfjs #outerContainer.sidebarMoving>#sidebarContainer,
.pdfjs #outerContainer.sidebarOpen>#sidebarContainer {
  visibility: visible
}

html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen>#sidebarContainer {
  left: 0
}

html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen>#sidebarContainer {
  right: 0
}

.pdfjs #mainContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  -webkit-transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-duration: 200ms;
  transition-timing-function: ease
}

html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen>#mainContainer {
  -webkit-transition-property: left;
  transition-property: left;
  left: 200px
}

html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen>#mainContainer {
  -webkit-transition-property: right;
  transition-property: right;
  right: 200px
}

.pdfjs #sidebarContent {
  top: 32px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  width: 200px;
  background-color: hsla(0, 0%, 0%, .1)
}

html[dir='ltr'] .pdfjs #sidebarContent {
  left: 0;
  box-shadow: inset -1px 0 0 hsla(0, 0%, 0%, .25)
}

html[dir='rtl'] .pdfjs #sidebarContent {
  right: 0;
  box-shadow: inset 1px 0 0 hsla(0, 0%, 0%, .25)
}

.pdfjs #viewerContainer {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0
}

html[dir='ltr'] .pdfjs #viewerContainer {
  box-shadow: inset 1px 0 0 hsla(0, 0%, 100%, .05);
  min-height: 166px;
}

html[dir='rtl'] .pdfjs #viewerContainer {
  box-shadow: inset -1px 0 0 hsla(0, 0%, 100%, .05)
}

.pdfjs .toolbar {
  position: relative;
  left: 0;
  right: 0;
  z-index: 9999;
  cursor: default
}

.pdfjs #toolbarContainer {
  width: 100%
}

.pdfjs #toolbarSidebar {
  width: 200px;
  height: 32px;
  background-color: #424242;
  background-image: url(/gui/img/pdfviewer/texture.png), linear-gradient(hsla(0, 0%, 30%, .99), hsla(0, 0%, 25%, .95))
}

html[dir='ltr'] .pdfjs #toolbarSidebar {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 hsla(0, 0%, 100%, .05), 0 1px 0 hsla(0, 0%, 0%, .15), 0 0 1px hsla(0, 0%, 0%, .1)
}

html[dir='rtl'] .pdfjs #toolbarSidebar {
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.25), inset 0 1px 0 hsla(0, 0%, 100%, .05), 0 1px 0 hsla(0, 0%, 0%, .15), 0 0 1px hsla(0, 0%, 0%, .1)
}

.pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  position: relative;
  height: 32px;
  background-color: #474747;
  background-image: url(/gui/img/pdfviewer/texture.png), linear-gradient(hsla(0, 0%, 32%, .99), hsla(0, 0%, 27%, .95))
}

html[dir='ltr'] .pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  box-shadow: inset 1px 0 0 hsla(0, 0%, 100%, .08), inset 0 1px 1px hsla(0, 0%, 0%, .15), inset 0 -1px 0 hsla(0, 0%, 100%, .05), 0 1px 0 hsla(0, 0%, 0%, .15), 0 1px 1px hsla(0, 0%, 0%, .1)
}

html[dir='rtl'] .pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  box-shadow: inset -1px 0 0 hsla(0, 0%, 100%, .08), inset 0 1px 1px hsla(0, 0%, 0%, .15), inset 0 -1px 0 hsla(0, 0%, 100%, .05), 0 1px 0 hsla(0, 0%, 0%, .15), 0 1px 1px hsla(0, 0%, 0%, .1)
}

.pdfjs #toolbarViewer {
  height: 32px
}

.pdfjs #loadingBar {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #333;
  border-bottom: 1px solid #333
}

.pdfjs #loadingBar .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ddd;
  overflow: hidden;
  -webkit-transition: width 200ms;
  transition: width 200ms
}


.pdfjs #loadingBar .progress.indeterminate {
  background-color: #999;
  -webkit-transition: none;
  transition: none
}

.pdfjs #loadingBar .progress.indeterminate .glimmer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 150px);
  background: repeating-linear-gradient(135deg, #bbb 0, #999 5px, #999 45px, #ddd 55px, #ddd 95px, #bbb 100px);
  -webkit-animation: progressIndeterminate 950ms linear infinite;
  animation: progressIndeterminate 950ms linear infinite
}

.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  top: 32px;
  position: absolute;
  z-index: 10000;
  height: 32px;
  min-width: 16px;
  padding: 0 6px 0 6px;
  margin: 4px 2px 4px 2px;
  color: hsl(0, 0%, 85%);
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  cursor: default
}

html[dir='ltr'] .pdfjs .findbar {
  left: 68px
}

html[dir='rtl'] .pdfjs .findbar {
  right: 68px
}

.pdfjs .findbar label {
  -webkit-user-select: none;
  -moz-user-select: none
}

.pdfjs #findInput[data-status="pending"] {
  background-image: url(/gui/img/pdfviewer/loading-small.png);
  background-repeat: no-repeat;
  background-position: right
}

html[dir='rtl'] .pdfjs #findInput[data-status="pending"] {
  background-position: left
}

.pdfjs .secondaryToolbar {
  padding: 6px;
  height: auto;
  z-index: 30000
}

html[dir='ltr'] .pdfjs .secondaryToolbar {
  right: 4px
}

html[dir='rtl'] .pdfjs .secondaryToolbar {
  left: 4px
}

.pdfjs #secondaryToolbarButtonContainer {
  max-width: 200px;
  max-height: 400px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: -4px
}

.pdfjs .doorHanger,
.pdfjs .doorHangerRight {
  border: 1px solid hsla(0, 0%, 0%, .5);
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3)
}

.pdfjs .doorHanger:after,
.pdfjs .doorHanger:before,
.pdfjs .doorHangerRight:after,
.pdfjs .doorHangerRight:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.pdfjs .doorHanger:after,
.pdfjs .doorHangerRight:after {
  border-bottom-color: hsla(0, 0%, 32%, .99);
  border-width: 8px
}

.pdfjs .doorHanger:before,
.pdfjs .doorHangerRight:before {
  border-bottom-color: hsla(0, 0%, 0%, .5);
  border-width: 9px
}

html[dir='ltr'] .pdfjs .doorHanger:after,
html[dir='rtl'] .pdfjs .doorHangerRight:after {
  left: 13px;
  margin-left: -8px
}

html[dir='ltr'] .pdfjs .doorHanger:before,
html[dir='rtl'] .pdfjs .doorHangerRight:before {
  left: 13px;
  margin-left: -9px
}

html[dir='rtl'] .pdfjs .doorHanger:after,
html[dir='ltr'] .pdfjs .doorHangerRight:after {
  right: 13px;
  margin-right: -8px
}

html[dir='rtl'] .pdfjs .doorHanger:before,
html[dir='ltr'] .pdfjs .doorHangerRight:before {
  right: 13px;
  margin-right: -9px
}

.pdfjs #findResultsCount {
  background-color: hsl(0, 0%, 85%);
  color: hsl(0, 0%, 32%);
  text-align: center;
  padding: 3px 4px
}

.pdfjs #findMsg {
  font-style: italic;
  color: #a6b7d0
}

.pdfjs #findInput.notFound {
  background-color: #f66
}

.pdfjs #toolbarViewerMiddle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
}

html[dir='ltr'] .pdfjs #toolbarViewerLeft,
html[dir='rtl'] .pdfjs #toolbarViewerRight {
  float: left
}

html[dir='ltr'] .pdfjs #toolbarViewerRight,
html[dir='rtl'] .pdfjs #toolbarViewerLeft {
  float: right
}

html[dir='ltr'] .pdfjs #toolbarViewerLeft>*,
html[dir='ltr'] .pdfjs #toolbarViewerMiddle>*,
html[dir='ltr'] .pdfjs #toolbarViewerRight>*,
html[dir='ltr'] .pdfjs .findbar>* {
  position: relative;
  float: left
}

html[dir='rtl'] .pdfjs #toolbarViewerLeft>*,
html[dir='rtl'] .pdfjs #toolbarViewerMiddle>*,
html[dir='rtl'] .pdfjs #toolbarViewerRight>*,
html[dir='rtl'] .pdfjs .findbar>* {
  position: relative;
  float: right
}

html[dir='ltr'] .pdfjs .splitToolbarButton {
  margin: 3px 2px 4px 0;
  display: inline-block
}

html[dir='rtl'] .pdfjs .splitToolbarButton {
  margin: 3px 0 4px 2px;
  display: inline-block
}

html[dir='ltr'] .pdfjs .splitToolbarButton>.toolbarButton {
  border-radius: 0;
  float: left
}

html[dir='rtl'] .pdfjs .splitToolbarButton>.toolbarButton {
  border-radius: 0;
  float: right
}

.pdfjs .toolbarButton,
.pdfjs .secondaryToolbarButton,
.pdfjs .overlayButton {
  border: 0 none;
  background: 0;
  width: 32px;
  height: 25px
}

.pdfjs .toolbarButton>span {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden
}

.pdfjs .toolbarButton[disabled],
.pdfjs .secondaryToolbarButton[disabled],
.pdfjs .overlayButton[disabled] {
  opacity: .5
}

.pdfjs .toolbarButton.group {
  margin-right: 0
}

.pdfjs .splitToolbarButton.toggled .toolbarButton {
  margin: 0
}

.pdfjs .splitToolbarButton:hover>.toolbarButton,
.pdfjs .splitToolbarButton:focus>.toolbarButton,
.pdfjs .splitToolbarButton.toggled>.toolbarButton,
.pdfjs .toolbarButton.textButton {
  background-color: hsla(0, 0%, 0%, .12);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  border: 1px solid hsla(0, 0%, 0%, .35);
  border-color: hsla(0, 0%, 0%, .32) hsla(0, 0%, 0%, .38) hsla(0, 0%, 0%, .42);
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .15) inset, 0 1px 0 hsla(0, 0%, 100%, .05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 150ms;
  -webkit-transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease
}

.pdfjs .splitToolbarButton>.toolbarButton:hover,
.pdfjs .splitToolbarButton>.toolbarButton:focus,
.pdfjs .dropdownToolbarButton:hover,
.pdfjs .overlayButton:hover,
.pdfjs .overlayButton:focus,
.pdfjs .toolbarButton.textButton:hover,
.pdfjs .toolbarButton.textButton:focus {
  background-color: hsla(0, 0%, 0%, .2);
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .15) inset, 0 0 1px hsla(0, 0%, 0%, .05);
  z-index: 199
}

.pdfjs .splitToolbarButton>.toolbarButton {
  position: relative
}

html[dir='ltr'] .pdfjs .splitToolbarButton>.toolbarButton:first-child,
html[dir='rtl'] .pdfjs .splitToolbarButton>.toolbarButton:last-child {
  position: relative;
  margin: 0;
  margin-right: -1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right-color: transparent
}

html[dir='ltr'] .pdfjs .splitToolbarButton>.toolbarButton:last-child,
html[dir='rtl'] .pdfjs .splitToolbarButton>.toolbarButton:first-child {
  position: relative;
  margin: 0;
  margin-left: -1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left-color: transparent
}

.pdfjs .splitToolbarButtonSeparator {
  padding: 8px 0;
  width: 1px;
  background-color: hsla(0, 0%, 0%, .5);
  z-index: 99;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .08);
  display: inline-block;
  margin: 5px 0
}

html[dir='ltr'] .pdfjs .splitToolbarButtonSeparator {
  float: left
}

html[dir='rtl'] .pdfjs .splitToolbarButtonSeparator {
  float: right
}

.pdfjs .splitToolbarButton:hover>.splitToolbarButtonSeparator,
.pdfjs .splitToolbarButton.toggled>.splitToolbarButtonSeparator {
  padding: 12px 0;
  margin: 1px 0;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .03);
  -webkit-transition-property: padding;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: ease;
  transition-property: padding;
  transition-duration: 10ms;
  transition-timing-function: ease
}

.pdfjs .toolbarButton,
.pdfjs .dropdownToolbarButton,
.pdfjs .secondaryToolbarButton,
.pdfjs .overlayButton {
  min-width: 16px;
  padding: 2px 6px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: hsla(0, 0%, 100%, .8);
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 150ms;
  -webkit-transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease
}

html[dir='ltr'] .pdfjs .toolbarButton,
html[dir='ltr'] .pdfjs .overlayButton,
html[dir='ltr'] .pdfjs .dropdownToolbarButton {
  margin: 3px 2px 4px 0
}

html[dir='rtl'] .pdfjs .toolbarButton,
html[dir='rtl'] .pdfjs .overlayButton,
html[dir='rtl'] .pdfjs .dropdownToolbarButton {
  margin: 3px 0 4px 2px
}

.pdfjs .toolbarButton:hover,
.pdfjs .toolbarButton:focus,
.pdfjs .dropdownToolbarButton,
.pdfjs .overlayButton,
.pdfjs .secondaryToolbarButton:hover,
.pdfjs .secondaryToolbarButton:focus {
  background-color: hsla(0, 0%, 0%, .12);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  border: 1px solid hsla(0, 0%, 0%, .35);
  border-color: hsla(0, 0%, 0%, .32) hsla(0, 0%, 0%, .38) hsla(0, 0%, 0%, .42);
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .15) inset, 0 1px 0 hsla(0, 0%, 100%, .05)
}

.pdfjs .toolbarButton:hover:active,
.pdfjs .overlayButton:hover:active,
.pdfjs .dropdownToolbarButton:hover:active,
.pdfjs .secondaryToolbarButton:hover:active {
  background-color: hsla(0, 0%, 0%, .2);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  border-color: hsla(0, 0%, 0%, .35) hsla(0, 0%, 0%, .4) hsla(0, 0%, 0%, .45);
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, .1) inset, 0 0 1px hsla(0, 0%, 0%, .2) inset, 0 1px 0 hsla(0, 0%, 100%, .05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: linear;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 10ms;
  transition-timing-function: linear
}

.pdfjs .toolbarButton.toggled,
.pdfjs .splitToolbarButton.toggled>.toolbarButton.toggled,
.pdfjs .secondaryToolbarButton.toggled {
  background-color: hsla(0, 0%, 0%, .3);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  border-color: hsla(0, 0%, 0%, .4) hsla(0, 0%, 0%, .45) hsla(0, 0%, 0%, .5);
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, .1) inset, 0 0 1px hsla(0, 0%, 0%, .2) inset, 0 1px 0 hsla(0, 0%, 100%, .05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: linear;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 10ms;
  transition-timing-function: linear
}

.pdfjs .toolbarButton.toggled:hover:active,
.pdfjs .splitToolbarButton.toggled>.toolbarButton.toggled:hover:active,
.pdfjs .secondaryToolbarButton.toggled:hover:active {
  background-color: hsla(0, 0%, 0%, .4);
  border-color: hsla(0, 0%, 0%, .4) hsla(0, 0%, 0%, .5) hsla(0, 0%, 0%, .55);
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, .2) inset, 0 0 1px hsla(0, 0%, 0%, .3) inset, 0 1px 0 hsla(0, 0%, 100%, .05)
}

.pdfjs .dropdownToolbarButton {
  width: 120px;
  max-width: 120px;
  padding: 0;
  overflow: hidden;
  background: url(/gui/img/pdfviewer/toolbarButton-menuArrows.png) no-repeat
}

html[dir='ltr'] .pdfjs .dropdownToolbarButton {
  background-position: 95%
}

html[dir='rtl'] .pdfjs .dropdownToolbarButton {
  background-position: 5%
}

.pdfjs .dropdownToolbarButton>select {
  min-width: 140px;
  font-size: 12px;
  color: hsl(0, 0%, 95%);
  margin: 0;
  padding: 3px 2px 2px;
  border: 0;
  background: rgba(0, 0, 0, 0)
}

.pdfjs .dropdownToolbarButton>select>option {
  background: hsl(0, 0%, 24%)
}

.pdfjs #customScaleOption {
  display: none
}

.pdfjs #pageWidthOption {
  border-bottom: 1px rgba(255, 255, 255, .5) solid
}

html[dir='ltr'] .pdfjs .splitToolbarButton:first-child,
html[dir='ltr'] .pdfjs .toolbarButton:first-child,
html[dir='rtl'] .pdfjs .splitToolbarButton:last-child,
html[dir='rtl'] .pdfjs .toolbarButton:last-child {
  margin-left: 4px
}

html[dir='ltr'] .pdfjs .splitToolbarButton:last-child,
html[dir='ltr'] .pdfjs .toolbarButton:last-child,
html[dir='rtl'] .pdfjs .splitToolbarButton:first-child,
html[dir='rtl'] .pdfjs .toolbarButton:first-child {
  margin-right: 4px
}

.pdfjs .toolbarButtonSpacer {
  width: 30px;
  display: inline-block;
  height: 1px
}

html[dir='ltr'] .pdfjs #findPrevious {
  margin-left: 3px
}

html[dir='ltr'] .pdfjs #findNext {
  margin-right: 3px
}

html[dir='rtl'] .pdfjs #findPrevious {
  margin-right: 3px
}

html[dir='rtl'] .pdfjs #findNext {
  margin-left: 3px
}

.pdfjs .toolbarButton::before,
.pdfjs .secondaryToolbarButton::before {
  position: absolute;
  display: inline-block;
  top: 4px;
  left: 7px
}

html[dir="ltr"] .pdfjs .secondaryToolbarButton::before {
  left: 4px
}

html[dir="rtl"] .pdfjs .secondaryToolbarButton::before {
  right: 4px
}

html[dir='ltr'] .pdfjs .toolbarButton#sidebarToggle::before {
  content: url(/gui/img/pdfviewer/toolbarButton-sidebarToggle.png)
}

html[dir='rtl'] .pdfjs .toolbarButton#sidebarToggle::before {
  content: url(/gui/img/pdfviewer/toolbarButton-sidebarToggle-rtl.png)
}

html[dir='ltr'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
  content: url(/gui/img/pdfviewer/toolbarButton-secondaryToolbarToggle.png)
}

html[dir='rtl'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
  content: url(/gui/img/pdfviewer/toolbarButton-secondaryToolbarToggle-rtl.png)
}

html[dir='ltr'] .pdfjs .toolbarButton.findPrevious::before {
  content: url(/gui/img/pdfviewer/findbarButton-previous.png)
}

html[dir='rtl'] .pdfjs .toolbarButton.findPrevious::before {
  content: url(/gui/img/pdfviewer/findbarButton-previous-rtl.png)
}

html[dir='ltr'] .pdfjs .toolbarButton.findNext::before {
  content: url(/gui/img/pdfviewer/findbarButton-next.png)
}

html[dir='rtl'] .pdfjs .toolbarButton.findNext::before {
  content: url(/gui/img/pdfviewer/findbarButton-next-rtl.png)
}

html[dir='ltr'] .pdfjs .toolbarButton.pageUp::before {
  content: url(/gui/img/pdfviewer/toolbarButton-pageUp.png)
}

html[dir='rtl'] .pdfjs .toolbarButton.pageUp::before {
  content: url(/gui/img/pdfviewer/toolbarButton-pageUp-rtl.png)
}

html[dir='ltr'] .pdfjs .toolbarButton.pageDown::before {
  content: url(/gui/img/pdfviewer/toolbarButton-pageDown.png)
}

html[dir='rtl'] .pdfjs .toolbarButton.pageDown::before {
  content: url(/gui/img/pdfviewer/toolbarButton-pageDown-rtl.png)
}

.pdfjs .toolbarButton.zoomOut::before {
  content: url(/gui/img/pdfviewer/toolbarButton-zoomOut.png)
}

.pdfjs .toolbarButton.zoomIn::before {
  content: url(/gui/img/pdfviewer/toolbarButton-zoomIn.png)
}

.pdfjs .toolbarButton.presentationMode::before,
.pdfjs .secondaryToolbarButton.presentationMode::before {
  content: url(/gui/img/pdfviewer/toolbarButton-presentationMode.png)
}

.pdfjs .toolbarButton.print::before,
.pdfjs .secondaryToolbarButton.print::before {
  content: url(/gui/img/pdfviewer/toolbarButton-print.png)
}

.pdfjs .toolbarButton.openFile::before,
.pdfjs .secondaryToolbarButton.openFile::before {
  content: url(/gui/img/pdfviewer/toolbarButton-openFile.png)
}

.pdfjs .toolbarButton.download::before,
.pdfjs .secondaryToolbarButton.download::before {
  content: url(/gui/img/pdfviewer/toolbarButton-download.png)
}

.pdfjs .toolbarButton.bookmark,
.pdfjs .secondaryToolbarButton.bookmark {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  padding-top: 4px;
  text-decoration: none
}

.pdfjs .secondaryToolbarButton.bookmark {
  padding-top: 5px
}

.pdfjs .bookmark[href='#'] {
  opacity: .5;
  pointer-events: none
}

.pdfjs .toolbarButton.bookmark::before,
.pdfjs .secondaryToolbarButton.bookmark::before {
  content: url(/gui/img/pdfviewer/toolbarButton-bookmark.png)
}

.pdfjs #viewThumbnail.toolbarButton::before {
  content: url(/gui/img/pdfviewer/toolbarButton-viewThumbnail.png)
}

html[dir="ltr"] .pdfjs #viewOutline.toolbarButton::before {
  content: url(/gui/img/pdfviewer/toolbarButton-viewOutline.png)
}

html[dir="rtl"] .pdfjs #viewOutline.toolbarButton::before {
  content: url(/gui/img/pdfviewer/toolbarButton-viewOutline-rtl.png)
}

.pdfjs #viewAttachments.toolbarButton::before {
  content: url(/gui/img/pdfviewer/toolbarButton-viewAttachments.png)
}

.pdfjs #viewFind.toolbarButton::before {
  content: url(/gui/img/pdfviewer/toolbarButton-search.png)
}

.pdfjs .toolbarButton.pdfSidebarNotification::after {
  position: absolute;
  display: inline-block;
  top: 1px;
  content: '';
  background-color: #70db55;
  height: 9px;
  width: 9px;
  border-radius: 50%
}

html[dir='ltr'] .pdfjs .toolbarButton.pdfSidebarNotification::after {
  left: 17px
}

html[dir='rtl'] .pdfjs .toolbarButton.pdfSidebarNotification::after {
  right: 17px
}

.pdfjs .secondaryToolbarButton {
  position: relative;
  margin: 0 0 4px 0;
  padding: 3px 0 1px 0;
  height: auto;
  min-height: 25px;
  width: auto;
  min-width: 100%;
  white-space: normal
}

html[dir="ltr"] .pdfjs .secondaryToolbarButton {
  padding-left: 24px;
  text-align: left
}

html[dir="rtl"] .pdfjs .secondaryToolbarButton {
  padding-right: 24px;
  text-align: right
}

html[dir="ltr"] .pdfjs .secondaryToolbarButton.bookmark {
  padding-left: 27px
}

html[dir="rtl"] .pdfjs .secondaryToolbarButton.bookmark {
  padding-right: 27px
}

html[dir="ltr"] .pdfjs .secondaryToolbarButton>span {
  padding-right: 4px
}

html[dir="rtl"] .pdfjs .secondaryToolbarButton>span {
  padding-left: 4px
}

.pdfjs .secondaryToolbarButton.firstPage::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-firstPage.png)
}

.pdfjs .secondaryToolbarButton.lastPage::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-lastPage.png)
}

.pdfjs .secondaryToolbarButton.rotateCcw::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-rotateCcw.png)
}

.pdfjs .secondaryToolbarButton.rotateCw::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-rotateCw.png)
}

.pdfjs .secondaryToolbarButton.handTool::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-handTool.png)
}

.pdfjs .secondaryToolbarButton.documentProperties::before {
  content: url(/gui/img/pdfviewer/secondaryToolbarButton-documentProperties.png)
}

.pdfjs .verticalToolbarSeparator {
  display: block;
  padding: 8px 0;
  margin: 8px 4px;
  width: 1px;
  background-color: hsla(0, 0%, 0%, .5);
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .08)
}

html[dir='ltr'] .pdfjs .verticalToolbarSeparator {
  margin-left: 2px
}

html[dir='rtl'] .pdfjs .verticalToolbarSeparator {
  margin-right: 2px
}

.pdfjs .horizontalToolbarSeparator {
  display: block;
  margin: 0 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: hsla(0, 0%, 0%, .5);
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .08)
}

.pdfjs .toolbarField {
  padding: 3px 6px;
  margin: 4px 0 4px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: hsla(0, 0%, 100%, .09);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  border: 1px solid hsla(0, 0%, 0%, .35);
  border-color: hsla(0, 0%, 0%, .32) hsla(0, 0%, 0%, .38) hsla(0, 0%, 0%, .42);
  box-shadow: 0 1px 0 hsla(0, 0%, 0%, .05) inset, 0 1px 0 hsla(0, 0%, 100%, .05);
  color: hsl(0, 0%, 95%);
  font-size: 12px;
  line-height: 14px;
  outline-style: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease
}

.pdfjs .toolbarField[type=checkbox] {
  display: inline-block;
  margin: 8px 0
}

.pdfjs .toolbarField.pageNumber {
  -moz-appearance: textfield;
  min-width: 16px;
  text-align: right;
  width: 40px
}

.pdfjs .toolbarField.pageNumber.visiblePageIsLoading {
  background-image: url(/gui/img/pdfviewer/loading-small.png);
  background-repeat: no-repeat;
  background-position: 1px
}

.pdfjs .toolbarField.pageNumber::-webkit-inner-spin-button,
.pdfjs .toolbarField.pageNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.pdfjs .toolbarField:hover {
  background-color: hsla(0, 0%, 100%, .11);
  border-color: hsla(0, 0%, 0%, .4) hsla(0, 0%, 0%, .43) hsla(0, 0%, 0%, .45)
}

.pdfjs .toolbarField:focus {
  background-color: hsla(0, 0%, 100%, .15);
  border-color: hsla(204, 100%, 65%, .8) hsla(204, 100%, 65%, .85) hsla(204, 100%, 65%, .9)
}

.pdfjs .toolbarLabel {
  min-width: 16px;
  padding: 3px 6px 3px 2px;
  margin: 4px 2px 4px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: hsl(0, 0%, 85%);
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default
}

.pdfjs #thumbnailView {
  position: absolute;
  width: 120px;
  top: 0;
  bottom: 0;
  padding: 10px 40px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.pdfjs .thumbnail {
  float: left;
  margin-bottom: 5px
}

.pdfjs #thumbnailView>a:last-of-type>.thumbnail {
  margin-bottom: 10px
}

.pdfjs #thumbnailView>a:last-of-type>.thumbnail:not([data-loaded]) {
  margin-bottom: 9px
}

.pdfjs .thumbnail:not([data-loaded]) {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  margin: -1px -1px 4px -1px
}

.pdfjs .thumbnailImage {
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
  opacity: .8;
  z-index: 99;
  background-color: white;
  background-clip: content-box
}

.pdfjs .thumbnailSelectionRing {
  border-radius: 2px;
  padding: 7px
}

.pdfjs a:focus>.thumbnail>.thumbnailSelectionRing>.thumbnailImage,
.pdfjs .thumbnail:hover>.thumbnailSelectionRing>.thumbnailImage {
  opacity: .9
}

.pdfjs a:focus>.thumbnail>.thumbnailSelectionRing,
.pdfjs .thumbnail:hover>.thumbnailSelectionRing {
  background-color: hsla(0, 0%, 100%, .15);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .2) inset, 0 0 1px hsla(0, 0%, 0%, .2);
  color: hsla(0, 0%, 100%, .9)
}

.pdfjs .thumbnail.selected>.thumbnailSelectionRing>.thumbnailImage {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, .5);
  opacity: 1
}

.pdfjs .thumbnail.selected>.thumbnailSelectionRing {
  background-color: hsla(0, 0%, 100%, .3);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .1) inset, 0 0 1px hsla(0, 0%, 0%, .2);
  color: hsla(0, 0%, 100%, 1)
}

.pdfjs #outlineView,
.pdfjs #attachmentsView {
  position: absolute;
  width: 192px;
  top: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none
}

.pdfjs #outlineView {
  padding: 4px 4px 0
}

.pdfjs #attachmentsView {
  padding: 3px 4px 0
}

html[dir='ltr'] .pdfjs .outlineWithDeepNesting>.outlineItem,
html[dir='ltr'] .pdfjs .outlineItem>.outlineItems {
  margin-left: 20px
}

html[dir='rtl'] .pdfjs .outlineWithDeepNesting>.outlineItem,
html[dir='rtl'] .pdfjs .outlineItem>.outlineItems {
  margin-right: 20px
}

.pdfjs .outlineItem>a,
.pdfjs .attachmentsItem>button {
  text-decoration: none;
  display: inline-block;
  min-width: 95%;
  min-width: calc(100% - 4px);
  height: auto;
  margin-bottom: 1px;
  border-radius: 2px;
  color: hsla(0, 0%, 100%, .8);
  font-size: 13px;
  line-height: 15px;
  -moz-user-select: none;
  white-space: normal
}

.pdfjs .attachmentsItem>button {
  border: 0 none;
  background: 0;
  cursor: pointer;
  width: 100%
}

html[dir='ltr'] .pdfjs .outlineItem>a {
  padding: 2px 0 5px 4px
}

html[dir='ltr'] .pdfjs .attachmentsItem>button {
  padding: 2px 0 3px 7px;
  text-align: left
}

html[dir='rtl'] .pdfjs .outlineItem>a {
  padding: 2px 4px 5px 0
}

html[dir='rtl'] .pdfjs .attachmentsItem>button {
  padding: 2px 7px 3px 0;
  text-align: right
}

.pdfjs .outlineItemToggler {
  position: relative;
  height: 0;
  width: 0;
  color: hsla(0, 0%, 100%, .5)
}

.pdfjs .outlineItemToggler::before {
  content: url(/gui/img/pdfviewer/treeitem-expanded.png);
  display: inline-block;
  position: absolute
}

html[dir='ltr'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
  content: url(/gui/img/pdfviewer/treeitem-collapsed.png)
}

html[dir='rtl'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
  content: url(/gui/img/pdfviewer/treeitem-collapsed-rtl.png)
}

.pdfjs .outlineItemToggler.outlineItemsHidden ~ .outlineItems {
  display: none
}

html[dir='ltr'] .pdfjs .outlineItemToggler {
  float: left
}

html[dir='rtl'] .pdfjs .outlineItemToggler {
  float: right
}

html[dir='ltr'] .pdfjs .outlineItemToggler::before {
  right: 4px
}

html[dir='rtl'] .pdfjs .outlineItemToggler::before {
  left: 4px
}

.pdfjs .outlineItemToggler:hover,
.pdfjs .outlineItemToggler:hover+a,
.pdfjs .outlineItemToggler:hover ~ .outlineItems,
.pdfjs .outlineItem>a:hover,
.pdfjs .attachmentsItem>button:hover {
  background-color: hsla(0, 0%, 100%, .02);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .2) inset, 0 0 1px hsla(0, 0%, 0%, .2);
  border-radius: 2px;
  color: hsla(0, 0%, 100%, .9)
}

.pdfjs .outlineItem.selected {
  background-color: hsla(0, 0%, 100%, .08);
  background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 hsla(0, 0%, 100%, .05) inset, 0 0 1px hsla(0, 0%, 100%, .1) inset, 0 0 1px hsla(0, 0%, 0%, .2);
  color: hsla(0, 0%, 100%, 1)
}

.pdfjs .noResults {
  font-size: 12px;
  color: hsla(0, 0%, 100%, .8);
  font-style: italic;
  cursor: default
}

.pdfjs::selection {
  background: rgba(0, 0, 255, 0.3)
}

.pdfjs::-moz-selection {
  background: rgba(0, 0, 255, 0.3)
}

.pdfjs #errorWrapper {
  background: none repeat scroll 0 0 #f55;
  color: white;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1000;
  padding: 3px;
  font-size: .8em
}

.pdfjs .loadingInProgress #errorWrapper {
  top: 37px
}

.pdfjs #errorMessageLeft {
  float: left
}

.pdfjs #errorMessageRight {
  float: right
}

.pdfjs #errorMoreInfo {
  background-color: #fff;
  color: black;
  padding: 3px;
  margin: 3px;
  width: 98%
}

.pdfjs .overlayButton {
  width: auto;
  margin: 3px 4px 2px 4px !important;
  padding: 2px 6px 3px 6px
}

.pdfjs #overlayContainer {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, .2);
  z-index: 40000
}

.pdfjs #overlayContainer>* {
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.pdfjs #overlayContainer>.container {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.pdfjs #overlayContainer>.container>.dialog {
  display: inline-block;
  padding: 15px;
  border-spacing: 4px;
  color: hsl(0, 0%, 85%);
  font-size: 12px;
  line-height: 14px;
  background-color: #474747;
  background-image: url(/gui/img/pdfviewer/texture.png), linear-gradient(hsla(0, 0%, 32%, .99), hsla(0, 0%, 27%, .95));
  box-shadow: inset 1px 0 0 hsla(0, 0%, 100%, .08), inset 0 1px 1px hsla(0, 0%, 0%, .15), inset 0 -1px 0 hsla(0, 0%, 100%, .05), 0 1px 0 hsla(0, 0%, 0%, .15), 0 1px 1px hsla(0, 0%, 0%, .1);
  border: 1px solid hsla(0, 0%, 0%, .5);
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3)
}

.pdfjs .dialog>.row {
  display: table-row
}

.pdfjs .dialog>.row>* {
  display: table-cell
}

.pdfjs .dialog .toolbarField {
  margin: 5px 0
}

.pdfjs .dialog .separator {
  display: block;
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: hsla(0, 0%, 0%, .5);
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .08)
}

.pdfjs .dialog .buttonRow {
  text-align: center;
  vertical-align: middle
}

.pdfjs .dialog:link {
  color: white
}

.pdfjs #passwordOverlay>.dialog {
  text-align: center
}

.pdfjs #passwordOverlay .toolbarField {
  width: 200px
}

.pdfjs #documentPropertiesOverlay>.dialog {
  text-align: left
}

.pdfjs #documentPropertiesOverlay .row>* {
  min-width: 100px
}

html[dir='ltr'] .pdfjs #documentPropertiesOverlay .row>* {
  text-align: left
}

html[dir='rtl'] .pdfjs #documentPropertiesOverlay .row>* {
  text-align: right
}

.pdfjs #documentPropertiesOverlay .row>span {
  width: 125px;
  word-wrap: break-word
}

.pdfjs #documentPropertiesOverlay .row>p {
  max-width: 225px;
  word-wrap: break-word
}

.pdfjs #documentPropertiesOverlay .buttonRow {
  margin-top: 10px
}

.pdfjs .clearBoth {
  clear: both
}

.pdfjs .fileInput {
  background: white;
  color: black;
  margin-top: 5px;
  visibility: hidden;
  position: fixed;
  right: 0;
  top: 0
}

.pdfjs #PDFBug {
  background: none repeat scroll 0 0 white;
  border: 1px solid #666;
  position: fixed;
  top: 32px;
  right: 0;
  bottom: 0;
  font-size: 10px;
  padding: 0;
  width: 300px
}

.pdfjs #PDFBug .controls {
  background: #eee;
  border-bottom: 1px solid #666;
  padding: 3px
}

.pdfjs #PDFBug .panels {
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: 27px
}

.pdfjs #PDFBug button.active {
  font-weight: bold
}

.pdfjs .debuggerShowText {
  background: none repeat scroll 0 0 yellow;
  color: blue
}

.pdfjs .debuggerHideText:hover {
  background: none repeat scroll 0 0 yellow
}

.pdfjs #PDFBug .stats {
  font-family: courier;
  font-size: 10px;
  white-space: pre
}

.pdfjs #PDFBug .stats .title {
  font-weight: bold
}

.pdfjs #PDFBug table {
  font-size: 10px
}

.pdfjs #viewer.textLayer-visible .textLayer {
  opacity: 1.0
}

.pdfjs #viewer.textLayer-visible .canvasWrapper {
  background-color: #80ff80
}

.pdfjs #viewer.textLayer-visible .canvasWrapper canvas {
  mix-blend-mode: screen
}

.pdfjs #viewer.textLayer-visible .textLayer>div {
  background-color: rgba(255, 255, 0, 0.1);
  color: black;
  border: solid 1px rgba(255, 0, 0, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.pdfjs #viewer.textLayer-hover .textLayer>div:hover {
  background-color: white;
  color: black
}

.pdfjs #viewer.textLayer-shadow .textLayer>div {
  background-color: rgba(255, 255, 255, .6);
  color: black
}

.pdfjs .grab-to-pan-grab {
  cursor: url("/gui/img/pdfviewer/grab.cur"), move !important;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important
}

.pdfjs .grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
  cursor: inherit !important
}

.pdfjs .grab-to-pan-grab:active,
.pdfjs .grab-to-pan-grabbing {
  cursor: url("/gui/img/pdfviewer/grabbing.cur"), move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
  position: fixed;
  background: transparent;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 50000
}

@page {
  margin: 0
}

.pdfjs #printContainer {
  display: none
}

@media screen and (min-resolution:2dppx) {
  .pdfjs .toolbarButton::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -5px
  }
  .pdfjs .secondaryToolbarButton::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -4px
  }
  html[dir='ltr'] .pdfjs .toolbarButton::before,
  html[dir='rtl'] .pdfjs .toolbarButton::before {
    left: -1px
  }
  html[dir='ltr'] .pdfjs .secondaryToolbarButton::before {
    left: -2px
  }
  html[dir='rtl'] .pdfjs .secondaryToolbarButton::before {
    left: 186px
  }
  .pdfjs .toolbarField.pageNumber.visiblePageIsLoading,
  .pdfjs #findInput[data-status="pending"] {
    background-image: url(/gui/img/pdfviewer/loading-small@2x.png);
    background-size: 16px 17px
  }
  .pdfjs .dropdownToolbarButton {
    background: url(/gui/img/pdfviewer/toolbarButton-menuArrows@2x.png) no-repeat;
    background-size: 7px 16px
  }
  html[dir='ltr'] .pdfjs .toolbarButton#sidebarToggle::before {
    content: url(/gui/img/pdfviewer/toolbarButton-sidebarToggle@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton#sidebarToggle::before {
    content: url(/gui/img/pdfviewer/toolbarButton-sidebarToggle-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
    content: url(/gui/img/pdfviewer/toolbarButton-secondaryToolbarToggle@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
    content: url(/gui/img/pdfviewer/toolbarButton-secondaryToolbarToggle-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .toolbarButton.findPrevious::before {
    content: url(/gui/img/pdfviewer/findbarButton-previous@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton.findPrevious::before {
    content: url(/gui/img/pdfviewer/findbarButton-previous-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .toolbarButton.findNext::before {
    content: url(/gui/img/pdfviewer/findbarButton-next@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton.findNext::before {
    content: url(/gui/img/pdfviewer/findbarButton-next-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .toolbarButton.pageUp::before {
    content: url(/gui/img/pdfviewer/toolbarButton-pageUp@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton.pageUp::before {
    content: url(/gui/img/pdfviewer/toolbarButton-pageUp-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .toolbarButton.pageDown::before {
    content: url(/gui/img/pdfviewer/toolbarButton-pageDown@2x.png)
  }
  html[dir='rtl'] .pdfjs .toolbarButton.pageDown::before {
    content: url(/gui/img/pdfviewer/toolbarButton-pageDown-rtl@2x.png)
  }
  .pdfjs .toolbarButton.zoomIn::before {
    content: url(/gui/img/pdfviewer/toolbarButton-zoomIn@2x.png)
  }
  .pdfjs .toolbarButton.zoomOut::before {
    content: url(/gui/img/pdfviewer/toolbarButton-zoomOut@2x.png)
  }
  .pdfjs .toolbarButton.presentationMode::before,
  .pdfjs .secondaryToolbarButton.presentationMode::before {
    content: url(/gui/img/pdfviewer/toolbarButton-presentationMode@2x.png)
  }
  .pdfjs .toolbarButton.print::before,
  .pdfjs .secondaryToolbarButton.print::before {
    content: url(/gui/img/pdfviewer/toolbarButton-print@2x.png)
  }
  .pdfjs .toolbarButton.openFile::before,
  .pdfjs .secondaryToolbarButton.openFile::before {
    content: url(/gui/img/pdfviewer/toolbarButton-openFile@2x.png)
  }
  .pdfjs .toolbarButton.download::before,
  .pdfjs .secondaryToolbarButton.download::before {
    content: url(/gui/img/pdfviewer/toolbarButton-download@2x.png)
  }
  .pdfjs .toolbarButton.bookmark::before,
  .pdfjs .secondaryToolbarButton.bookmark::before {
    content: url(/gui/img/pdfviewer/toolbarButton-bookmark@2x.png)
  }
  .pdfjs #viewThumbnail.toolbarButton::before {
    content: url(/gui/img/pdfviewer/toolbarButton-viewThumbnail@2x.png)
  }
  html[dir="ltr"] .pdfjs #viewOutline.toolbarButton::before {
    content: url(/gui/img/pdfviewer/toolbarButton-viewOutline@2x.png)
  }
  html[dir="rtl"] .pdfjs #viewOutline.toolbarButton::before {
    content: url(/gui/img/pdfviewer/toolbarButton-viewOutline-rtl@2x.png)
  }
  .pdfjs #viewAttachments.toolbarButton::before {
    content: url(/gui/img/pdfviewer/toolbarButton-viewAttachments@2x.png)
  }
  .pdfjs #viewFind.toolbarButton::before {
    content: url(/gui/img/pdfviewer/toolbarButton-search@2x.png)
  }
  .pdfjs .secondaryToolbarButton.firstPage::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-firstPage@2x.png)
  }
  .pdfjs .secondaryToolbarButton.lastPage::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-lastPage@2x.png)
  }
  .pdfjs .secondaryToolbarButton.rotateCcw::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-rotateCcw@2x.png)
  }
  .pdfjs .secondaryToolbarButton.rotateCw::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-rotateCw@2x.png)
  }
  .pdfjs .secondaryToolbarButton.handTool::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-handTool@2x.png)
  }
  .pdfjs .secondaryToolbarButton.documentProperties::before {
    content: url(/gui/img/pdfviewer/secondaryToolbarButton-documentProperties@2x.png)
  }
  .pdfjs .outlineItemToggler::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -1px;
    content: url(/gui/img/pdfviewer/treeitem-expanded@2x.png)
  }
  html[dir='ltr'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
    content: url(/gui/img/pdfviewer/treeitem-collapsed@2x.png)
  }
  html[dir='rtl'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
    content: url(/gui/img/pdfviewer/treeitem-collapsed-rtl@2x.png)
  }
  html[dir='ltr'] .pdfjs .outlineItemToggler::before {
    right: 0
  }
  html[dir='rtl'] .pdfjs .outlineItemToggler::before {
    left: 0
  }
}

@media print {
  body {
    background: transparent none
  }
  .pdfjs #sidebarContainer,
  .pdfjs #secondaryToolbar,
  .pdfjs .toolbar,
  .pdfjs #loadingBox,
  .pdfjs #errorWrapper,
  .pdfjs .textLayer {
    display: none
  }
  .pdfjs #viewerContainer {
    overflow: visible
  }
  .pdfjs #mainContainer,
  .pdfjs #viewerContainer,
  .pdfjs .page,
  .pdfjs .page canvas {
    position: static;
    padding: 0;
    margin: 0
  }
  .pdfjs .page {
    float: left;
    display: none;
    border: 0;
    box-shadow: none;
    background-clip: content-box;
    background-color: white
  }
  .pdfjs .page[data-loaded] {
    display: block
  }
  .pdfjs .fileInput {
    display: none
  }
  body[data-pdfjsprinting] .pdfjs #outerContainer {
    display: none
  }
  body[data-pdfjsprinting] .pdfjs #printContainer {
    display: block
  }
  .pdfjs #printContainer {
    height: 100%
  }
  .pdfjs #printContainer>div {
    position: relative;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: visible;
    page-break-after: always;
    page-break-inside: avoid
  }
  .pdfjs #printContainer canvas,
  .pdfjs #printContainer img {
    display: block
  }
}

.pdfjs .visibleLargeView,
.pdfjs .visibleMediumView,
.pdfjs .visibleSmallView {
  display: none
}

@media all and (max-width:1040px) {
  .pdfjs #outerContainer.sidebarMoving #toolbarViewerMiddle,
  .pdfjs #outerContainer.sidebarOpen #toolbarViewerMiddle {
    display: table;
    margin: auto;
    left: auto;
    position: inherit;
    transform: none
  }
}

@media all and (max-width:980px) {
  .pdfjs .sidebarMoving .hiddenLargeView,
  .pdfjs .sidebarOpen .hiddenLargeView {
    display: none
  }
  .pdfjs .sidebarMoving .visibleLargeView,
  .pdfjs .sidebarOpen .visibleLargeView {
    display: inherit
  }
}

@media all and (max-width:900px) {
  .pdfjs #toolbarViewerMiddle {
    display: table;
    margin: auto;
    left: auto;
    position: inherit;
    transform: none
  }
  .pdfjs .sidebarMoving .hiddenMediumView,
  .pdfjs .sidebarOpen .hiddenMediumView {
    display: none
  }
  .pdfjs .sidebarMoving .visibleMediumView,
  .pdfjs .sidebarOpen .visibleMediumView {
    display: inherit
  }
}

@media all and (max-width:840px) {
  .pdfjs #sidebarContainer {
    top: 32px;
    z-index: 100
  }
  .pdfjs .loadingInProgress #sidebarContainer {
    top: 37px
  }
  .pdfjs #sidebarContent {
    top: 32px;
    background-color: hsla(0, 0%, 0%, .7)
  }
  html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen>#mainContainer {
    left: 0
  }
  html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen>#mainContainer {
    right: 0
  }
  .pdfjs #outerContainer .hiddenLargeView,
  .pdfjs #outerContainer .hiddenMediumView {
    display: inherit
  }
  .pdfjs #outerContainer .visibleLargeView,
  .pdfjs #outerContainer .visibleMediumView {
    display: none
  }
}

@media all and (max-width:770px) {
  .pdfjs #outerContainer .hiddenLargeView {
    display: none
  }
  .pdfjs #outerContainer .visibleLargeView {
    display: inherit
  }
}

@media all and (max-width:700px) {
  .pdfjs #outerContainer .hiddenMediumView {
    display: none
  }
  .pdfjs #outerContainer .visibleMediumView {
    display: inherit
  }
}

@media all and (max-width:640px) {
  .pdfjs .hiddenSmallView {
    display: none
  }
  .pdfjs .visibleSmallView {
    display: inherit
  }
  .pdfjs .toolbarButtonSpacer {
    width: 0
  }
}

@media all and (max-width:535px) {
  .pdfjs #scaleSelectContainer {
    display: none
  }
}

#fileInput.fileInput {
  display: none;
}
