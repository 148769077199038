.xaf-account-form{
  .xaf-mailing-editor-control{
    display: block;
    width: 100%;
    //height: 30px;
    line-height: 30px;
    @include xaf-grid-board;
    margin-top:5px;
  }
  .xaf-account-input {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
    font-weight: 300;
    width: 100%;
    height: 30px;
    display: block;
    padding: 0 5px 0 5px;

  }

  .cke, .xaf-account-input {
    border: 0px solid #b6b6b6;
  }

  .invalid {
    border: 1px solid red;
  }

}

.open-menu{
  .xaf-mailing-editor-stage{
    .xaf-float-menu{
      &.sticky{
        width: 85%;
      }
    }
  }
}
.xaf-mailing-editor-stage{
  .xaf-mailing-editor-control{
    display: block;
    width: 100%;
    //height: 30px;
    line-height: 30px;
    @include xaf-grid-board;
    margin-top:5px;
    height: 44px;
  }
  .xaf-float-menu{
    .xaf-mailing-display-ctrl{
      @include xaf-grid-board;

      .xaf-preview-devices {
        &.left{
          float: left;
        }
        & > div {
          width: 70px;
          height: 50px;
          float: left;
          cursor: pointer;
          position: relative;
          text-align: center;
          @include transition;
          &:hover {
            background-color: $grey;
          }
          &.active {
            background-color: $second-color;
            color: $white;
          }
          small {
            position: absolute;
            width: 100%;
            display: inline-block;
            left: 0;
            bottom: 3px;
          }
          .icon {
            width: 100%;
            height: 36px;
            left: 0;
            top: 0;
          }
          .iconDevice {
            .xaf-icon-device-mobile{
              font-size: .8em;
            }
            .xaf-icon-device-tablet{
              font-size: .8em;
            }
            font-size: 28px;
            line-height: 36px;
          }
        }
        width: auto;
        height: 50px;
        float: right;
        background-color: $white;
        position: relative;
        z-index: 1209;
        @include clear;

      }
      .xaf-display-control{
        .xaf-display-control-item{
          a{
            &.active,&:hover{
              background: $second-color;
              color: $white;
            }
            &:not(.active) {
              margin-top: 5px !important;
              height: 90% !important;
            }
            display: block;
            width: 100%;
            height: 100%;
            //margin-left: 10px;
            text-decoration: none;
            padding: 0 14px;
            color: #000;
            background-color: #fefefe;
            position: relative;
            -webkit-transition: 0.1s ease-in;
            -moz-transition: 0.1s ease-in;
            -o-transition: 0.1s ease-in;
            transition: 0.1s ease-in;
          }
          display: inline-block;
          height: 100%;
          line-height: 30px;
          //text-transform: uppercase;
          overflow: hidden;
        }
        list-style: none;
        width: auto;
        float: right;
        height: 30px;
        margin: 0;
        text-align: left;
      }
      .xaf-mode-control{
        .xaf-mode-control-item{
          a{
            &.active, &:hover{
              background: $second-color;
              color: #fefefe;
            }
            &:not(.active) {
              margin-top: 5px !important;
              height: 90% !important;
            }
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 5px 0 5px;
            text-decoration: none;
            color: #000;
            padding: 0 14px;
            color: #000;
            background-color: #fefefe;
            position: relative;
            -webkit-transition: 0.1s ease-in;
            -moz-transition: 0.1s ease-in;
            -o-transition: 0.1s ease-in;
            transition: 0.1s ease-in;
          }
          display: inline-block;
          height: 100%;
          line-height: 30px;

          overflow: hidden;
        }
        list-style: none;
        width: auto;
        float: left;
        height: 30px;
        margin: 0;
        padding: 0;
        text-align: left;
      }
      display: block;
      height: 51px;
      width: 100%;
      z-index: 100;
      position: relative;
      border-bottom: 1px solid #ccc;
    }
    .xaf-mailing-subject{
      label{
        font-size: 1em;
        width: 75px;
        float: left;
        text-align: center;
        line-height: 44px;
      }
      input{
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        margin-top: 3px;
        float: left;
        padding: 0 10px;
        font-size: 16px;
        width:calc( 100% - 75px);
      }
      .editor-subject-preview{
        float: left;
        width: calc( 100% - 75px);
        line-height: 44px;
        padding: 0 10px;
        height: 38px;
      }
      width: 100%;
      height: 32px;
      display: block;
      margin: 10px 0;
      @include xaf-grid-board;
    }
    &.sticky{
      position: fixed;
      z-index: 1000;
      width: calc( 100% - 38px);
      top: 0px;
    }
    transition: width 0.3s ease-in-out;
    background: #fefefe;
    border-bottom: 1px solid #ccc;
  }

  .xaf-mailing-display{
    &.ios , &.android{
      a{
        span:not(:first-child) {
            display:none;
        }

      }
    }
    &.mobile{
      .xaf-live-edit{
        .xaf-live-edit-inner{
          .options {
            & > a{
              span{
                &:not( .icon ){
                  display: none;
      }
    }
              padding:0;
              width: 30px;
              height: 30px;
  }
  }
  }
      }
    }

    .xaf-mailing-display-inner{
      .xaf-mailing-display-inner-container {
        .xaf-live-drop-zone{
  .xaf-live-drop-zone-title{
    &:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
            display: block;
            width: 100%;
            height: 35px;
            line-height: 35px;
    text-align: center;
    font-size: 14px;
    background-color: #949494;
    color: #000;
  }
  .xaf-live-dropin{
    &:hover{
      .xaf-live-edit-inner {
        a {
          background: $second-color;
          color: #fefefe;
        }
      }
    }
    .xaf-live-edit-inner{
      a{
        .xaf-icn{
          margin-right: 5px;
        }
        span{
          font-size: 1em;
          line-height: 1.2;
          vertical-align: middle;
        }
        display: block;
        width: 100%;
                height: 35px;
                line-height: 35px;
        cursor: pointer;
        outline: none;
        color: #000;
        text-decoration: none;
      }
      display: block;
              width: 100%;
              height: 100%;
      text-align: center;
              font-size: 14px;
    }
            display: block;
            width: 100%;
            height: 35px;
    border-color: #9e9e9e;
    background: #c8c8c8 !important;
  }
  .xaf-live-edit{

    &:hover{
      .xaf-live-edit-inner{
        display: block;
      }
    }
    .xaf-live-edit-inner{
      .xaf-filter-show{
        .icon{
          font-size: 10px;
          line-height: 30px;
          position: absolute;
          left: 0px;
          top: 5px;
          width: 35px;
          height: 35px;
        }
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: #3e7ad4;
        text-align: center;
        color: #fefefe;
      }
      .xaf-button-show{
        a{
          &:hover{
            color: #000;
          }
          .xaf-icn{
            margin-right: 5px;
          }
          display: inline-block;
          color: #fefefe;
          text-decoration: none;
          padding: 0 5px 0 5px;
        }
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        text-align: right;
        padding: 0 30px;
        width: auto;
        height: 25px;
        line-height: 25px;
        background: $second-color;
        overflow: hidden;
      }
      &.open-options{
        .options {
          height: 30px;
        }
        .btn-options {
          top: 40px;
        }
      }
      .options {
        > a{
          .icon{
            font-size: 10px;
            line-height: 30px;
            position: absolute;
            left: 0;
            width: 35px;
            height: 35px;
            top: 5px;
          }
          span{
            font-size: 14px;
            line-height: 1.2;
          }
          color: #fefefe;
          position: relative;
          margin-left: 0;
                  padding: 0 10px 0 35px;
          float:left;
                  line-height: 35px;
        }
                display: block;
                width: 100%;
                height: 35px;
                line-height: 35px;
        top: 0;
                padding: 0 0 0 35px;
                font-size: 14px;
        background-color: $second-color;
      }
      .btn-options {
        opacity: 0;
        right: 10px;
        top: 10px;
        pointer-events: none;
        margin: 0;
        z-index: 100;
      }
      &:hover{
        .btn-options {
          opacity: 1;
          pointer-events: all;
        }
      }
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;
      overflow: hidden;
      margin: 0;
      padding: 0;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
              background: rgba(0,0,0,0.3);
              cursor: default;
    }
    display: block;
            min-height: 35px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
  }
  width: calc( 100% + 2px);
  display: block;
  position: relative;
  border: 1px solid #949494;
  margin: 2px 0;
  padding: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-backface-visibility: hidden;
            }
        width: auto;
        display: table;
        margin: 0 auto;
        position: relative;
        max-width: 600px;
          }
      display: block;
      width: 100%;
      height: calc( 100% - 130px);
      overflow: auto;
      margin-top: 20px;
      margin-bottom: 100px;
        }
    display: block;
    height: calc( 100% - 30px );
    width: 100%;
      }
  .xaf-view-1{

    }
  .xaf-view-2,
  .xaf-view-3{
    display: none;
  }
  width: 100%;
  height: 100%;
  display: block;
}

/*Article picker*/
.xaf-article-editor{
  .xaf-article-editor-inner{
    display: block;
    height: auto;
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    @include xaf-grid-board;
    & fieldset {
      margin-top: 30px;
    }
  }
  display: none;
  width: 100%;
  height: 100%;
  //padding: 0px 33px;
}
/*Article picker*/



