$content-transfer__A0: #731513;

.dialog__content-transfer {

  .form-group-textarea-inner{
    margin-left: 12px;
    width: 74% !important;
    margin-top: 5px;
  }

  .me__form-row-container {
    flex-direction: row;
  }

  .chosen-container.chosen-container-multi {
    max-height: 100px;

    //Hidde scroll bar
    .chosen-choices {
      width: calc(100% - 32px)
    }

    &:before {
      background-color: #404644 !important;
      background: #404644 !important;
    }
  }

  .dialog__content-transfer__folder-select {
    .chosen-container {
      .chosen-drop {
        .chosen-results {
          li {
            $itemDif: 10px;
            $minDist: 5px;
            transition: opacity 0.1s linear;

            //@todo: opacity without option-class???
            &.level_0 {
              opacity: 1;
              padding-left: $minDist;
            }

            &.level_1 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 1);

            }

            &.level_2 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 2);
            }

            &.level_3 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 3);
            }

            &.level_4 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 4);
            }

            &.level_5 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 5);
            }

            &.level_6 {
              opacity: 1;
              padding-left: calc(#{$minDist} + #{$itemDif} * 6);
            }

            &.disabled {
              opacity: 0.3;
              pointer-events: none;
            }
            &.disabled-add{
              opacity: 0.3;
              pointer-events: none;
            }

            opacity: 0;
          }
        }

        .chosen-search {
          display: none;
        }
      }
    }
  }

  //todo: move this to me__form scss file
  .me__form-row__column {
    display: flex;

    &.me__form-row__column--big {
      flex-grow: 1;
    }

    &.me__form-row__column--multiselect-big {
      flex-grow: 1;
      min-height: 80px;
    }
  }

  .dialog__content-transfer__label {
    min-width: 130px;

    label {
      width: 100% !important;
    }

  }

  .dialog__content-transfer__required-icon::before {
    line-height: 25px;
    color: $content-transfer__A0;
  }


  //Condition block
  .dialog__content-transfer__condition-block {
    margin: 50px 0;
  }

  .dialog__content-transfer__condition-title {
    width: 100%;
    margin-bottom: 10px;
  }

  .dialog__content-transfer__condition-body {

  }

}
