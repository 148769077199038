
$panel-approval__A0: themed('base:accent--text-color') !default;

$panel-approval__A1: themed('base:accent--text-color')!default;
$panel-approval__A2: themed('info--color')!default;
$panel-approval__A3: themed('error--color')!default;

.e__panel-approval{

    height:100%;

    &__toolbar{
        /*
           todo:  This class should be a basic element from panels
        */
        display:flex;
        min-height:32px;
        flex-direction:row;
        border-bottom:1px solid black;

        .e__select-chosen{
            min-width:700px;
        }
    }

    &__wrapper{
        // flex-grow: 1 1;
        // flex-shrink: 0;
        // overflow:hidden;
        // display: block;
        // max-height:100%;
        height:100%;

        &--with-nav{
           height: calc( 100% - 32px);
        }
    }

    &__content{
        height:auto;
        overflow:hidden;
    }

    &__first-col{
      //Flex item
      min-width:700px;
      //border-right: 0px solid #000 !important;

    }
    &__objects-col{
      //Flex item
      min-width:270px;
      //border-right: 0px solid #000 !important;

    }
    &__auto-height-col{
      //Flex item
      height: auto !important;
    }
    //&__second-col{
    //   display: inline-table;
    //    border-left: 1px solid #000;
    //
    //}

    &__action-col{
        //Custom style for the column flex-item
        max-width:200px;
        align-self: right;
        color:$panel-approval__A0;
    }

    &__action-col--header{
        //Custom style for the column flex-item
        max-width:200px;
        align-self: right;
    }

    &__icon-cell{
        &.icon-check-circle::before{
            color:$panel-approval__A1;
        }

        &.icon-close-circle::before{
            color:$panel-approval__A3;
        }

        &.icon-timer::before{
            color:$panel-approval__A2;
        }
    }

}
