.login {
  *{
    font-family: Roboto Light;
  }
  .login_container {
    .login_container_left,
    .login_container_right{
      display: inline-block;
      overflow: hidden;
      position: absolute;
      height: calc( 100% - 35px );
    }
    .login_container_left{
      left: 0;
      width: 20%;
    }
    .login_container_right{
      .login_container_right_header{
        .login_container_right_header_title{
          font-size: 12px;
          color: #6d891b;
          text-transform: uppercase;
        }
        ul{
          display: none;
        }
        width: 100%;
        display: block;
        height: 40px;
        line-height: 35px;
      }
      .login_container_right_body{
        .tab-pane{
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
        height: calc( 100% - 40px );
        display: block;
        width: 100%;
      }

      .under_maintenance{
        color: #fefefe;
        padding-top: 20px;
        p{
          font-weight: bold;
        }
      }

      .form{
        .form-group{
          label,input{
            display: inline-block;
          }
          label{
            width: 20%;
            font-size: 12px;
            color: #fefefe;
            font-weight: 100;
            line-height: 25px;
          }
          input{
            &:focus {
              outline:none;
              box-shadow: none !important;
            }
            width: 79%;
            background: #474949 none repeat scroll 0 0;
            border: none;
            border-radius: 0;
            color: #fefefe;
            font-size: 12px;
            height: 30px;
            padding: 1%;
            vertical-align: top;
            outline: none !important;
          }
          width: 97%;
        }
        padding-top: 15%;
      }
      .login_toggle{
        color: #6d891b;
        float: right;
        margin-right: 20px;
      }
      right: 0;
      width: 80%;
      padding-left: 10px;

      background: url(/gui/img/login/elaine_six_dreiecke.png) no-repeat right bottom;
      background-size: 382px auto;

    }
    .login_container_footer{
      button{
        &.hidden{
          display: none;
        }
        &[disabled]{
          background: transparent;
          cursor: not-allowed;
        }
        &:hover{
          background: #a8c33d;
        }
        color: #fefefe;
        float: right;
        height: 35px;
        background: #a8c33d;
        -webkit-transition: background-color 500ms linear;
        -moz-transition: background-color 500ms linear;
        -o-transition: background-color 5000ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
        border: none;
        border-radius: 0;
        text-transform: uppercase;
        outline: none;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 12px;
      }
      width: 100%;
      display: block;
      height: 35px;
      position: absolute;
      bottom: 0;
    }
    width: 650px;
    height: 375px;
    margin: 25% auto;
    display: block;
    background: #313231;
    position: relative;

  }
  .elaine-spinner.e-floating-circles-container.e-spin-1x{
    display: none;
    right: 5px;
    position: absolute;
    top: 7px;
  }
  background: #000;
  background: url(/gui/img/login/elaine_loginscreen_bg.png);
  background-size: cover;
}


//height 799 px for medium devices or higher
@media (max-height: 799px) {
  .login {
    .login_container {
      margin-top: 15%;
    }
  }
}

div {
  &.footer {
    position: absolute;
    font-size: large;
    list-style-type: none;
    display: block;
    top: 100%;
    margin: -35px 0 0 0;
    width: 100%;

  }

  &.footer-container {
    float: right;
    color: #a8c33d;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    background: #5B5B5B;



  }
}

.link {
  color: #a8c33d;
  line-height: 35px;
  display: block;
  height: 35px;
  background-color: #5B5B5B;

  &:hover {
    background-color: #5B5B5B;
    color: #a8c33d;

  }

}
