// main: ../index.scss

$login-bg : "";

/*  CUSTOM PALLETTE */

$favourite-color: #a8c33d;

$first-color: #a8c33d;
$second-color: #3e7ad4;

$main-color: $first-color;

/* CUSTOM COLORS */

$button-submit-bg: $green;
$button-submit-color: $white;

$button-error-bg: $red;
$button-error-color: $white;



$button-interactive-bg: $favourite-color;
$button-interactive-color: $white;

$button-active-bg: $blue;
$button-active-color: $white;

$button-regular-bg: $dark;
$button-regular-color: $white;

$button-negative-bg: $grey;
$button-negative-color: $black;
$button-negative-bg-span-color: $white;


/* Background Header color */

/**/
$header-bg-color-groups:$first-color;
$header-bg-color-mailings:$first-color;
$header-bg-color-statistics:$first-color;
$header-bg-color-dashboard:$first-color;
$header-bg-color-account:$second-color;


