//Properties Table
.elaine-properties-table{
  .elaine-properties-table-row{
    a{
      color: #a8c33d;
    }
    &:nth-child( odd ){
      background: #404644;
    }
    .elaine-properties-table-row-field{
      &--text{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.first{
        width: 200px;
        border-right: 1px solid #000;
      }
      &.second{
        width: calc( 100% - 210px );
      }

      &.analyse-left {
        height: auto;
      }

      &.analyse-right {
        height: auto;
      }

      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      vertical-align: top;
    }
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    background: #4a4e4d;
    color: #fefefe;

    &.analyse-row {
      display: flex;
      height: auto;
    }

    &:nth-child( odd ){
      background: #404644;
    }

    .elaine-properties-table-col{
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      vertical-align: top;
      flex-grow:1;
      border-right: 1px solid #000;

      &:first-child{
        width: 200px;
        max-width: 200px;
      }

      &:last-child{
        border-right: none;
        max-width: 200px;
        align-self:right;
      }

      a{
        &:link { color: #a8c33d; }
        &:visited { color: #a8c33d; }
        &:hover { color: #a8c33d; }
        &:active { color: #a8c33d; }
      }
    }
  }
  display: block;
  width: 100%;
  height: auto;
}
