.me__structure-element{
  ul{
    li{
      list-style: none;
    }
    margin: 0;
    padding: 0;
  }
  &.variant{
    //background: red;
  }
  &.htmlcontent{
    //background: transparent;
    padding: 0;
  }
  &.composing{
    //background: red;
  }
  &.article{
    //background: green;
  }
  display: block;
  height: auto;
  padding: 10px;
  margin-bottom: 10px;
}
