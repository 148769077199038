.me__preview-versioning{
    .versions-table-body{
        max-height: 55vh;
        height: 100%;
    }
    .versions-table-col-{
        &active{
            max-width: 11%;
        }
        &version{
            max-width: 15%;
        }
        &creator{
            max-width: 30%;
        }
        &creation{
            max-width: 33%;
        }
        &action{
            max-width: 11%;
        }
    }
    .text-center{
        text-align: center;
    }
    .acions-menu__more{
        display:inline-block;
        width:20px;
        height:20px;
        vertical-align: text-bottom;
        &::before{
            content: url( '/gui/fonts/six/icons/more.svg' );
            width:20px;
            height:20px;
            box-sizing: border-box;
        }
    }
    .message-version{
        &:hover{
            color: #9ec21a;
            cursor:pointer;
            .e_icn{
                color: #9ec21a !important;
                cursor:pointer;
            }
        }
        &.actionMenuHover{
            color: #fefefe !important;
            .e_icn{
                color: #fefefe !important;
                cursor:pointer;
            }
            .action-column{
                opacity: 0.7;
            }
        }
        .no-action{
            display: none !important;
        }
        .empty-actions{
            &:hover{
                cursor:default !important;
            }
        }
    }
    .action-column{
        z-index: 2;
        &:hover{
            cursor:pointer;
        }
    }
}
.textWhite{
    color: white;
}
