$sending-and-planing__A0: #731513;
$sending-and-planing__B0: #75882c;
$sending-and-planing__C0: #aa2921;
$sending-and-planing__D0: #e27f01;

.dialog__sending-and-planing {
  .analysis-content {
    &__scrollBody{
      max-height: 700px;
    }
    &__expandableBox{
      &_info {
        .md-title{
          color: $sending-and-planing__B0 !important;
        }
      }
      &_error {
        .md-title{
          color: $sending-and-planing__C0 !important;
        }
      }
      &_warning {
        .md-title{
          color: $sending-and-planing__D0 !important;
        }
      }
    }
    &__text{
      border: none;
      margin: 0 0 10px 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
    &__info {
      color: $sending-and-planing__B0;
      line-height: 30px;
    }
    &__error {
      color: $sending-and-planing__C0;
      line-height: 30px;
    }
    &__warning {
      color: $sending-and-planing__D0;
      line-height: 30px;
    }
  }
  &__legend--margin {
    margin-bottom: 13px;
  }
}
