//your theme name
$theme-name: elaine-overlay-scrollbars;

//horizontal scrollbar
$scrollbar-horizontal-size: 7px; //horizontal scrollbar height
$scrollbar-horizontal-padding: 0px;
$scrollbar-horizontal-inner-expansion: 0px;
$scrollbar-horizontal-track-background: #000;
$scrollbar-horizontal-track-background-hover: #000;
$scrollbar-horizontal-track-background-active: #000;
$scrollbar-horizontal-track-transition: background-color 0.3s;
$scrollbar-horizontal-handle-min-size: 30px; //horizontal scrollbar handle min width
$scrollbar-horizontal-handle-max-size: none; //horizontal scrollbar handle max width
$scrollbar-horizontal-handle-background: #585e5e;
$scrollbar-horizontal-handle-background-hover: #585e5e;
$scrollbar-horizontal-handle-background-active: #585e5e;
$scrollbar-horizontal-handle-transition: background-color 0.3s;

//vertical scrollbar
$scrollbar-vertical-size: 7px; //vertical scrollbar width
$scrollbar-vertical-padding: 0px;
$scrollbar-vertical-inner-expansion: 0px;
$scrollbar-vertical-track-background: #000;
$scrollbar-vertical-track-background-hover: #000;
$scrollbar-vertical-track-background-active: #000;
$scrollbar-vertical-track-transition: background-color 0.3s;
$scrollbar-vertical-handle-min-size: 30px; //vertical scrollbar handle min height
$scrollbar-vertical-handle-max-size: none; //vertical scrollbar handle max height
$scrollbar-vertical-handle-background: #585e5e;
$scrollbar-vertical-handle-background-hover: #585e5e;
$scrollbar-vertical-handle-background-active: #585e5e;
$scrollbar-vertical-handle-transition:  none;

//scrollbar corner
$scrollbar-corner-background-color: #000 !important;;

.#{$theme-name} > .os-scrollbar-horizontal {
  right: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
  height: $scrollbar-horizontal-size;
  padding: $scrollbar-horizontal-padding;
}
.#{$theme-name} > .os-scrollbar-vertical {
  bottom: $scrollbar-horizontal-size + ($scrollbar-horizontal-padding * 2);
  width: $scrollbar-vertical-size;
  padding: $scrollbar-vertical-padding;
}
.#{$theme-name}.os-host-rtl > .os-scrollbar-horizontal {
  left: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
  right: 0;
}
.#{$theme-name} > .os-scrollbar-corner {
  height: $scrollbar-horizontal-size + ($scrollbar-horizontal-padding * 2);
  width: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
  background-color: $scrollbar-corner-background-color;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track {
  background: $scrollbar-horizontal-track-background;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track {
  background: $scrollbar-vertical-track-background;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track:hover {
  background: $scrollbar-horizontal-track-background-hover;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track:hover {
  background: $scrollbar-vertical-track-background-hover;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track.active {
  background: $scrollbar-horizontal-track-background-active;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track.active {
  background: $scrollbar-vertical-track-background-active;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track {
  -webkit-transition: $scrollbar-horizontal-track-transition;
  transition: $scrollbar-horizontal-track-transition;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track {
  -webkit-transition: $scrollbar-vertical-track-transition;
  transition: $scrollbar-vertical-track-transition;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before,
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
  top: -$scrollbar-horizontal-inner-expansion;
  bottom: -$scrollbar-horizontal-padding;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  left: -$scrollbar-vertical-inner-expansion;
  right: -$scrollbar-vertical-padding;
}
.#{$theme-name}.os-host-rtl > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  right: -$scrollbar-vertical-inner-expansion;
  left: -$scrollbar-vertical-padding;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  min-width: $scrollbar-horizontal-handle-min-size;
  max-width: $scrollbar-horizontal-handle-max-size;
  background: $scrollbar-horizontal-handle-background;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  min-height: $scrollbar-vertical-handle-min-size;
  max-height: $scrollbar-vertical-handle-max-size;
  background: $scrollbar-vertical-handle-background;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover {
  background: $scrollbar-horizontal-handle-background-hover;
  cursor: pointer;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover {
  background: $scrollbar-vertical-handle-background-hover;
  cursor: pointer;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: $scrollbar-horizontal-handle-background-active;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: $scrollbar-vertical-handle-background-active;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  -webkit-transition: $scrollbar-horizontal-handle-transition;
  transition: $scrollbar-horizontal-handle-transition;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  -webkit-transition: $scrollbar-vertical-handle-transition;
  transition: $scrollbar-vertical-handle-transition;
}