$sortable_item_B0: themed('base:accent--color') !default;
$sortable_item_B1: themed('list:even--bg-color') !default;
$sortable_item_B2: themed('list:odd--bg-color') !default;


.me__sortable-item {
  -ms-touch-action: none;
  touch-action: none;
  /* to disable context menu on iOS devices */
  -webkit-touch-callout: none;
}

.me__sortable-item-handle {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.me__sortable-placeholder {
  height: 0px !important;
  outline: 5px solid rgba( $sortable_item_B0 ,.75);
  width: 100% !important;
  display: block;
  z-index: 1000;
  position: relative;
}

.me__sortable-drag {
  article-menu,.me__attachment-item__menu{
    display: none;
  }
  position: absolute;
  pointer-events: none;
  z-index: 2000;
  opacity: .5;
}

.me__sortable-hidden {
  opacity: .5 !important;
}

.me__sortable-un-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.me__sortable-item-helper{
  display: table;
  min-width: 600px;
  max-width: 650px;
  width: auto;
  height: auto;
  overflow: hidden;
  opacity: .5;
}
