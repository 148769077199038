$compatibility-show__A0: themed('base:accent--color') !default; // success--color //@todo: branding or succes color
$compatibility-show__A1: themed('frame--bg-color') !default;
$compatibility-show__A2: themed('base--text-color') !default;
$compatibility-show__B0: themed('success--color') !default;
$compatibility-show__B1: themed('info--color') !default;
$compatibility-show__B2: themed('warning--color') !default;
$compatibility-show__B3: themed('error--color') !default;

.ge__compatibility-show{
  &__icon{
    &.icon-check-circle{
      color: $compatibility-show__B0;
    }
    &.icon-info-circle{
      color: $compatibility-show__B2;
    }
    &.icon-exclamation-triangle{
      color: $compatibility-show__B3;
    }
    vertical-align: sub;
    font-size: 1.3em;
  }
  &__text{
    padding-left: 5px;
  }
  display: block;
  width: auto;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-left: 1px solid $compatibility-show__A1;
  cursor: pointer;
  color:$compatibility-show__A2;
}
