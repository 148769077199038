$loading-spinner__A0: themed('base--bg-color') !default;

loading-spinner{
  &.ng-enter,&.ng-leave{
    -webkit-transition: opacity ease-in-out .3s;
    -moz-transition: opacity ease-in-out .3s;
    -ms-transition: opacity ease-in-out .3s;
    -o-transition: opacity ease-in-out .3s;
    transition: opacity ease-in-out .3s;
  }
  &.ng-enter,&.ng-leave.ng-leave-active{
    opacity: 0;
  }
  &.ng-leave,&.ng-enter.ng-enter-active{
    opacity: 1;
  }
  .ge__loading-spinner{
    &.no-shade{
      background: rgba( 0,0,0,.3);
    }
    &__container{
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
    &__show{
      &.ge__loading-spinner__show-1{
        width: 19px;
        height: 19px;
      }
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 150px;
      height: 150px;
      margin: auto;

      &.ge__loading-spinner__show-2{
        width: 35px;
        height: 35px;
      }

      &.ge__loading-spinner__show-3{
        width: 74px;
        height: 74px;
      }

      &.ge__loading-spinner__show-4{
        width: 109px;
        height: 109px;
      }
    }
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $loading-spinner__A0;
    z-index: 10000;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 10000;
}
