/*! Angular Moment Picker - v0.10.2 - http://indrimuska.github.io/angular-moment-picker - (c) 2015 Indri Muska - MIT */
.moment-picker-input {
  cursor: pointer;
}
.moment-picker {
  position: absolute;
  z-index: 9999;
}
.moment-picker .moment-picker-container {
  direction: ltr;
  display: block;
  margin-top: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 320px;
  background: #404644;
  color: #fefefe;
  border: 0;
  box-shadow: 0 6px 12px rgba(0,0,0,0.6);
  position: absolute;
}
.moment-picker .moment-picker-container:before,
.moment-picker .moment-picker-container:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: none;
  position: absolute;
  top: -9px;
  left: 15px;
}
.moment-picker .moment-picker-container:before {
  border-bottom-color: #404644;
  border-width: 9px;
}
.moment-picker .moment-picker-container:after {
  border-bottom-color: #404644;
  margin-top: 1px;
  margin-left: 1px;
}
.moment-picker.inline {
  display: block;
  position: relative;
}
.moment-picker.inline .moment-picker-container {
  position: relative;
  margin: 0;
}
.moment-picker.inline .moment-picker-container:before,
.moment-picker.inline .moment-picker-container:after {
  content: none;
}
.moment-picker.top .moment-picker-container {
  bottom: 100%;
  margin-top: auto;
  margin-bottom: 4px;
}
.moment-picker.top .moment-picker-container:before,
.moment-picker.top .moment-picker-container:after {
  border: 8px solid transparent;
  border-bottom: none;
  top: auto;
  bottom: -9px;
}
.moment-picker.top .moment-picker-container:before {
  border-top-color: #404644;
  border-width: 9px;
}
.moment-picker.top .moment-picker-container:after {
  border-top-color: #404644;
  margin-top: auto;
  margin-bottom: 1px;
}
.moment-picker.right .moment-picker-container {
  right: 0;
  margin-left: auto;
  margin-right: -0.5em;
}
.moment-picker.right .moment-picker-container:before,
.moment-picker.right .moment-picker-container:after {
  left: auto;
  right: 15px;
}
.moment-picker.right .moment-picker-container:after {
  margin-left: auto;
  margin-right: 1px;
}
.moment-picker table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  table-layout: fixed;
}
.moment-picker th {
  font-weight: bold;
}
.moment-picker th:first-child,
.moment-picker th:last-child {
  width: 2em;
}
.moment-picker th,
.moment-picker td {
  line-height: 34px;
  border: 0;
  border-radius: 0px;
  height: 34px;
  text-align: center;
}
.moment-picker th:hover,
.moment-picker td:hover {
  background-color: #6d891b;
  color: #fefefe;
}
.moment-picker th.disabled,
.moment-picker td.disabled,
.moment-picker th.disabled:hover,
.moment-picker td.disabled:hover {
  color: #abbbc7;
  background: none;
  cursor: default;
}
.moment-picker td.today {
  color: #fefefe;
  background: #6d891b;
  border: none;
  outline: 1px solid #6d891b;
  outline-offset: -1px;
}
.moment-picker td.selected {
  color: #fefefe;
  background: transparent;
  border: none;
  outline: 1px solid #6d891b;
  outline-offset: -1px;
}
.moment-picker td.highlighted {
  color: #fefefe;
  background: transparent;
  border: none;
  outline: 1px solid #6d891b;
  outline-offset: -1px;
}
.moment-picker .decade-view td,
.moment-picker .year-view td {
  height: 3.4em;
}
.moment-picker .month-view .moment-picker-specific-views th {
  background: none;
  cursor: default;
}
.moment-picker .month-view td {
  width: 1.4285714286em;
}
.moment-picker .day-view td,
.moment-picker .hour-view td {
  height: 2.3333333333em;
}
.moment-picker .minute-view td {
  height: 1.8em;
}
