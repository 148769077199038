/*
 * treeGrid.css
 *
 * Styles for the tree-grid
 * for both Bootstrap 2 and Bootstrap 3
 *
 */

.tree-grid-animate-enter,
.tree-grid-row.ng-enter {
  -moz-transition: 200ms linear all;
  -o-transition: 200ms linear all;
  -webkit-transition: 200ms linear all;
  transition: 200ms linear all;
  position: relative;
  opacity: 0;
  max-height: 0;
}

.tree-grid-animate-enter.tree-grid-animate-enter-active,
.tree-grid-row.ng-enter-active {
  opacity    : 1;
  max-height : 30px;
}

.tree-grid-animate-leave,
.tree-grid-row.ng-leave {
  -moz-transition: 200ms linear all;
  -o-transition: 200ms linear all;
  -webkit-transition: 200ms linear all;
  transition: 200ms linear all;
  position: relative;
  height: 30px;
  max-height: 30px;
  opacity: 1;
}

.tree-grid-animate-leave.tree-grid-animate-leave-active,
.tree-grid-row.ng-leave-active {
  height     : 0;
  max-height : 0;
  opacity    : 0;
}

/*
 * Angular 1.2.0 Animation placeholders.
 */
.tree-grid-animate.ng-enter {
}

/*
 * Normal CSS styles.
 */
.tree-grid i.indented {
  padding : 2px;
}

.tree-grid .level-1 .indented {
  position : relative;
  left     : 0;
}

.tree-grid .level-2 .indented {
  position : relative;
  left     : 20px;
}

.tree-grid .level-3 .indented {
  position : relative;
  left     : 40px;
}

.tree-grid .level-4 .indented {
  position : relative;
  left     : 60px;
}

.tree-grid .level-5 .indented {
  position : relative;
  left     : 80px;
}

.tree-grid .level-6 .indented {
  position : relative;
  left     : 100px;
}

.tree-grid .level-7 .indented {
  position : relative;
  left     : 120px;
}

.tree-grid .level-8 .indented {
  position : relative;
  left     : 140px;
}

.tree-grid .level-9 .indented {
  position : relative;
  left     : 160px;
}

.tree-grid .tree-icon,
.tree-label {
  cursor : pointer;
}

table.tree-grid {
  max-height : 500px;
  overflow-y : scroll;
}

/*Custom*/
.tree-grid {
  thead {
    tr {
      th {
        border-right: 1px solid #000;
        border-bottom: none;
      }
      th:nth-child(1) {
        width: 25%;
      }
      th:nth-child(2) {
        width: 10%;
      }
      th:nth-child(3) {
        width: 15%;
      }
      th:nth-child(4) {
        width: 15%;
      }
      th:nth-child(5) {
        width: 15%;
      }
      th:nth-child(6) {
        width: 10%;
      }
      th:nth-child(7) {
        width: 10%;
      }
      th:last-child {
        border-right: none;
      }
    }
    border-bottom: 2px solid #000;
    line-height: 30px;
  }
  tbody {
    tr {
      &:nth-child(even) {
        td {
          .tree-icon {
            &.elaine-tree-collapsed{
              &:after {
                content: ']'
              }
            }
            &.elaine-tree-expanded{
              &:after {
                content: '['
              }
            }
            color: #fefefe;
          }
          .progress {
            .progress-bar {
              background-color: rgb(3, 118, 149);
            }
          }
        }
        background: #282e2c;
      }
      &:nth-child(odd) {
        td {
          .tree-icon {
            &.elaine-tree-collapsed{
              &:after {
                content: ']'
              }
            }
            &.elaine-tree-expanded{
              &:after {
                content: '['
              }
            }
            color: #fefefe;
          }
          .progress {
            .progress-bar {
              background-color: #009CC9;
            }
          }
        }
        background: #1f2523;
      }
      td:nth-last-child(-n+2) {
        padding: 0 1px 0 0;
      }
      td:nth-child(2){
        padding:0;
      }
      td {
        .center {
          text-align: center;
          line-height: 30px;
        }
        border: none;
        padding: 0 0 0 10px;
        line-height: 30px;
      }
      &.active {
        td {
          background: transparent;
        }
      }
      height: 30px;
    }
  }
  .progress {
    .progress-bar {
      span {
        &.value {
          position: absolute;
          left: 10px;
          top: 5px;
        }
      }
      -webkit-transition: width 1s ease;
      -o-transition: width 1s ease;
      transition: width 1s ease;
      min-width: 1px;
    }
    background: transparent;
    border-radius: 0;
    height: 30px;
    margin: 0;
    border: none;
    box-shadow: none;
    position: relative;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  color: #fefefe;
  border-bottom: 1px solid #000;
}

