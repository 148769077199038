$item-drop-zone__A0: themed('frame--bg-color') !default;
$item-drop-zone__A3: themed('list--bg-color') !default;
$item-drop-zone__C0: themed('element-action--bg-color') !default;
$item-drop-zone__C1: themed('element-action:active--bg-color') !default;
$item-drop-zone__D0: themed('base:accent--color') !default;
$item-drop-zone__E0: themed('base--bg-color') !default;
$item-drop-zone__E1: themed('base--bg-color') !default;
$item-drop-zone__E2: themed('base--text-color') !default;
$item-drop-zone__F0: $iconFontFamily; //@todo: app font
$item-drop-zone__F1: themed('base--text-color') !default;
$item-drop-zone__G0: themed('element-action:hover-cancel--bg-color') !default;

.me__item-drop-zone{
  *{
    user-select: none;
  }
  .me__sortable-placeholder{
    display: none !important;
  }
  &__element{

    &:not( :first-of-type ){
      border-top: 1px solid $item-drop-zone__A0;
    }
    &:focus {
      outline: none;
    }
    &.not-empty{
      &:hover{
        .me__item-drop-zone__label{
          background-image: repeating-linear-gradient(
                  135deg, $item-drop-zone__C0, transparent 3px, transparent 3px, rgba( $item-drop-zone__G0 ,.75 ) 6px
          );
        }
        .me__item-drop-zone__button--left{
          background-color:$item-drop-zone__G0;
        }
      }

    }
    &:hover:not( .not-empty ){
      .me__item-drop-zone__label{
        background-image: repeating-linear-gradient(
                135deg, $item-drop-zone__C0, transparent 3px, transparent 3px, rgba( $item-drop-zone__C1 ,.75 ) 6px
        );
      }
      .me__item-drop-zone__button--left{
        background-color:$item-drop-zone__D0;
      }
    }

    &--last{
      border: 1px solid $item-drop-zone__A0;
    }
    display:flex;
    width: 100%;
    height:30px;
    flex-wrap: wrap;
    cursor:default;
    //border-top: 1px solid $item-drop-zone__A0;
    //border-left: 1px solid $item-drop-zone__A0;
    //border-right: 1px solid $item-drop-zone__A0;
    border-bottom: none;
    background-color:$item-drop-zone__A3;
  }

  &__element-inner{
    display: block;
    width: 100%;
  }

  &__clipboard-item{
    display: block;
    width: 100%;
  }

  &__clipboard-template{
    position: relative;
    min-width: 600px;
    max-width: 650px;
    opacity: .5;
  }
  &__handle{
    display: block;
    width: 100%;
  }

  &__label{
    display: inline-block;
    width: calc( 100% - 30px );
    height:30px;
    color:$item-drop-zone__E2;
    line-height: 30px;
    text-align: left;
    padding: 0 10px;
    font-size: 12px;
    background-image: repeating-linear-gradient(135deg, $item-drop-zone__E0, transparent 3px, transparent 3px, $item-drop-zone__E1 6px);
  }

  &__label-inner{
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button{
    display: inline-block;
    width:30px;
    height:30px;
    font-family: $item-drop-zone__F0;
    color:$item-drop-zone__F1;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    vertical-align: top;
    float: left;
  }
  display:block;
  position:relative;
  float:left;
  width: 100%;
}

.me__stage-middle{
  &.me__sortable-un-selectable{
    .me__item-drop-zone__element{
      &.not-empty{
        .me__sortable-item{
          pointer-events: none;
        }
      }
    }
  }
}
