$table__T0: themed('tablelist--text-color') !default;
$table__L0: themed('tablelist--bg-color') !default;
$table__L1: themed('tablelist:odd--bg-color') !default;
$table__D0: themed('tablelist-dark--bg-color') !default;
$table__D1: themed('tablelist-dark:odd--bg-color') !default;
$table__H0: themed('list:even--bg-color') !default;

//Light version default
.e__table{

  display:flex;
  width:100%;
  min-height:100%;
  height:auto;
  overflow:hidden;
  flex-direction:column;
  color: $table__T0;

  .e__table__table-header {
    /* display: flex; */
    /* flex-direction: column; */
  }

  .e__table__table-body {
    /* display: flex; */
    /* flex-direction: column; */
  }

  .e__table-row{
    display:flex;
    flex-direction:row;
    background-color: $table__L0;
    flex-wrap: wrap;

    &--odd{
      background-color: $table__L1;
    }

    &--full-width{
      width:100%;
    }

    &--header{
      background-color: $table__H0;
      border-bottom: 1px solid #000;
    }
  }

  .e__table-col{

    flex:1 1;
    height: auto;
    line-height: 30px;
    padding: 0 10px;
    border-right: 1px solid #000;
    overflow: hidden;

    &--full-width{
      width:100%;
    }
    &.fixed-height{
      height: 30px;
    }

    &:last-child{
      //border-right: none;
    }


    &--single-row{
      flex: 1 1 100%;
      background: gray;
      display: inline-table;
      width: 100%;
    }

    &--single-row-with-childs{
      padding: 0 0px;
      flex: 1 1 100%;
      background: gray;
      display: inline-table;
      width: 100%;
    }


    &--hidden{
      display:none;
    }

  }

  .e__table-cell{
    max-width: 100%;
    display: block;
    overflow: hidden;
  }
  .personalization-hint {
    background: #282e2c;
  }
  .feedbackFiles{
    padding: 0 10px;
    color: #a8c33d;
    &__odd{
      background: map-get($mappedPalette, 'base-grey-02') !important;
      border-right: 1px solid #000 !important;
    }
    &__even{
      background: map-get($mappedPalette, 'base-grey-03') !important;
      border-right: 1px solid #000 !important;
    }
  }

 //Dark version
  &.e__table--dark{
    .e__table-row{
      background-color: $table__D0;
      &--odd{
        background-color: $table__D1;
      }
    }
  }

  &.e__table--border {
    border: 1px solid #000;
  }

}
