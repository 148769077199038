/*  COLORS */
$white: #fff;
$black: #000;
$grey: #ebebeb;
$grey-light: #f2f2f2;
$grey-dark: #b2b2b2;
$blue: #3471ef;
$blue-dark: #3d61a3;
$purple: #753aba;
$purple-dark: #5c3f8a;
$purple-darker: #37214f;
$green: #25cf14;
$green-dark: #428e28;
$green-darker: #387c22;
$red: #ff1a16;
$red-dark: #9e1c19;
$red-darker: #79170e;
$orange: #c28325;
$dark: #4c4c4c;
$dark-darker: #191919;



/* Default Header Background colors */

$header-bg-color-groups:$purple;
$header-bg-color-mailings:$purple;
$header-bg-color-statistics:$blue;
$header-bg-color-dashboard:$dark;



